import './MyAccountAccessActivities.css';

import { Button, CardContent, Container } from "@mui/material";
import {Alert, Card, CardActions, Collapse, IconButton, Stack} from "@material-ui/core"
import React, {useEffect, useState} from 'react';
import {Inbox, Login, Password, Alarm, ExpandMore as ExpandMoreIcon, Timer, Grid3x3, ArrowBack} from "@mui/icons-material";
import {AccountAccessActivity, Actions} from "../../models/account-access-activity";
import {Link} from "react-router-dom"
import AuthenticationService from "../../services/accounts/authentication";
import { Box } from "@material-ui/system";


//Redux
import {useAppDispatch} from '../../redux/hooks';
import {show} from "../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from '../../components';
import ErrorWrapper from '../../utils/ErrorWrapper';

function MyAccountAccessActivities() : JSX.Element {
    const dispatch = useAppDispatch();

    //State props
    const[activities, setActivities] = useState<AccountAccessActivity[]>([]);
    const[page, setPage] = useState(1);
    const[hasMoreContentToFetch, setHasMoreContentToFetch] = useState(true);

    //Load activities when page loads
    useEffect(() => {
        fetchActivities();
        //On load effect 
        // eslint-disable-next-line
    }, []);

    /**
     * Fetch the user activities from the current page
     */
    async function fetchActivities(){
        //Fetch the results
        try {

            const result = await AuthenticationService.fetchMyAccountAccessActivities(page);

            if (result) {
                //Add activities on activities state
                result.forEach(r => activities.push(r));
                setActivities(activities);

                //Go the next page
                setPage(page + 1);
            }
            else {
                setHasMoreContentToFetch(false);
            }
            
        } catch (e) {
            console.error(e);
            dispatch(show({
                message : "Ocorreu um erro ao buscar suas atividades: " + new ErrorWrapper(e).message,
                type : 'error'
            }))
        }
    }

    function FetchMoreContent() : JSX.Element {
        if (hasMoreContentToFetch) {
            return (
                <Box sx={{display : 'flex'}}>
                    <Button className="ta-c" sx={{width : '100%'}} onClick={() => fetchActivities()}>Buscar mais atividades</Button>
                </Box>
            );
        }
        else {
            return <Alert className="ta-c">Fim da lista</Alert>
        }
    }

    function ActivityDate(props :  React.ComponentProps<any>) {
        //Extracting activity prop
        const {activity} = props;
        const accountAccessActivity = (activity as AccountAccessActivity);

        const activityDate = new Date(accountAccessActivity.creationDate);
        return (
            <div>
                <Box sx={{display : 'flex', flexDirection : 'row', marginTop : '10px'}}>
                    <Timer style={{fontSize : '16px'}} />
                    <p style={{fontSize : '14px'}}>Data da atividade: {activityDate.toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'})}</p>
                </Box>
                <Box sx={{display : 'flex', flexDirection : 'row', marginTop : '10px'}}>
                    <Grid3x3 style={{fontSize : '16px'}} />
                    <p style={{fontSize : '14px'}}>Id da atividade: #{accountAccessActivity.id}</p>
                </Box>
            </div>
            
        );
    }

    /**
     * Component of each list item of the user account activities
     * @param props 
     * @returns 
     */
    function AccountAccessActivityListItem(props :  React.ComponentProps<any>) : JSX.Element{
        //Extracting activity prop
        const {activity} = props;
        const accountAccessActivity = (activity as AccountAccessActivity);

        const [expanded, setExpanded] = useState(false);

        return (
            <Card className="activity-container" 
                sx={
                    {
                        border : '1px solid rgb(230, 230, 230)', 
                        marginBottom : 2,
                        backgroundColor : 'rgb(253, 253, 253)'
                    }
                }>
                <CardContent
                >
                    <AccountAccessActivityListItemTextAndIcon activity={accountAccessActivity} />
                    <hr/>
                    <ActivityDate activity={accountAccessActivity} />
                    <CardActions disableSpacing 
                        sx={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            p: 1,
                            m: 1,
                          }}
                          >
                        <IconButton
                            onClick={() => setExpanded(!expanded)}
                            aria-expanded={expanded}
                            aria-label="show more"
                            size="small"
                        >
                        <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <ActivityExtraContent activity={accountAccessActivity} />
                        </CardContent>
                    </Collapse>
                </CardContent>
            </Card>
        );
    }

    function ActivityExtraContent(props : React.ComponentProps<any>) : JSX.Element {
        //Extract type action from the element
        const { activity } = props;
        
        //Force type
        const _activity = (activity as AccountAccessActivity);
        const ipGeoLocationData = _activity.ipGeoLocationData;

        if (ipGeoLocationData) {
            return (
                <div className='activity-extra-info-container'>
                    <p><b>IP:</b>{ipGeoLocationData.ip}</p>
                    <p><b>Hostname:</b>{ipGeoLocationData.hostname}</p>
                    <p><b>Localização:</b>{ipGeoLocationData.location}</p>
                    <p><b>Cidade:</b>{ipGeoLocationData.city}</p>
                    <p><b>Região:</b>{ipGeoLocationData.region}</p>
                </div>
            );
        }
        return <span/>
    }

    function AccountAccessActivityListItemTextAndIcon(props :  React.ComponentProps<any>) : JSX.Element {
        //Extract type action from the element
        const { activity } = props;
        
        //Force type
        const _activity = (activity as AccountAccessActivity);
        switch ((_activity.action as Actions)) {
            case Actions.EmailChanged:
                return (
                    <Box className="activity-description">
                        <Inbox />
                        <p>Email alterado</p>
                    </Box>
                );
            case Actions.EmailChangeRequest:
                return (
                    <Box className="activity-description">
                        <Inbox />
                        <p>Pedido de alteração de email</p>
                    </Box>
                );
            case Actions.Login:
                return (
                    <Box className="activity-description">
                        <Login fontSize='small' />
                        <p>Novo login na sua conta</p>
                    </Box>
                );
            case Actions.PasswordChanged:
                return (
                    <Box className="activity-description">
                        <Password />
                        <p>Senha foi alterada</p>
                    </Box>
                );
            case Actions.PasswordExpiration:
                return (
                    <Box className="activity-description">
                        <Password />
                        <p>Senha foi expirada</p>
                    </Box>
                );
            case Actions.SessionExpiration:
                return (
                    <Box className="activity-description">
                        <Alarm />
                        <p>Expiração de sessões</p>
                    </Box>
                );
            default: 
            return (
                <Box className="activity-description">
                    <Login fontSize="small" />
                    <p>Atividade de acesso</p>
                </Box>
            );
        }   
    }

    return(
        <div style={{margin : 30}}>
            <Link to='/my-account-security'>
                <Stack direction="row" spacing={2}>
                    <Button startIcon={<ArrowBack />}>
                        Voltar para menu de segurança
                    </Button>
                </Stack>
            </Link>
            <Container style={{maxWidth : '680px'}}>
                <div className="ta-c">
                    <h2>Histórico de Atividades de acesso da minha conta</h2>
                </div>
                {activities.map(activity => (
                    <React.Fragment>
                        <AccountAccessActivityListItem key={activity.id} activity={activity} />
                    </React.Fragment>
                ))}
                <FetchMoreContent />
            </Container>
            <AppNotification/>
        </div>
    );
}

export default MyAccountAccessActivities;