import { IpGeoLocation } from "./ip-geo-location";

export enum Actions {
    Login = "LOGIN",
    PasswordChanged = "PASSWORD_CHANGED",
    EmailChangeRequest = "EMAIL_CHANGE_REQUEST",
    EmailChanged = "EMAIL_CHANGED",
    SessionExpiration = "SESSION_EXPIRATION",
    PasswordExpiration = "PASSWORD_EXPIRATION"
}

export class AccountAccessActivity {
    id : string;
    creationDate : Date;
    action : Actions;
    ipGeoLocationData : IpGeoLocation;

    constructor(id : string, creationDate : Date, action : Actions, ipGeoLocationData : IpGeoLocation) {
        this.id = id;
        this.creationDate = creationDate;
        this.action = action;
        this.ipGeoLocationData = ipGeoLocationData;
    }
}