import { Pagination } from "../../models/pagination";
import { UserAccount } from "../../models/user";
import AccountsApi from "./accountsApi";

interface RegisterWithEmailInvite {
    preferredRegion : string,
    inviteId : string,
    invitePassword : string,
    userPassword : string
}

interface UpdateCustomerSubuser {
    preferredName : string;
    fullname : string;
    email? : string | null;
    roles : string[]
}

interface Register {
    preferredName : string,
    fullname : string,
    email : string
}

const CustomerSubuserService = {

    /**
     * Return the customer subuser from the organization of the current authenticated user
     * @returns 
     */
    fetchFromOrganization : async (page : number = 1, limit : number = 10) : Promise<UserAccount[] | null> => {
        //Fetch server response
        const response = await AccountsApi.get(`/rest/v1/customer-subusers?page=${page}&limit=${limit}`);

        //Return null if pagination response is empty
        if (response.status === 204) {
            return null;
        }

        //Otherwise fetch the data
        return response.data;
    },

    /**
     * Return pagination of customer subusers of the organization of the authenticated user
     * @param limit 
     * @returns 
     */
    paginationFromOrganization : async (limit : number = 10) : Promise<Pagination> => {
        return await (await AccountsApi.get(`/rest/v1/customer-subusers/pagination?limit=${limit}`)).data;
    },

    /**
     * Block or unblock an given subuser account
     * @param account 
     * @returns 
     */
    blockOrUnblock : async (account : UserAccount) : Promise<UserAccount> => {
        if (account.user.blockDate) {
            return await (await AccountsApi.post(`/rest/v1/customer-subusers/${account.user.uuid}/unblock`)).data;
        }
        else {
            return await (await AccountsApi.post(`/rest/v1/customer-subusers/${account.user.uuid}/block`)).data;
        }
        
    },

    /**
     * Expire all given account sessions
     * @param account The account that will  be expired
     * @returns 
     */
    expireSessions : async (account : UserAccount) : Promise<UserAccount> => {
        return await (await AccountsApi.post(`/rest/v1/customer-subusers/${account.user.uuid}/expire-sessions`)).data;
    },

    /**
     * Update an UserAccount of a subuser of a customer. This service will check domain verification if the email is being updated.
     * If you want to avoid to update the email you should give null on parameter
     * @param uuid - The user unique id
     * @param dto - Object from the update
     * @returns 
     */
    updateUser : async (uuid : string, dto : UpdateCustomerSubuser) : Promise<UserAccount> => {
        //Delete the email prop if is null
        if (!dto.email) {
            delete dto.email;
        }

        //Call the API
        const responseData = await (await AccountsApi.put(`/rest/v1/customer-subusers/${uuid}`, dto)).data;

        //Return the response data
        return responseData;
    },

    /**
     * Register an UserAccount using an email invite
     * @param dto 
     * @returns 
     */
    registerWithEmailInvite : async (dto : RegisterWithEmailInvite) : Promise<UserAccount> => {
        return await (await AccountsApi.post(`/rest/v1/customer-subusers/public/register-with-email-invite`, dto, {
            withCredentials : false
        })).data;
    },

    /**
     * Register an new customer subuser using an verified email
     * @param dto 
     * @returns 
     */
    register : async (dto : Register) : Promise<UserAccount> => {
        return await (await AccountsApi.post(`/rest/v1/customer-subusers`, dto, {
            withCredentials : false
        })).data;
    },

    /**
     * Delete a customer subuser account
     * @param uuid 
     * @returns 
     */
    delete : async (uuid : string) : Promise<UserAccount> => {
        return await (await AccountsApi.delete(`/rest/v1/customer-subusers/${uuid}`)).data;
    }
};

export default CustomerSubuserService;