import {createContext} from 'react';
import { AuthenticatedUser, AuthenticationContextData } from '../models/user';

//The React.Context contract
interface StlInterceptor {
    require(authContext : AuthenticationContextData, requiredStlLevel : number) : Promise<AuthenticatedUser>,
    hasAtLeast(authContext : AuthenticationContextData, requiredStlLevel : number) : boolean
}

//Create the React.Context default instance
const StlInterceptorInstance = createContext<StlInterceptor>({
    hasAtLeast: (authContext : AuthenticationContextData, requiredStlLevel : number) : boolean => {
        //If user is not authenticated return false
        if (!authContext.isAuthenticated()) {
            return false;
        }

        const user = authContext.user();
        return user !== null && user.session.stl >= requiredStlLevel;
    },

    require : (authContext : AuthenticationContextData, requiredStlLevel : number) : Promise<AuthenticatedUser> => {
        return new Promise((resolve, reject) => {
            authContext.updatedUser()
            .then(authUser => {
                if (authUser.session.stl >= requiredStlLevel) {
                    resolve(authUser);
                }
                else {
                    reject(authUser);
                }
            })
            .catch(() => {
                reject();
            });
        });
    },
});

//Export the STL interceptor
export default StlInterceptorInstance;


    