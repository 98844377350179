import { Button, LinearProgress, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import { Box } from "@material-ui/system"
import { Check, Error as ErrorIcon } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import jwt from 'jsonwebtoken';
import { AppNotification } from "../../components";
import AuthenticationService from "../../services/accounts/authentication";
import { useAppDispatch } from "../../redux/hooks";
import {show} from "../../redux/features/app-global-notification/app-global-notification-slice"
import ErrorWrapper from "../../utils/ErrorWrapper";
import AuthenticationContext from "../../contexts/authentication";
import StlInterceptorInstance from "../../contexts/stl";
import SecurityTierLevels from "../../security/stl";
import RenewAccessDialog from "../renew-access/RenewAccessDialog";

/**
 * Enum used to store token validation status
 */
 enum TokenValidationStatus {
    Processing,
    Failed,
    Validated
}

const ChangeEmailWithToken = () : JSX.Element => {
    //Redux props
    const dispatch = useAppDispatch();

    //Context prop used to navigate to another page
    const navigate = useNavigate();
    
    //Context variables
    let authUser = useAuthentication();
    function useAuthentication() {
        return useContext(AuthenticationContext);
    }
    //stl
    let stl = useStl();
    function useStl() {
        return useContext(StlInterceptorInstance);
    }
    // content used to get query parameter data
    const [searchParams] = useSearchParams();

    //Store the state of the current step
    const [step, setStep] = useState(0);

    //Stateless props
    const steps = [
        'Validando token de verificação',
        'E-mail alterado com sucesso',
    ];

    //Inner components
    function TokenValidationProgress() : JSX.Element | null{
        //Store the state of the token verification step
        const [tokenVerificationStatus, setTokenVerificationStatus] = useState<TokenValidationStatus>(TokenValidationStatus.Processing);
        const [token, setToken] = useState<string | null>(null);
        const [progress, setProgress] = useState(0);
        const [renewAccessDialogVisible, setRenewAccessDialogVisible] = useState(false);
        const [stlApproved, setStlApproved] = useState(false);

        //Effect used to validate the token when the component is first loaded
        useEffect(() => {

            //Verify the STL level before the token validation
            stl.require(authUser, SecurityTierLevels.MaxLevel)
            .then(() => {
                try {
                    //Get the token from query parameter
                    const tokenFromQuery = searchParams.get("token") || null;
                    if (tokenFromQuery === null) {
                        console.error("Token not found");
                        throw new Error("Token not found");
                    }
    
                    //Get the decoded JWT
                    const decodedJwt = jwt.decode(tokenFromQuery) as any;
                    if (decodedJwt === null) {
                        console.error("Invalid token");
                        throw new Error("Invalid token");
                    }
    
                    //Get the token expiration date to validate if it is expired
                    const tokenExpirationTimestamp = decodedJwt.exp * 1000;
                    const now = new Date().getTime();
    
                    //Verify is the token expired
                    if (tokenExpirationTimestamp < now) {
                        console.error("The verification token expired");
                        throw new Error("The verification token expired");
                    }
    
                    //If any error is detected set the progress to check and set a timeout to change to step 1 (change password)
                    setTokenVerificationStatus(TokenValidationStatus.Validated);
                    setToken(tokenFromQuery);
                } catch (error) {
                    setTokenVerificationStatus(TokenValidationStatus.Failed);
                }
            })
            .catch(() => {
                //Show the renew access dialog
                setRenewAccessDialogVisible(true);
            })
        }, [stlApproved]);

        //Effect used to automatically skip to reset password step when the token is set
        const Timeout = 3000;
        useEffect(() => {
            if (token !== null) {
                setTimeout(() => {
                    goToEmailChangeConfirmation();
                }, Timeout);

                //Progress bar 
                const interval = setInterval(() => {
                    setProgress((oldProgress) => {
                        //Keep always on 100
                        if (oldProgress >= 80) {
                            clearInterval(interval);
                            return 100;
                        }

                        return oldProgress + 10;
                    });
                }, Timeout / 10);
            }
            // eslint-disable-next-line
        }, [token]);

        //Functions
        function goToEmailChangeConfirmation() {
            //Ignore if token is null
            if (token === null) {
                return;
            }

            //Go to the next page
            setStep(1);

            //Update the e-mail
            AuthenticationService.updateEmailWithToken(token)
            .then(() => {
                //Show success message
                dispatch(show({
                    message : 'Email primário alterado com sucesso',
                    type : 'success'
                }))

                //Update auth user data async
                authUser.updatedUser();

                setTimeout(() => {
                    navigate("/", {
                        replace : true
                    })
                }, 3000)
            })
            .catch((e) => {
                dispatch(show({
                    message : 'Ocorreu um erro ao alterar e-mail primário: ' + (new ErrorWrapper(e).message),
                    type : 'error'
                }))
            })
        }

        //Switch between token verification status components
        switch (tokenVerificationStatus) {
            case TokenValidationStatus.Processing:
                return (
                    <Box sx={{ justifyContent : 'center', mt : 4 }}>
                        <LinearProgress color="info" sx={{display : 'block', 'margin' : 'auto'}}/>
                        <p className="ta-c">Validando token de verificação</p>
                        <RenewAccessDialog  
                            open={renewAccessDialogVisible} 
                            onClose={() => setRenewAccessDialogVisible(false)} 
                            onLogin={() => setStlApproved(true)}
                        />
                    </Box>
                );
            case TokenValidationStatus.Validated:
                return (
                    <Box sx={{ justifyContent : 'center', mt : 4 }} className='ta-c'>
                        <Check color="success" fontSize="large" sx={{display : 'block', 'margin' : 'auto'}}/>
                        <p className="ta-c">Token validado com sucesso</p>
                        <Typography variant="subtitle1" >Seu e-mail será alterado em instantes</Typography>
                        <LinearProgress variant="determinate" value={progress} />
                        <hr/>
                        <Button size="medium" onClick={() => goToEmailChangeConfirmation()}>Trocar meu e-mail agora</Button>
                    </Box>
                );
                case TokenValidationStatus.Failed:
                    return (
                        <Box sx={{ justifyContent : 'center', mt : 4 }}>
                            <ErrorIcon color="error" fontSize="large" sx={{display : 'block', 'margin' : 'auto'}}/>
                            <p className="ta-c">Token de verificação inválido</p>
                            <hr/ >
                            <Link to="/my-account-security">
                                <Button size="medium" sx={{margin : 'auto', display : 'block'}}>Iniciar troca de e-mail novamente</Button>
                            </Link>
                        </Box>
                    );
            default : 
                    return null;
        }
    }

    /**
     * Main component
     */
    return (
        <Box
            sx={{
                margin : 'auto',
                maxWidth : '480px',
                padding : 2
            }}
        >
            <Box
                sx={{
                    alignItems : 'center',
                    display : 'flex',
                    height : '100vh'
                }}
            >
                <hr/>
                <div>
                    {/*Steppers */}
                    <Box sx={{width : '100%', mb : 8}}>
                        <Stepper activeStep={step} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>

                    {/*Step validations*/}
                    {
                        /*Step 0 = Token validation progress */
                        (step === 0) 
                        ?
                        <TokenValidationProgress />
                        :
                        /*Step 1 = Reset password form*/
                        (step === 1)
                        ?
                        <div>
                            <h3 className='ta-c'>E-mail primário alterado com sucesso</h3>
                        </div>
                        :
                        null
                    }
                    <hr/ >
                    <p className='ta-c' style={{textTransform : 'uppercase'}}><b>Ou</b></p>
                    <Link to="/login">
                        <Button size="small" color='error' sx={{margin : 'auto', display : 'block'}}>Voltar para login</Button>
                    </Link>
                </div>
            </Box>
            {/* App notification component */}
            <AppNotification />  
        </Box>
    );
};

export default ChangeEmailWithToken;