import "./UserAndTeams.css";

import {
    Alert, AppBar, Box, Button, Container, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, Tab,
    Tabs, TextField, Toolbar, Fab, Stack, Checkbox, FormControlLabel, Typography, Select, SelectChangeEvent, Tooltip
} from "@material-ui/core";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import TabPanel from "../../components/TabPanel";
import { Pagination } from "../../models/pagination";
import { User, UserAccount } from "../../models/user";
import CustomerSubuserService from "../../services/accounts/customer-subuser";
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Team } from "../../models/team";
import TeamService from "../../services/accounts/team";
import { CustomerSubuserEmailInvite } from "../../models/email-invite";
import CustomerSubuserEmailInviteService from "../../services/accounts/customer-subuser-email-invites";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { LoadingButton, AppNotification, SearchBar } from "../../components";
import { Block, Check, Close, Delete, DeleteForeverOutlined, DomainVerification, Edit, Login, ManageAccounts, Menu as MenuIcon, RefreshOutlined, Save } from "@mui/icons-material";
import StlInterceptor from '../../contexts/stl'
import { RolesContext, CustomerSubuserRoles as CustomerSubuserRolesEnumeration } from '../../contexts/roles'
import AuthenticationContext from '../../contexts/authentication'
import SecurityTierLevels from '../../security/stl';
import RenewAccessDialog from "../renew-access/RenewAccessDialog";
import { CustomerSubuserRoles } from '../../static/customer-subuser.roles';

//Redux
import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import SearchResult from "../../models/search-result";
import { CustomerInternetDomain } from "../../models/customer-internet-domain";
import CustomerInternetDomainService from "../../services/accounts/customer-internet-domain";
import { useSearchParams } from "react-router-dom";
import AuthenticationService from "../../services/accounts/authentication";
import { CurrentContext } from "../../appctx/webappContext";
import AuthenticationStore from "../../store/authentication";
import moment from "moment";
import 'moment/locale/pt-br';

function UserAndTeams(): JSX.Element {
    //Redux props
    const dispatch = useAppDispatch();

    //State props
    const [tab, setTab] = useState(0);
    const [addUserDialogVisible, setAddUserDialogVisible] = useState(false);
    const [addTeamDialogVisible, setAddTeamDialogVisible] = useState(false);
    const [renewAccessDialog, setRenewAccessDialog] = useState(false);
    const [anchorSubusersMenu, setAnchorSubusersMenu] = useState<null | HTMLElement>(null);
    const [anchorInvitesMenu, setAnchorInvitesMenu] = useState<null | HTMLElement>(null);
    const [anchorTeamsMenu, setAnchorTeamsMenu] = useState<null | HTMLElement>(null);
    const [confirmDeleteInviteDialog, setConfirmDeleteInviteDialog] = useState(false);
    const [confirmDeleteTeamDialog, setConfirmDeleteTeamDialog] = useState(false);
    const [confirmResendInviteDialog, setConfirmResendInviteDialog] = useState(false);
    const [blockAccountDialog, setBlockAccountDialog] = useState(false);
    const [subuserDataDialog, setSubuserDataDialog] = useState(false);
    const [deleteAccountDialog, setDeleteAccountDialog] = useState(false);
    const [teamDataDialog, setTeamDataDialog] = useState(false);
    const [expireSubuserSessionsDialog, setExpireSubuserSessionsDialog] = useState(false);
    const [error, setError] = useState<string>(null!);

    //Teams state
    const [teamPage, setTeamPage] = useState(1);
    const [teams, setTeams] = useState<Team[]>([]);
    const [teamsPagination, setTeamsPagination] = useState<Pagination>(null!);
    const [selectedTeam, setSelectedTeam] = useState<Team>(null!);

    //Subusers state
    const [userPage, setUserPage] = useState(1);
    const [users, setUsers] = useState<UserAccount[]>([]);
    const [usersPagination, setUsersPagination] = useState<Pagination>(null!);
    const [selectedUser, setSelectedUser] = useState<UserAccount>(null!);

    //Email invites state
    const [invitesPage, setInvitesPage] = useState(1);
    const [invites, setInvites] = useState<CustomerSubuserEmailInvite[]>([]);
    const [invitesPagination, setInvitesPagination] = useState<Pagination>(null!);
    const [selectedInvite, setSelectedInvite] = useState<CustomerSubuserEmailInvite>(null!);

    const [searchParams, setSearchParams] = useSearchParams();

    const [token, setToken] = useState(searchParams.get('token'));

    //Organization domains state
    const [domains, setDomains] = useState<CustomerInternetDomain[]>([]);

    const [loading, setLoading] = useState(false);

    //Context props
    const stl = useContext(StlInterceptor);
    const authUserProvider = useContext(AuthenticationContext);
    const roles = useContext(RolesContext);

    // return session data related to the token used in the request.
    async function authorizeMe() {
        setLoading(true)
        let authMe = await AuthenticationService.fetchAuthenticatedUserData();
        setLoading(false)
    }

    async function validateToken(token: string) {
        await authUserProvider.authenticateWithAuthToken(token);
        authorizeMe();
    }

    useEffect(() => {
        if (token) {
            validateToken(token)
            searchParams.delete("token");
            searchParams.delete("accountType");
            setSearchParams(searchParams);
        }
        else {
            const tokenFromStore = AuthenticationStore.getToken();
            if (tokenFromStore) {
                setToken(tokenFromStore);
            }
            else {
                window.location.replace(`${CurrentContext.authWebappURL}/login?service=accounts&requiredEmail=${authUserProvider.user().user.email}&redirectUrl=/user-and-teams`)
            }
        }
    }, [token])

    //Effect that fetches organization verified domains
    useEffect(() => {
        let currentPage: number = 1;

        //while (hasMoreContentToFetch) {
        CustomerInternetDomainService.fetchDomains(currentPage)
            .then(fetchedDomains => {
                //If it is the first page, clear the content of the domains
                if (currentPage === 1) {
                    setDomains([]);
                }

                //Go to the next page
                currentPage++;

                //Add the fetched content into the array
                if (fetchedDomains !== null) {
                    setDomains([...domains, ...fetchedDomains]);
                }
            })
            .catch((e) => {
                dispatch(show({
                    message: 'Ocorreu um erro ao buscar domínios: ' + (new ErrorWrapper(e).message),
                    type: 'error'
                }))
            })
        //}
        // eslint-disable-next-line
    }, []);

    //useEffect - If users change fetch pagination
    useEffect(() => {
        //Component loading flag
        setLoading(true);
        CustomerSubuserService.paginationFromOrganization()
            .then(pagination => {
                setUsersPagination(pagination);
            })
            .catch(e => {
                console.error(e);
                dispatch(show({
                    message: "Ocorreu um erro ao buscar usuários da organização : " + new ErrorWrapper(e).message,
                    type: 'error'
                }))
            })
            .finally(() => {
                //Component loading flag
                setLoading(false);
            });
    }, [users, dispatch])

    //useEffect => Observe invites change and update pagination
    useEffect(() => {

        //Assert customer type to fecth the data
        if (!roles.assertCustomerOrHasSubuserRole(authUserProvider, CustomerSubuserRolesEnumeration.CSM)) {
            return;
        }

        //Component loading flag
        setLoading(true);

        CustomerSubuserEmailInviteService.paginationFromOrganization()
            .then(pagination => {
                setInvitesPagination(pagination);
            })
            .catch(e => {
                console.error(e);
                dispatch(show({
                    message: "Ocorreu um erro ao buscar convites de usuário : " + new ErrorWrapper(e).message,
                    type: 'error'
                }))
            })
            .finally(() => {
                setLoading(false);
            });
    }, [invites, dispatch, authUserProvider, roles]);

    //useEffect => Observe teams change and update teams
    useEffect(() => {
        //Component loading flag
        setLoading(true);
        TeamService.paginationFromOrganization()
            .then(pagination => {
                setTeamsPagination(pagination);
            })
            .catch(e => {
                console.error(e);
                dispatch(show({
                    message: "Ocorreu um erro ao buscar times : " + new ErrorWrapper(e).message,
                    type: 'error'
                }));
            })
            .finally(() => {
                //Component loading flag
                setLoading(false);
            });
    }, [teams, dispatch]);

    //useEffect - Observe user page change and fetch the users
    useEffect(() => {
        //Component loading flag
        setLoading(true);
        CustomerSubuserService.fetchFromOrganization(userPage)
            .then(response => {
                //Pagination query can return null (if page is empty) assert that the response has always a value
                if (response) {
                    setUsers(response);
                }
            })
            .catch(e => {
                console.error(e);
                dispatch(show({
                    message: "Ocorreu um erro ao usuários da organização : " + new ErrorWrapper(e).message,
                    type: 'error'
                }))
            })
            .finally(() => {
                //Component loading flag
                setLoading(false);
            });
    }, [userPage, dispatch]);

    //useEffect - Observe invite page change and fetch the invites from the database
    useEffect(() => {
        //Assert customer type to fecth the data
        if (!roles.assertCustomerOrHasSubuserRole(authUserProvider, CustomerSubuserRolesEnumeration.CSM)) {
            return;
        }

        //Component loading flag
        setLoading(true);

        CustomerSubuserEmailInviteService.fetchFromOrganization(invitesPage)
            .then(response => {
                if (response) {
                    setInvites(response);
                }
            })
            .catch(e => {
                console.error(e);
                dispatch(show({
                    message: "Ocorreu um erro ao buscar convites de usuário : " + new ErrorWrapper(e).message,
                    type: 'error'
                }))
            })
            .finally(() => {
                //Component loading flag
                setLoading(false);
            })
    }, [invitesPage, dispatch, authUserProvider, roles]);

    //useEffect - Observe team page change and fetch teams from the database
    useEffect(() => {
        //Component loading flag
        setLoading(true);
        TeamService.fetchFromOrganization(teamPage)
            .then(response => {
                if (response) {
                    setTeams(response);
                }
            })
            .catch(e => {
                console.error(e);
                dispatch(show({
                    message: "Ocorreu um erro ao buscar times : " + new ErrorWrapper(e).message,
                    type: 'error'
                }))
            })
            .finally(() => {
                setLoading(false);
            })
    }, [teamPage, dispatch]);

    //Events
    //This event associate the cell click event with the subusers context menu
    const handleSubusersCellClick = (cell: GridCellParams, event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorSubusersMenu(event.currentTarget);
        setSelectedUser(cell.row);
    }

    //This event associate the cell click event with the invites context menu
    const handleInvitesCellClick = (cell: GridCellParams, event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorInvitesMenu(event.currentTarget);
        setSelectedInvite(cell.row);
    }

    //Functions
    async function expireSubuserSessions() {
        if (selectedUser === null) {
            return;
        }

        try {
            //Update UI
            setLoading(true);
            setError(null!);

            //Call the API
            await CustomerSubuserService.expireSessions(selectedUser);

            //Close the dialog
            setExpireSubuserSessionsDialog(false);

            //Notifica sucesso
            dispatch(show({
                message: "Alteração realizada com sucesso",
                type: 'success'
            }))
        } catch (e) {
            console.error(e);
            setError(new ErrorWrapper(e).message);
        } finally {
            setLoading(false);
        }
    }

    async function blockOrUnblock() {
        if (selectedUser === null) {
            return;
        }

        try {

            setLoading(true);
            setError(null!);

            //Call API
            const updatedUser = await CustomerSubuserService.blockOrUnblock(selectedUser);

            //Remove the user currently selected from the list
            const indexToUpdate: number = users.findIndex(u => u.user.uuid === selectedUser.user.uuid);
            if (indexToUpdate < 0) {
                throw Error("Could not find index in users");;
            }

            //Replace the object with the updated one
            const updatedUsers: UserAccount[] = users.slice();
            updatedUsers[indexToUpdate] = updatedUser;

            //Set the selected user
            setSelectedUser(updatedUser);

            //Render UI
            setBlockAccountDialog(false);
            setAnchorSubusersMenu(null);
            setUsers(updatedUsers);

            //Notifica sucesso
            dispatch(show({
                message: "Alteração realizada com sucesso",
                type: 'success'
            }))

        } catch (e) {
            console.error(e);
            setError(new ErrorWrapper(e).message);
        } finally {
            setLoading(false);
        }
    }

    /**
     * Get the user current status
     * @param user 
     * @returns 
     */
    function getUserStatus(user: User): string {
        //Check if is blocked
        if (user.blockDate) {
            return "Bloqueado";
        }
        //Check if email is confirmed
        if (!user.emailConfirmationDate) {
            return "Aguardando confirmação de e-mail";
        }

        return "Ativo";
    }

    /**
     * Delete the selected invite
     * @returns 
     */
    async function deleteInvite() {
        //If selected invite is not selected
        if (selectedInvite === null) {
            return;
        }

        try {
            setLoading(true);

            //Call API to delete the invite
            const deletedInvite = await CustomerSubuserEmailInviteService.deleteInvite(selectedInvite.id);

            //Render the UI without the excluded invite
            setInvites(invites.filter(i => i.id !== deletedInvite.id));

            //Close the dialog
            setConfirmDeleteInviteDialog(false);
            setAnchorInvitesMenu(null);

            dispatch(show({
                message: "Convite deletado com sucesso",
                type: 'success'
            }))
        } catch (e) {
            console.error(e);

            dispatch(show({
                message: "Ocorreu um erro ao solicitar serviço " + new ErrorWrapper(e).message,
                type: 'error'
            }))
        } finally {
            setLoading(false);
        }
    }

    /**
    * Delete the selected invite
    * @returns 
    */
    async function deleteAccount() {
        if (!selectedUser || !roles.assertCustomerOrHasSubuserRole(authUserProvider, CustomerSubuserRolesEnumeration.CSM)) return;

        try {

            //Update states
            setLoading(true);
            setError(null!);

            //Call API
            const deletedUser = await CustomerSubuserService.delete(selectedUser.user.uuid);

            //Remove the deleted user from the list
            const updatedUsers: UserAccount[] = users.filter(u => u.user.uuid != deletedUser.user.uuid)
            setUsers(updatedUsers);

            //Render UI
            setDeleteAccountDialog(false);
            setAnchorSubusersMenu(null);

            //Notify success
            dispatch(show({
                message: "Usuário deletado",
                type: 'success'
            }))

        } catch (e) {
            console.error(e);
            setError(new ErrorWrapper(e).message);
        } finally {
            setLoading(false);
        }
    }
    /**
     * Delete the selected team
     * @returns 
     */
    async function deleteTeam() {
        //If there is no selected team, ignore it
        if (selectedTeam === null) {
            return;
        }

        try {
            setLoading(true);

            //Call API to delete the invite
            const deletedTeam = await TeamService.deleteTeam(selectedTeam.guid);

            //Remove selected team
            setSelectedTeam(null!);

            //Render the UI without the excluded invite
            setTeams(teams.filter((team) => team.guid !== deletedTeam.guid));

            //Close the dialog
            setConfirmDeleteTeamDialog(false);
            setAnchorInvitesMenu(null);

            dispatch(show({
                message: "Time deletado com sucesso",
                type: 'success'
            }))
        } catch (e) {
            console.error(e);

            dispatch(show({
                message: "Ocorreu um erro ao solicitar serviço " + new ErrorWrapper(e).message,
                type: 'error'
            }))
        } finally {
            setLoading(false);
        }
    }

    async function resendInvite() {
        //If selected invite is not selected
        if (selectedInvite === null) {
            return;
        }

        try {
            setLoading(true);

            //Call API to delete the invite
            const resendedInvite = await CustomerSubuserEmailInviteService.resendInvite(selectedInvite);

            //Delete the invite and add the resended invite in the last position
            setInvites([...invites.filter(i => i.id !== selectedInvite.id), resendedInvite]);

            //Close the dialog
            setConfirmResendInviteDialog(false);
            setAnchorInvitesMenu(null);

            dispatch(show({
                message: "Convite reenviado com sucesso",
                type: 'success'
            }))
        } catch (e) {
            console.error(e);
            dispatch(show({
                message: "Ocorreu um erro ao solicitar serviço " + new ErrorWrapper(e).message,
                type: 'success'
            }))
        } finally {
            setLoading(false);
        }
    }

    //Static props
    const items = [
        {
            label: 'Usuários',
        },
        {
            label: 'Times'
        }
    ];

    const subusersColumnDef: GridColDef[] = [
        { field: 'fullname', headerName: 'Nome completo', width: 200 },
        { field: 'email', headerName: 'E-mail', flex: 1, valueGetter: (params: GridValueGetterParams) => `${params.row.user.email}` },
        { field: 'status', headerName: 'Status', flex: 1, valueGetter: (params: GridValueGetterParams) => `${getUserStatus(params.row.user)}` },
        {
            field: 'roles', headerName: "Permissões", flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                const roles = params.row.roles

                return (
                    <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"} sx={{ width: "100%" }}>
                        {
                            roles.map((role: string) => (
                                <>
                                    {
                                        role === "CSM"
                                            ?
                                            <Tooltip title="Gerente de usuários" >
                                                <ManageAccounts color="primary" />
                                            </Tooltip>
                                            :
                                            <></>
                                    }
                                    {
                                        role === "CIDM"
                                            ?
                                            <Tooltip title="Gerente de Domínios de Internet" >
                                                <DomainVerification color="primary" />
                                            </Tooltip>
                                            :
                                            <></>
                                    }
                                </>
                            ))
                        }
                    </Box>
                )
            }
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {

                const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation(); // don't select this row after clicking
                    setAnchorSubusersMenu(event.currentTarget);
                    setSelectedUser(params.row);
                };

                return (
                    <Box>
                        <IconButton
                            onClick={onClick}
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            aria-expanded={!!anchorSubusersMenu ? 'true' : undefined}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                )
            }
        }
    ];

    const invitesColumnDef: GridColDef[] = [
        { field: 'fullname', headerName: 'Nome completo', width: 200 },
        { field: 'email', headerName: 'E-mail', width: 280, valueGetter: (params: GridValueGetterParams) => `${params.row.email}` },
        { field: 'status', headerName: 'Status', width: 280, valueGetter: (params: GridValueGetterParams) => `Aguardando confirmação` },
        {
            field: 'expiresAt',
            headerName: "Expira em",
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                moment.locale("pt-br");
                const creationDate = moment(params.row.creationDate);
                const expirationDate = creationDate.add(1, "days");
                const diff = expirationDate.fromNow();
                const isExpired = moment(params.row.creationDate).isBefore(moment().subtract(1, 'days'));

                return (
                    <Box>
                        <Typography>
                            {
                                isExpired ?
                                    <Tooltip title="Este convite expirou, envie um novo convite clicando no botão ao lado e ``Reenviar convite``">
                                        <Typography sx={{ color: "red" }}>
                                            Expirado
                                        </Typography>
                                    </Tooltip>
                                    :
                                    diff.toLocaleLowerCase()
                            }
                        </Typography>
                    </Box>
                )
            }
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {

                const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation(); // don't select this row after clicking
                    setAnchorInvitesMenu(event.currentTarget);
                    setSelectedInvite(params.row);
                };

                return (
                    <Box>
                        <IconButton
                            onClick={onClick}
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            aria-expanded={!!anchorSubusersMenu ? 'true' : undefined}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                )
            }
        }
    ];

    const teamsColumnDef: GridColDef[] = [
        { field: 'name', headerName: 'Nome', width: 800 },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {

                const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation(); // don't select this row after clicking
                    setAnchorTeamsMenu(event.currentTarget);
                    setSelectedTeam(params.row);
                };

                return (
                    <Box>
                        <IconButton
                            onClick={onClick}
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            aria-expanded={!!anchorSubusersMenu ? 'true' : undefined}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                )
            }
        }
    ];

    /**
     * Create the tab items
     * @returns 
     */
    function TabItems(): JSX.Element {
        //Events
        const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
            setTab(newTab);
        }

        //Component
        return (
            <Tabs value={tab} onChange={handleTabChange} aria-label="Menu de abas">
                {items.map((t: any): JSX.Element => (
                    <Tab key={t.label} {...t} />
                ))}
            </Tabs>
        );
    }

    function paginationProps(pagination: Pagination): Object {
        return {
            rowCount: pagination?.totalRecords,
            pageSize: pagination?.recordsPerPage,
            paginationMode: 'server'
        }
    }

    function AddTeamDialog(): JSX.Element {
        //Props
        const [teamName, setTeamName] = useState("");
        const [selectedMembers, setSelectedMembers] = useState<SearchResult[]>([]);
        const [canAddTeam, setCanAddTeam] = useState(false);

        //Events
        const handleSearchBarChange = (event: any, value: SearchResult[]) => {
            setSelectedMembers(value);
        }

        const handleTeamNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setTeamName(event.target.value);
        }

        //Effects
        //Observe selectedMembers, teamName
        useEffect(() => {
            setCanAddTeam(teamName.length > 0 && selectedMembers.length > 0);
        }, [selectedMembers, teamName])

        //Functions
        async function addTeam() {
            try {
                //Set loading state to true
                setLoading(true);

                //Call the API to register the member
                const registeredTeam = await TeamService.register({
                    name: teamName,
                    members: selectedMembers.map((member: SearchResult): string => member.id)
                });

                //Include the registered team into the list
                setTeams([...teams, registeredTeam]);

                //Close this dialog
                setAddTeamDialogVisible(false);
            } catch (e) {
                console.error(e);
                dispatch(show({
                    message: "Ocorreu um erro ao realizar alteração: " + new ErrorWrapper(e).message,
                    type: 'error'
                }));
            } finally {
                //Disable loading state
                setLoading(false);
            }
        }

        //JSX
        return (
            <Dialog
                onClose={() => setAddTeamDialogVisible(false)}
                open={addTeamDialogVisible}
                fullWidth
            >
                <DialogTitle>Adicionar time</DialogTitle>
                <DialogContent>
                    <Container>
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField required label="Nome do time" fullWidth={true} value={teamName} onChange={handleTeamNameChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <SearchBar searchMode="user-accounts" multiple fullWidth={true}
                                        onChange={handleSearchBarChange}
                                        renderInputProps={{
                                            label: "Membros do grupo",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <span>{selectedMembers.length} {(selectedMembers.length !== 1) ? 'membros adicionados' : 'membro adicionado'}</span>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddTeamDialogVisible(false)}>Cancelar</Button>
                    <LoadingButton onClick={() => addTeam()} loading={loading} color='success' disabled={!canAddTeam}>Adicionar time</LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }

    /**
     * Dialog used to edit team data
     * @returns 
     */
    function EditTeamDialog(): JSX.Element {
        //State props
        const [tab, setTab] = useState(0);
        const [teamFromDialog, setTeamFromDialog] = useState<Team>(null!);
        const [teamMembers, setTeamMembers] = useState<UserAccount[]>([]);

        //Load an copy of the selected team into the 'teamFromDialog' state prop
        useEffect(() => {
            if (teamDataDialog === true && selectedTeam !== null) {
                setTeamFromDialog({
                    ...selectedTeam
                });
            }
        }, []);

        //Effect to fetch all team members
        useEffect(() => {
            if (teamFromDialog !== null) {
                TeamService.fetchAllMembers(teamFromDialog.guid)
                    .then(members => {
                        if (members !== null) {
                            setTeamMembers(members);
                        }
                    })
                    .catch((e) => {
                        dispatch(show({
                            message: new ErrorWrapper(e).message,
                            type: 'error'
                        }))
                    });
            }
        }, [teamFromDialog]);

        //Col defs
        const teamMembersColDef: GridColDef[] = [
            { field: 'fullname', headerName: 'Nome completo', width: 200 },
            { field: 'email', headerName: 'E-mail', width: 280, valueGetter: (params: GridValueGetterParams) => `${params.row.user.email}` },
            { field: 'status', headerName: 'Status', width: 280, valueGetter: (params: GridValueGetterParams) => `${getUserStatus(params.row.user)}` },
            {
                field: "actions",
                headerName: "",
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {

                    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation(); // don't select this row after clicking

                        //Delete the member from members list
                        setTeamMembers(teamMembers.filter(member => member.user.uuid !== params.row.user.uuid))
                    };

                    return (
                        <Box>
                            <IconButton
                                onClick={onClick}
                                id="basic-button"
                                aria-controls="basic-menu"
                            >
                                <Delete color='error' />
                            </IconButton>
                        </Box>
                    )
                }
            }
        ];


        //Events
        const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
            setTab(newTab);
        }
        const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            //Update the name from the dialog object
            setTeamFromDialog({
                ...teamFromDialog,
                name: event.target.value
            })
        }
        //Events
        const handleSearchBarChange = (event: SyntheticEvent, value: SearchResult) => {
            //Ignore empty values
            if (!value) {
                return;
            }

            //Get the UserAccount from the search result
            const newUser: UserAccount = value.object as UserAccount;

            //Ignore if the member is already added
            if (teamMembers.findIndex((member) => member.user.uuid === newUser.user.uuid) >= 0) {
                dispatch(show({
                    message: "Membro já adicionado no time",
                    type: "warning"
                }));
                return;
            }

            //Add the member on the top of members list
            setTeamMembers([newUser, ...teamMembers]);

            dispatch(show({
                message: `${newUser.fullname} adicionado ao time`,
                type: "info"
            }))
        }

        //Functions
        async function updateTeam() {
            try {
                //Enable loading state
                setLoading(true);

                //Call the API
                const updatedTeam = await TeamService.update(selectedTeam.guid, {
                    name: teamFromDialog.name,
                    members: teamMembers.map((member: UserAccount): string => member.user.uuid)
                });

                //Update the team on the interface
                const updatedTeamsList = [...teams];
                const indexOfUpdatedItem = updatedTeamsList.findIndex((team) => team.guid === selectedTeam.guid);
                updatedTeamsList[indexOfUpdatedItem] = updatedTeam;
                setTeams(updatedTeamsList);

                //Show success message and close this dialog
                dispatch(show({
                    message: "Time alterado com sucesso",
                    type: 'success'
                }));
                setTeamDataDialog(false);

            } catch (e) {
                //Show error
                console.error(e);
                dispatch(show({
                    message: new ErrorWrapper(e).message,
                    type: 'error'
                }))
            } finally {
                //Disable loading state
                setLoading(false);
            }
        }

        return (
            <Dialog
                open={teamDataDialog}
                fullScreen={true}
            >
                {/**App Bar */}
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setTeamDataDialog(false)}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                {/* Conteúdo do dialog */}
                <Container>
                    <div className='ta-c header-container'>
                        <h3>{selectedTeam?.name}</h3>
                    </div>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab} onChange={handleTabChange} aria-label="Menu de abas">
                            <Tab label="Dados do time" />
                            <Tab label="Membros" />
                        </Tabs>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'row-reverse' }}>
                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                            <Button variant="contained" startIcon={<Save />} onClick={() => updateTeam()}>
                                Salvar
                            </Button>
                        </Stack>
                    </Box>
                    {/*Panel 0*/}
                    <TabPanel value={tab} index={0}>
                        <Box className='form-container'>
                            <h4>Dados do time</h4>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField label='Nome' variant='outlined' value={teamFromDialog?.name} onChange={handleNameChange} fullWidth={true} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className='form-container'>
                            <h4>Informações do time</h4>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <p><b>GUID: </b>{selectedTeam?.guid}</p>
                                </Grid>
                            </Grid>
                        </Box>
                    </TabPanel>
                    {/* Panel 1*/}
                    <TabPanel value={tab} index={1}>
                        <SearchBar
                            searchMode="user-accounts"
                            fullWidth={true}
                            onChange={handleSearchBarChange}
                            sx={{ mb: 2, mt: 2 }}
                            renderInputProps={{
                                label: "Adicionar membro do grupo",
                            }}
                        />
                        <DataGrid
                            getRowId={(userAccount) => userAccount.user.uuid}
                            rows={teamMembers}
                            columns={teamMembersColDef}
                            loading={loading}
                            isRowSelectable={() => false}
                            rowCount={teamMembers.length}
                            pageSize={teamMembers.length}
                            paginationMode={'client'}
                            onPageChange={(page: number) => { setTeamPage(page + 1) }}
                        />
                    </TabPanel>
                    <Fab color='primary' sx={{ position: 'fixed', display: { xs: 'inherit', md: 'none' } }} onClick={() => updateTeam()}>
                        <Save />
                    </Fab>
                </Container>
            </Dialog>
        )
    }

    /**
     * Dialog used to update CUSTOMER_SUBUSER data
     * @returns 
     */
    function SubuserDataDialog(): JSX.Element {
        //State props
        const [subuserDataTab, setSubuserDataTab] = React.useState(0);
        const [userFromDialog, setUserFromDialog] = React.useState<UserAccount>(null!);
        const [checkedRoles, setCheckedRoles] = React.useState<Set<string>>(new Set<string>());

        //Effect to set the userFromDialog data
        useEffect(() => {
            //Make a copy from the select user on the global state
            setUserFromDialog({
                ...selectedUser,
            });

            //Store the checked roles on the state set
            setCheckedRoles(new Set<string>(selectedUser?.roles || []));
        }, []);

        //Events
        const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
            setSubuserDataTab(newTab);
        }
        const handleFullnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setUserFromDialog({
                ...userFromDialog,
                fullname: event.target.value
            });
        }
        const handlePreferredNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setUserFromDialog({
                ...userFromDialog,
                preferredName: event.target.value
            });
        }
        const handleRoleCheckboxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
            //Initialize checked roles
            const newCheckedRoles = new Set<string>();
            checkedRoles.forEach(role => newCheckedRoles.add(role));

            //Update the roles set
            if (event.currentTarget.checked) {
                newCheckedRoles.add(event.currentTarget.value);
            }
            else {
                newCheckedRoles.delete(event.currentTarget.value);
            }

            //Change the set on state
            setCheckedRoles(newCheckedRoles);

            //Change the roles from user in dialog state prop
            setUserFromDialog({
                ...userFromDialog,
                roles: Array.from(newCheckedRoles.values())
            });
        }

        //Functions
        async function updateUser() {
            try {
                //Reset the UI
                setLoading(true);

                //Call the API
                const updatedUser = await CustomerSubuserService.updateUser(userFromDialog.user.uuid, {
                    fullname: userFromDialog.fullname,
                    preferredName: userFromDialog.preferredName,
                    roles: userFromDialog.roles
                });

                //Update the user on the table (stored in global state)
                const newUsersState = [...users];
                const indexOfTheUpdatedUser = newUsersState.findIndex(u => u.user.uuid === userFromDialog.user.uuid);
                newUsersState[indexOfTheUpdatedUser] = updatedUser;

                //Update the state
                setUsers(newUsersState);

                //Close the dialog
                setSubuserDataDialog(false);

                dispatch(show({
                    message: "Alteração realizada com sucesso",
                    type: 'success'
                }));
            } catch (e) {
                console.error(e);
                dispatch(show({
                    message: "Ocorreu um erro ao realizar alteração: " + new ErrorWrapper(e).message,
                    type: 'error'
                }));
            } finally {
                //Reset the UI
                setLoading(false);
            }
        }

        //Component
        return (
            <Dialog
                fullScreen={true}
                open={subuserDataDialog}
                onClose={() => setSubuserDataDialog(false)}
            >
                {/**App Bar */}
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setSubuserDataDialog(false)}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                {/**Dialog content */}
                <Container>
                    <div className='ta-c header-container'>
                        <h3>{selectedUser?.fullname}</h3>
                        <p className='description'>{selectedUser?.user?.email}</p>
                    </div>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={subuserDataTab} onChange={handleTabChange} aria-label="Menu de abas">
                            <Tab label="Dados da conta" />
                            <Tab label="Permissões" />
                        </Tabs>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'row-reverse' }}>
                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                            <Button variant="contained" startIcon={<Save />} onClick={() => updateUser()}>
                                Salvar
                            </Button>
                        </Stack>
                    </Box>
                    <TabPanel value={subuserDataTab} index={0}>
                        <Box className='form-container'>
                            <h4>Informações da conta</h4>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={7}>
                                    <TextField label='Nome completo' variant='outlined' value={userFromDialog?.fullname} onChange={handleFullnameChange} fullWidth={true} />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <TextField label='Nome de apresentação' variant='outlined' value={userFromDialog?.preferredName} onChange={handlePreferredNameChange} fullWidth={true} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className='user-info-container form-container'>
                            <h4>Status do usuário</h4>
                            <Grid className='' container spacing={2}>
                                {/**Left column */}
                                <Grid item xs={12} md={6}>
                                    {/**User uuid */}
                                    <p><b>UUID do usuário</b></p>
                                    <p>{userFromDialog?.user?.uuid}</p>
                                    <hr />
                                    {/**Account blocked info */}
                                    <p><b>Conta bloqueada</b></p>
                                    <p>
                                        {
                                            (userFromDialog?.user?.blockDate) ?
                                                "Bloqueada em " + new Date(userFromDialog?.user?.blockDate).toLocaleDateString() :
                                                "Não"
                                        }
                                    </p>
                                    <hr />
                                    {/**Preferred region */}
                                    <p><b>Região de preferência do usuário</b></p>
                                    <p>{userFromDialog?.user?.preferredRegion}</p>
                                    <hr />
                                </Grid>
                                {/**Right column */}
                                <Grid item xs={12} md={6}>
                                    {/**Account creation date */}
                                    <p><b>Data de criação da conta</b></p>
                                    <p>{new Date(userFromDialog?.user?.creationDate).toLocaleDateString()}</p>
                                    <hr />
                                    {/**Email confirmation info */}
                                    <p><b>Status de confirmação de e-mail</b></p>
                                    <p>
                                        {
                                            (userFromDialog?.user?.emailConfirmationDate) ?
                                                "Confirmado em " + new Date(userFromDialog?.user?.emailConfirmationDate).toLocaleDateString() :
                                                "Ainda não confirmado"
                                        }
                                    </p>
                                    <hr />
                                </Grid>
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel value={subuserDataTab} index={1}>
                        <React.Fragment>
                            {CustomerSubuserRoles.map(role => (
                                <div key={role.value}>
                                    <FormControlLabel
                                        label={role.name}
                                        control={
                                            <Checkbox
                                                checked={checkedRoles.has(role.value)}
                                                value={role.value}
                                                onChange={handleRoleCheckboxChanged}
                                            />
                                        }
                                    />
                                    <p>{role.description}</p>
                                    <hr />
                                </div>
                            ))}
                        </React.Fragment>
                    </TabPanel>
                    <Fab color='primary' sx={{ position: 'fixed', display: { xs: 'inherit', md: 'none' } }} onClick={() => updateUser()}>
                        <Save />
                    </Fab>
                </Container>
            </Dialog>
        );
    }

    /**
    * Dialog used to add new users
    * @returns 
    */
    function AddUserDialog(): JSX.Element {
        //State
        const [addUserDialogTab, setAddUserDialogTab] = useState(0);
        const [email, setEmail] = useState<string>("");
        const [emailAddress, setEmailAddress] = useState<string>("");
        const [fullname, setFullname] = useState<string>("");
        const [preferredName, setPreferredName] = useState<string>("");
        const [canSendEmailInvite, setCanSendEmailInvite] = useState(false);
        const [selectedDomain, setSelectedDomain] = useState("");

        const [loading, setLoading] = useState(false);
        const [error, setError] = useState<string>(null!);

        //Effect that observe the email prop
        useEffect(() => {
            setCanSendEmailInvite(
                /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) &&
                preferredName.length > 0 &&
                fullname.length > 0
            );
        }, [email, preferredName, fullname]);

        //Effect used to initialize the initial value of selectedDomain
        useEffect(() => {
            if (domains.length > 0) {
                setSelectedDomain(domains[0].name);
            }
        }, []);



        //Event functions
        const handleEmailInputChange = (event: any) => {
            setEmail(event.target.value);
        };
        const handleFullnameChange = (event: any) => {
            setFullname(event.target.value);
            setPreferredName(event.target.value);
        }
        const handlePreferredNameChange = (event: any) => {
            setPreferredName(event.target.value);
        }
        const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
            setAddUserDialogTab(newTab);
        }
        const handleEmailAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value + '@' + selectedDomain);
            setEmailAddress(event.target.value);
        }
        const handleSelectedDomainChange = (event: SelectChangeEvent<string>) => {
            setSelectedDomain(event.target.value);
        }

        //Functions

        /**
         * Register an customer subuser account using an verified domain
         */
        function registerWithVerifiedDomain() {
            //Set the loading flag to true
            setLoading(true);

            CustomerSubuserService.register({
                email: email,
                preferredName: preferredName,
                fullname: fullname
            })
                .then(registeredUser => {
                    //Include the registered user into the list
                    setUsers([...users, registeredUser]);

                    //Show success message
                    dispatch(show({
                        message: "Usuário cadastrado com sucesso",
                        type: 'success'
                    }))

                    //Close this dialog
                    setAddUserDialogVisible(false);
                })
                .catch((e) => {
                    console.error(e);
                    dispatch(show({
                        message: `Ocorreu um erro ao cadastrar usuário da organização: ${new ErrorWrapper(e).message}`,
                        type: 'error'
                    }))
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        /**
         * Send the email invite to the user
         */
        async function sendEmailInvite() {
            try {
                setLoading(true);

                //Call the create invite API
                const createdInvite = await CustomerSubuserEmailInviteService.sendInvite({
                    email: email,
                    preferredName: preferredName,
                    fullname: fullname
                });

                //Add the invite into the list
                const allInvites = [...invites, createdInvite];

                //Refresh UI
                setInvites(allInvites);

                //Close this dialog
                setAddUserDialogVisible(false);

                //Notify the user
                dispatch(show({
                    message: "Convite enviado com sucesso",
                    type: 'success'
                }))
            } catch (e) {
                console.error(e);
                const error = new ErrorWrapper(e).message;
                setError(error);

                //Notify the user
                dispatch(show({
                    message: "Ocorreu um erro ao realizar operação: " + error,
                    type: 'error'
                }))
            } finally {
                setLoading(false);
            }

        }

        return (
            <Dialog
                open={addUserDialogVisible}
                onClose={() => { setAddUserDialogVisible(false) }}
            >
                <Container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h3 className="ta-c">Adicionar novo usuário</h3>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={addUserDialogTab} onChange={handleTabChange} aria-label="Menu de abas">
                            <Tab label="Por convite" />
                            <Tab label="Por domínio verificado" />
                        </Tabs>
                    </Box>
                    {/*Send invite by email*/}
                    <TabPanel value={addUserDialogTab} index={0}
                    >
                        <p>
                            Através dessa opção, você poderá adicionar um usuário à sua organização através de um convite por e-mail.
                        </p>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                            id="emailInviteForm"
                        >
                            <TextField name="email" value={email} label="Email" type="email" variant="outlined" onChange={handleEmailInputChange} />
                            <TextField name="fullname" value={fullname} label="Nome completo" variant="outlined" onChange={handleFullnameChange} />
                            <TextField name="preferredName" value={preferredName} label="Nome de apresentação" variant="outlined" onChange={handlePreferredNameChange} />
                            <LoadingButton loading={loading} color="success" variant='contained' onClick={sendEmailInvite} disabled={!canSendEmailInvite}>Enviar convite por e-mail</LoadingButton>
                        </Box>
                    </TabPanel>
                    {/*Add by verified domain*/}
                    <TabPanel value={addUserDialogTab} index={1}
                    >
                        <p>Através dessa opção, você poderá adicionar um usuário à sua organização sem precisar de confirmação por e-mail.</p>
                        <Box
                            sx={{
                                padding: 1
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <TextField name="emailAddress" autoComplete="off" value={emailAddress} label="Email" type="email" variant="outlined" onChange={handleEmailAddressChange} />
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <b>@</b>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Select
                                        value={selectedDomain}
                                        label="Domínio"
                                        onChange={handleSelectedDomainChange}
                                        fullWidth
                                    >
                                        {
                                            domains.map((domain) =>
                                            (
                                                <MenuItem key={domain.challengeId} value={domain.name}>{domain.name}</MenuItem>
                                            )
                                            )
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle2'>{email}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField name="fullname" value={fullname} label="Nome completo" variant="outlined" onChange={handleFullnameChange} fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField name="preferredName" value={preferredName} label="Nome de apresentação" variant="outlined" onChange={handlePreferredNameChange} fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton loading={loading} color="success" variant='contained' disabled={!canSendEmailInvite} fullWidth onClick={() => registerWithVerifiedDomain()}>Cadastrar usuário</LoadingButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </TabPanel>
                    {/**Error interface */}
                    <Alert color='error' sx={{ display: (error) ? 'inherit' : 'none' }}>{error}</Alert>
                </Container>
            </Dialog>
        );
    }

    return (
        <div>
            <Container sx={{ mb: 4 }}>
                <div className='ta-c'>
                    <h2>Meus Usuários e Times</h2>
                    <p>Gerencie os usuários e times da sua organização. Clique nas opções usuários ou times abaixo</p>
                </div>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabItems />
                    </Box>
                    {/*Tab users */}
                    <TabPanel value={tab} index={0}>
                        <Container sx={{ mt: 2 }}>
                            {/**Button area */}
                            <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                <LoadingButton color="primary" variant='contained'
                                    onClick={() => setAddUserDialogVisible(true)}>Adicionar usuário</LoadingButton>
                            </Box>
                            {/**Subusers table area */}
                            <><h3>Usuários da sua organização</h3>
                                <Box sx={{ height: (invites.length === 0) ? '56vh' : '48vh', mt: 2 }} >
                                    <DataGrid
                                        getRowId={(userAccount) => userAccount.user.uuid}
                                        rows={users}
                                        columns={subusersColumnDef}
                                        loading={loading}
                                        page={userPage - 1}
                                        isRowSelectable={() => false}
                                        onCellClick={handleSubusersCellClick}
                                        {...paginationProps(usersPagination)}
                                        onPageChange={(page: number) => { setUserPage(page + 1) }}
                                    />
                                </Box>
                                {/**Subusers invites table area */}
                                <div style={{ display: (invites.length === 0) ? 'none' : 'inherit' }}>
                                    <h3>Convites enviados</h3>
                                    <Box sx={{ height: '32vh', mt: 2 }}>
                                        <DataGrid
                                            getRowId={(invite) => invite.id}
                                            rows={invites}
                                            columns={invitesColumnDef}
                                            loading={loading}
                                            page={invitesPage - 1}
                                            onCellClick={handleInvitesCellClick}
                                            {...paginationProps(invitesPagination)}
                                            onPageChange={(page: number) => { setInvitesPage(page + 1) }}
                                        />
                                    </Box>
                                </div></>
                        </Container>
                    </TabPanel>
                    {/*Tab teams */}
                    <TabPanel value={tab} index={1}>
                        <Container sx={{ mt: 2 }}>
                            {/**Button area */}
                            <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                <Button color="primary" variant='contained' onClick={() => setAddTeamDialogVisible(true)}>Adicionar time</Button>
                            </Box>
                            <h3>Times da sua organização</h3>
                            {/**Table area */}
                            <Box sx={{ height: '56vh', mt: 2 }}>
                                <DataGrid
                                    getRowId={(team) => team.guid}
                                    rows={teams}
                                    columns={teamsColumnDef}
                                    loading={loading}
                                    page={teamPage - 1}
                                    onPageChange={(page: number) => { setTeamPage(page + 1) }}
                                    isRowSelectable={() => false}
                                    {...paginationProps(teamsPagination)}
                                />
                            </Box>
                        </Container>
                    </TabPanel>
                </Box>
            </Container>
            <AddUserDialog />
            {/* Expire sessions */}
            <Dialog onClose={() => setExpireSubuserSessionsDialog(false)} open={expireSubuserSessionsDialog}>
                <DialogTitle>Expirar sessões de usuário</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span>
                            Deseja realmente encerrar as sessões de <b>{selectedUser?.fullname} ({selectedUser?.user?.email})</b>?
                            Fazendo isso todas as sessões dessa contas serão encerradas e será necessário realizar login novamente.
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setExpireSubuserSessionsDialog(false)}>Cancelar</Button>
                    <LoadingButton onClick={expireSubuserSessions} loading={loading} color='error'>Encerrar sessões</LoadingButton>
                </DialogActions>
                <Alert color='error' sx={{ display: (error) ? 'inherit' : 'none' }}>{error}</Alert>
            </Dialog>

            {/* Delete subuser account */}
            <Dialog onClose={() => setDeleteAccountDialog(false)} open={deleteAccountDialog}>
                <DialogTitle>Deletar usuário</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span>
                            Deseja deletar a conta de <b>{selectedUser?.fullname} ({selectedUser?.user?.email})</b>?
                            Fazendo isso, este usuário será deletado permanentemente da plataforma.
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteAccountDialog(false)}>Cancelar</Button>
                    <LoadingButton onClick={deleteAccount} loading={loading} color='error'>Deletar conta</LoadingButton>
                </DialogActions>
                <Alert color='error' sx={{ display: (error) ? 'inherit' : 'none' }}>{error}</Alert>
            </Dialog>

            {/* Block/unblock subuser account */}
            <Dialog onClose={() => setBlockAccountDialog(false)} open={blockAccountDialog}>
                <DialogTitle>Bloqueio de acesso a conta de usuário</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            (selectedUser?.user?.blockDate) ?
                                (
                                    <span>
                                        Deseja realmente desbloquear a conta <b>{selectedUser?.fullname} ({selectedUser?.user?.email})</b>?
                                        Fazendo isso ele poderá acessar novamente os serviços da sua organização autorizados para ele.
                                    </span>
                                )
                                :
                                (
                                    <span>Deseja realmente bloquear a conta de <b>{selectedUser?.fullname} ({selectedUser?.user?.email})</b>?</span>
                                )
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setBlockAccountDialog(false)}>Cancelar</Button>
                    {
                        (selectedUser?.user?.blockDate) ?
                            (
                                <LoadingButton onClick={blockOrUnblock} loading={loading} color='success'>Desbloquear conta</LoadingButton>
                            )
                            :
                            (
                                <LoadingButton onClick={blockOrUnblock} loading={loading} color='error'>Bloquear conta</LoadingButton>
                            )
                    }
                </DialogActions>
                <Alert color='error' sx={{ display: (error) ? 'inherit' : 'none' }}>{error}</Alert>
            </Dialog>
            {/* Delete invite dialog */}
            <Dialog onClose={() => setConfirmDeleteInviteDialog(false)} open={confirmDeleteInviteDialog}>
                <DialogTitle>Deletar convite</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Você tem certeza que deseja deletar o convite enviado para o e-mail <b>{selectedInvite?.email}</b>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDeleteInviteDialog(false)}>Cancelar</Button>
                    <LoadingButton loading={loading} color='error' onClick={deleteInvite}>Deletar convite</LoadingButton>
                </DialogActions>
            </Dialog>
            {/* Delete team dialog */}
            <Dialog onClose={() => setConfirmDeleteTeamDialog(false)} open={confirmDeleteTeamDialog}>
                <DialogTitle>Deletar time</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Você tem certeza que deseja deletar o time <b>{selectedTeam?.name}</b>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDeleteTeamDialog(false)}>Cancelar</Button>
                    <LoadingButton loading={loading} color='error' onClick={deleteTeam}>Deletar time</LoadingButton>
                </DialogActions>
            </Dialog>
            {/* Resend invite dialog */}
            <Dialog onClose={() => setConfirmResendInviteDialog(false)} open={confirmResendInviteDialog}>
                <DialogTitle>Reenviar convite</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Você tem certeza que reenviar o convite enviado para o e-mail <b>{selectedInvite?.email}</b>? O convite anterior
                        será cancelado.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmResendInviteDialog(false)}>Cancelar</Button>
                    <LoadingButton loading={loading} color='success' onClick={resendInvite}>Reenviar convite</LoadingButton>
                </DialogActions>
            </Dialog>
            {/* Add team dialog */}
            <AddTeamDialog />
            {/* Edit team dialog */}
            <EditTeamDialog />
            {/* Subusers menus */}
            <Menu
                id="basic-menu"
                anchorEl={anchorSubusersMenu}
                open={!!anchorSubusersMenu}
                onClose={() => { setAnchorSubusersMenu(null) }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => { setSubuserDataDialog(true); setAnchorSubusersMenu(null) }}>
                    <Edit sx={{ mr: 1 }} color="success" /> Ver informações do usuário
                </MenuItem>
                <hr />
                <MenuItem onClick={() => 
                    stl.require(authUserProvider, SecurityTierLevels.SamePerson)
                        .then(() => { setExpireSubuserSessionsDialog(true); setAnchorSubusersMenu(null) })
                        .catch(() => { setRenewAccessDialog(true); setAnchorSubusersMenu(null) })
                     }>
                    <Login sx={{ mr: 1 }} color="error" /> Encerrar sessões do usuário
                </MenuItem>
                {/* Block/Unblock subuser account */}
                {selectedUser?.user?.blockDate ?
                    <MenuItem onClick={
                        () =>
                            stl.require(authUserProvider, SecurityTierLevels.SamePerson)
                                .then(() => { setBlockAccountDialog(true); setAnchorSubusersMenu(null) })
                                .catch(() => { setRenewAccessDialog(true); setAnchorSubusersMenu(null) })
                    }                    >
                        <Check sx={{ mr: 1 }} color="success" /> Desbloquear usuário
                    </MenuItem>
                    :
                    <MenuItem onClick={
                        () =>
                            stl.require(authUserProvider, SecurityTierLevels.SamePerson)
                                .then(() => { setBlockAccountDialog(true) })
                                .catch(() => { setRenewAccessDialog(true) })
                    }                  >
                        <Block sx={{ mr: 1 }} color="error" /> Bloquear usuário
                    </MenuItem>
                }
                {
                    !roles.assertCustomerOrHasSubuserRole(authUserProvider, CustomerSubuserRolesEnumeration.CSM) ? null :
                        <div>
                            <hr />
                            <MenuItem onClick={
                                () =>
                                    stl.require(authUserProvider, SecurityTierLevels.MaxLevel)
                                        .then(() => { setDeleteAccountDialog(true); setAnchorSubusersMenu(null) })
                                        .catch(() => { setRenewAccessDialog(true); setAnchorSubusersMenu(null) })
                            }>
                                <DeleteForeverOutlined sx={{ mr: 1 }} color="error" /> Deletar usuário
                            </MenuItem>
                        </div>
                }
            </Menu>
            {/* Invites menus */}
            <Menu
                id="basic-menu"
                anchorEl={anchorInvitesMenu}
                open={!!anchorInvitesMenu}
                onClose={() => { setAnchorInvitesMenu(null) }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => { setConfirmResendInviteDialog(true); setAnchorInvitesMenu(null) }}>
                    <RefreshOutlined sx={{ mr: 1 }} color="success" /> Reenviar convite
                </MenuItem>
                <hr />
                <MenuItem onClick={() => { setConfirmDeleteInviteDialog(true); setAnchorInvitesMenu(null) }}>
                    <DeleteForeverOutlined sx={{ mr: 1 }} color="error" /> Deletar convite
                </MenuItem>
            </Menu>
            {/* Teams menu */}
            <Menu
                id="basic-menu"
                anchorEl={anchorTeamsMenu}
                open={!!anchorTeamsMenu}
                onClose={() => { setAnchorTeamsMenu(null) }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => { setTeamDataDialog(true); setAnchorTeamsMenu(null); }}>
                    <Edit sx={{ mr: 1 }} color="success" /> Ver informações do time
                </MenuItem>
                <hr />
                <MenuItem onClick={() => { setConfirmDeleteTeamDialog(true); setAnchorTeamsMenu(null); }}>
                    <DeleteForeverOutlined sx={{ mr: 1 }} color="error" /> Deletar time
                </MenuItem>
            </Menu>
            {/**See subuser data dialog */}
            <SubuserDataDialog />
            {/**Renew access dialog */}
            <RenewAccessDialog
                open={renewAccessDialog}
                onClose={() => setRenewAccessDialog(false)}
                onLogin={() => setRenewAccessDialog(false)} />
            <AppNotification />
        </div>
    );
}

export default UserAndTeams;