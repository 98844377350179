import './App.css';
import React, { useContext, useEffect, useState } from "react";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemIcon, ListItemText, Menu } from "@material-ui/core";
import { AppBar, Drawer, Grid, IconButton, List, ListItem, ListItemButton, MenuItem, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { Home as HomeIcon, Security as SecurityIcon, Menu as MenuIcon, AccountCircle, PeopleOutline, DomainVerification, Article, AccountBalanceWallet, LocalSeeOutlined } from '@mui/icons-material';
import { BrowserRouter, NavLink as Link, Routes, Route, Outlet, Navigate } from "react-router-dom"

import LoginView from "./views/login/Login";
import HomeView from "./views/home/Home";
import MyAccountView from "./views/my-account/MyAccount";
import MyAccountSecurityView from "./views/my-account-security/MyAccountSecurity";
import MyAccountAccessActivitiesView from "./views/my-account-access-activities/MyAccountAccessActivities";
import AuthenticationContext from './contexts/authentication';
import UserAndTeams from './views/user-and-teams/UserAndTeams';
import CustomerSubuserInviteView from './views/customer-subuser-invite/CustomerSubuserInvite';
import DomainsView from "./views/domains/Domains";
import LicensesView from "./views/licenses/Licenses";
import { CustomerSubuserRoles, RolesContext } from './contexts/roles';
import RecoverMyPasswordView from './views/recover-my-password/RecoverMyPassword';
import ResetPasswordByEmail from './views/reset-password-by-email/ResetPasswordByEmailToken';
import AuthStatusRouter from './views/auth-status/AuthStatusRouter';
import CreateAccount from './views/create-account/CreateAccount';
import ConfirmEmailWithToken from './views/confirm-email-with-token/ConfirmEmailWithToken';
import authentication from './store/authentication';

import { CurrentContext } from "./appctx/webappContext";
import MyProfile from './views/my-profile/MyProfile';
import CacheControl from './contexts/cache-control';
import ChangeEmailWithToken from './views/change-email-with-token/ChangeEmailWithToken';
import { AccountTypes } from './models/user';
import NotFound from './views/auth-status/404-not-found/NotFound';

function App() {

  const [state, setState] = useState<any>({
    /**
     * Mark when the drawer is showing
     */
    navDrawer: false,

    /**
     * Mark when the user menu should be appearing
     */
    userMenu: false,
  });

  //Context variables
  const authUser = useContext(AuthenticationContext);
  const userRoles = useContext(RolesContext);

  /**
   * Define the breakpoint use to show/hide navigation elements
   */
  const navMediaBreakpoint = '(min-width:1200px)';

  /**
   * Indicates if the drawer will be shown on screen
   * @param {Boolean} open - Flag that indicates if the drawer will be shown
   * @returns 
   */
  const showNavDrawer = (open: boolean) => () => {
    setState({
      ...state,
      'navDrawer': open
    });
  };

  /**
   * Store the data of the navigation list items
   */
  const mainMenuItems = [
    {
      text: 'Home',
      icon: (<HomeIcon />),
      link: '/',
      enabled: () => true
    },
    {
      text: 'Segurança da minha conta',
      icon: (<SecurityIcon />),
      link: '/my-account-security',
      enabled: () => true
    },
    {
      text: 'Meu perfil',
      icon: (<AccountCircle />),
      link: '/my-profile',
      enabled: () => true
    },
    {
      text: 'Usuários e Times',
      icon: (<PeopleOutline />),
      link: '/user-and-teams',
      enabled: () => userRoles.assertCustomerOrHasSubuserRole(authUser, CustomerSubuserRoles.CSM)
    },
    {
      text: 'Domínios verificados',
      icon: (<DomainVerification />),
      link: '/domains',
      enabled: () => userRoles.assertCustomerOrHasSubuserRole(authUser, CustomerSubuserRoles.CIDM)
    },
    {
      text: 'Licenças',
      icon: (<Article />),
      link: '/organization-licenses',
      enabled: () => (authUser.is(AccountTypes.Customer) || authUser.is(AccountTypes.CustomerSubuser))
    },
  ];

  function logout() {
    authUser.signout(() => {
      setState({});
    });
  }

  /**
   * Generate the JSX.Element of the navigation list
   * @returns {JSX.Element} List of navigation items
   */
  function NavigationMenuItems() {
    return (
      <List>
        <React.Fragment>
          {mainMenuItems.filter(menuItem => menuItem.enabled()).map(((menuItem) => (
            <ListItem disablePadding key={menuItem.link}>
              <ListItemButton component={Link} to={menuItem.link}>
                <ListItemIcon>
                  {menuItem.icon}
                </ListItemIcon>
                <ListItemText primary={menuItem.text} />
              </ListItemButton>
            </ListItem>
          )))}
        </React.Fragment>
      </List>
    );
  }

  function AppContent() {
    if (authUser.isAuthenticated() === true) {
      return <AuthenticatedContent />;
    }
    else {
      return <Navigate to="/login" />
    }
  }

  function AuthenticatedContent() {
    const [anchorAuthUserMenu, setAnchorAuthUserMenu] = useState<HTMLElement | null>(null);

    const [firstAccessDialog, setFirstAccessDialog] = useState(false);

    //Refs
    const userMenuRef = React.useRef<HTMLDivElement | null>(null);

    //Event handlers
    const handleUserMenuClick = () => {
      setAnchorAuthUserMenu(userMenuRef.current || null);
    }

    //  Effect used to verify if is the first access of the user, if was, show the dialog
    //and save the timestamp.
    useEffect(() => {
      const hasFirstAccessCache = localStorage.getItem('firstAccessTimestamp');

      if (!hasFirstAccessCache) {
        const timestamp = new Date().getTime();
        localStorage.setItem('firstAccessTimestamp', timestamp.toString());
        setFirstAccessDialog(true);
      }
    }, [])

    /**
     * Create the authenticated layout
     */
    return (
      <div>
        {/*App Bar*/}
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar color="primary">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2, display: (useMediaQuery(navMediaBreakpoint) ? 'none' : 'block') }}
                onClick={showNavDrawer(!state.navDrawer)}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Klaus | Accounts
              </Typography>
              <div ref={userMenuRef}>
                {
                  //Render only if the current user is authenticated and has an avatar
                  (authentication.isAuthenticated() && authentication.user().user.hasAvatar) ?
                    (
                      <span>
                        <img src={CurrentContext.accountsCdn.server + '/' + authentication.user().user.uuid + `?cache-tmstp=${CacheControl.avatarCdn()}`} alt='User profile avatar' onClick={handleUserMenuClick}
                          style={{ height: 48, width: 48, objectFit: 'cover', borderRadius: '50%', padding: 4 }} />
                      </span>
                    )
                    //Render an default avatar icon
                    :
                    (
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleUserMenuClick}
                      >
                        <AccountCircle />
                      </IconButton>
                    )
                }
                <Menu
                  id="basic-menu"
                  anchorEl={anchorAuthUserMenu}
                  open={anchorAuthUserMenu != null}
                  onClose={() => { setAnchorAuthUserMenu(null) }}
                >
                  <MenuItem onClick={logout}>Sair</MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
        </Box>
        {/*Navigation Drawer - On small screens only*/}
        <Drawer
          anchor='left'
          open={state.navDrawer}
          onClose={showNavDrawer(false)}
        >
          {/*The navigation menu items are a function declared on this file */}
          <NavigationMenuItems />
        </Drawer>
        {/*Application main layout*/}
        <Grid container spacing={2}>
          <Grid item xs={3} sx={{ display: (useMediaQuery(navMediaBreakpoint)) ? 'block' : 'none' }}>
            <Box
              sx={{ width: '100%', maxWidth: 360 }}
            >
              <nav aria-label='main menu'>
                {/*The navigation menu items are a function declared on this file */}
                <NavigationMenuItems />
              </nav>
            </Box>
          </Grid>
          {/*Application view (routes)*/}
          <Grid item xs={(useMediaQuery(navMediaBreakpoint)) ? 9 : 12} sx={{ maxWidth: 1024 }}>
            {/*The Outlet component is the child route elements that will be shown*/}
            <Outlet />
          </Grid>
        </Grid>
        {/**First Access Dialog */}
        <Dialog open={firstAccessDialog} onClose={() => setFirstAccessDialog(false)}>
          <DialogContent>
            <DialogContentText>
              <Box sx={{ width: "100%" }}>
                <img style={{ display: "block", margin: "auto", marginBottom: "36px" }} src="../images/products-logos/logo-klaus.svg" width={150} />
                <Typography sx={{ marginBottom: "24px", textAlign: "center" }}>
                  Seja bem-vindo(a) ao <b>Klaus Accounts</b>, a plataforma de contas da <b>Klaus Tecnologia</b>. Conheça alguns dos nossos produtos:
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "24px 0px" }}>
                <a href="https://klausfiscal.com.br" target="_blank">
                  <img src="../images/products-logos/logo-kloud.svg" alt="Kloud" width={100} style={{ margin: "0px 8px" }} />
                </a>
                <a href="https://app.cartorizi.com/" target="_blank">
                  <img src="../images/products-logos/cartorize-logo.svg" alt="Cartorizi" width={100} style={{ margin: "0px 8px" }} />
                </a>
                <a href="https://app.kauditor.com.br/hacesso.aspx" target="_blank">
                  <img src="../images/products-logos/logo-kauditor.svg" alt="K-Auditor" width={100} style={{ margin: "0px 8px" }} />
                </a>
                <a href="https://audire.sng.com.br/" target="_blank">
                  <img src="../images/products-logos/logo-audire.svg" alt="Audire" width={100} style={{ margin: "0px 8px" }} />
                </a>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setFirstAccessDialog(false)}>Fechar</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } 

  return (
    <div>
      {/*View handling*/}
      <BrowserRouter>
        <Routes>
          {/*Public routes*/}
          <Route path="/auth-status/:statusCode" element={<AuthStatusRouter />} />
          <Route path="/confirm-email-with-token" element={<ConfirmEmailWithToken />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/create-account/service-consumer" element={<CreateAccount />} />
          <Route path="/customer-subuser-invite" element={<CustomerSubuserInviteView />} />
          <Route path="/login" element={<LoginView />} />
          <Route path="/login/service-consumer" element={<LoginView />} />
          <Route path="/recover-my-password" element={<RecoverMyPasswordView />} />
          <Route path="/reset-password-by-email" element={<ResetPasswordByEmail />} />
          {/* Authenticated only routes */}
          <Route element={<AppContent />}>
            <Route path="/change-email-with-token" element={<ChangeEmailWithToken />} />
            <Route path="/domains" element={<DomainsView />} />
            <Route path="/" element={<HomeView />} />
            <Route path="/my-account" element={<MyAccountView />} />
            <Route path="/my-account-security" element={<MyAccountSecurityView />} />
            <Route path="/my-account-security/account-access-activities" element={<MyAccountAccessActivitiesView />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/user-and-teams" element={<UserAndTeams />} />
            <Route path="/organization-licenses" element={<LicensesView />} />
            <Route path="*" element={<NotFound />}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
