import { createContext } from "react";
import { AccountTypes, AuthenticationContextData, Roles } from "../models/user";

export enum CustomerSubuserRoles {
    CSM = "CSM",
    CIDM = "CIDM"
}

//The context interface
export interface RolesContextInterface {
    assertCustomerOrHasSubuserRole(authContext : AuthenticationContextData, role : CustomerSubuserRoles) : boolean;
}

//The react context
export const RolesContext =  createContext<RolesContextInterface>({
    assertCustomerOrHasSubuserRole : (authContext : AuthenticationContextData, customerRole : CustomerSubuserRoles) : boolean => {
        //Check if user is authenticated
        if (authContext.isAuthenticated() === false) {
            return false;
        }

        //Return true is account is from a customer
        if (authContext.user().session.accountType === AccountTypes.Customer) {
            return true;
        }

        //Check for customer subuser role
        //Cast the customer subuser role to Roles enumeration
        const role : Roles = Roles[`ROLE_${CustomerSubuserRoles[customerRole]}` as keyof typeof Roles];

        if (authContext.user().session.roles.indexOf(role) >= 0) {
            return true;
        }

        return false;
    }
});