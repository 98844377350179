import axios from 'axios';
import {CurrentContext} from "../../appctx/webappContext";
import authenticationStorage from '../../store/authentication';

//Marks if the application is running on development
const DEV = process.env.NODE_ENV === "development";

//Create the AccountsApi Axios instance
const AccountsApi = axios.create({
    baseURL: CurrentContext.accountsAPI.baseURL,
    timeout: CurrentContext.accountsAPI.timeout,
    withCredentials : true
})

//Adding request interceptors
AccountsApi.interceptors.request.use((config) => {
    config.headers = { 
        'Authorization' : `Bearer ${authenticationStorage.getToken()}`,
        "Accept" : "application/json"
    }
    return config;
});

AccountsApi.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const responseHeaders = error?.response?.headers || false;
    if (error?.response?.status === 401) {
        if (responseHeaders && responseHeaders['x-auth-status']) {
            const authStatus = responseHeaders['x-auth-status'] as string;
    
            //If the auth status does not starts with 2 redirect to auth-status page
            if (!authStatus.startsWith('2')) {
                window.location.href = `/auth-status/${authStatus}`;
            }
        }

    }
    
    // If there is no response just reject the error
    if (!error.response) return Promise.reject(error);
    
    // If there is and the status is 5xx change the x-reason to default message
    if (Math.floor(error.response.status / 100) === 5) {
        error.response.headers["x-reason"] = "Ocorreu um erro no servidor"
        console.error(error)
    }
    
    return Promise.reject(error);
});

export default AccountsApi;