import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import { Box } from "@material-ui/system";
import { SecurityRounded, Visibility, VisibilityOff, Warning } from "@mui/icons-material";
import { Button, Container, DialogContentText } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom"

import { AppNotification, LoadingButton } from "../../components";
import AuthenticationContext from "../../contexts/authentication";
import StlInterceptor from "../../contexts/stl";
import AuthenticationService from "../../services/accounts/authentication";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { AccountTypes } from "../../models/user";
import RenewAccessDialog from "../renew-access/RenewAccessDialog";
import SecurityTierLevels from "../../security/stl";

//Redux
import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import PasswordStrenght from "../../components/PasswordStrenght";
import { calculatePasswordStrength } from "../../utils/passwordUtil";
import PinInput from "react-pin-input";
import authentication from "../../store/authentication";

function MyAccountSecurity(): JSX.Element {

    //Redux props
    const dispatch = useAppDispatch();

    //State props
    const [expireSessionsDialog, setExpireSessionsDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [renewAccessDialog, setRenewAccessDialog] = useState(false);
    const [passwordChangeDrawer, setPasswordChangeDrawer] = useState(false);
    const [emailChangeDrawer, setEmailChangeDrawer] = useState(false);

    const [verificationCodeDialog, setVerificationCodeDialog] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [loadingVerificationCodeSubmit, setLoadingVerificationCodeSubmit] = useState(false);

    //Context props
    function useAuthentication() {
        return useContext(AuthenticationContext);
    }
    function useStl() {
        return useContext(StlInterceptor);
    }
    let navigate = useNavigate();
    let authUser = useAuthentication();
    let stl = useStl();

    //Event handlers
    const handlePasswordChangeDrawerOpen = () => {
        setPasswordChangeDrawer(true);
    }
    const handleEmailChangeDrawerOpen = () => {
        setEmailChangeDrawer(true);
    }

    const handleCodeChange = (value: string) => {
        value = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
        setVerificationCode(value.slice(0, 6));
    };

    async function verifyVerificationCode(verificationCode: string){
        setLoadingVerificationCodeSubmit(true);

        const resp = await AuthenticationService.updateEmailWithVerificationCode(verificationCode)
        .then(() => {
            setVerificationCodeDialog(false)
            dispatch(show({ type: 'success', message: 'E-mail alterado com sucesso' }))
        })
        .catch(error => {
            dispatch(show({
                type: 'error',
                message: `Ocorreu um erro: ${new ErrorWrapper(error).message}`
            }))
        })
        .finally(()=> {
            setLoadingVerificationCodeSubmit(false);
        })
    }

    /**
     * Trigger accounts service to expire all user sessions
     */
    function expireUserSessions() {
        //Enable loading
        setLoading(true);

        try {
            //Expire authenticated user sessions
            AuthenticationService.expireUserSessions();

            //Close the dialog
            setExpireSessionsDialog(false);

            //Logout the authenticated user
            authUser.signout(null!);

            //Navigate to login
            navigate("/login");
        } catch (e) {
            console.error(e);
            const error = new ErrorWrapper(e);
            dispatch(show({
                message: "Ocorreu um erro ao alterar a senha: " + error.message,
                type: 'error'
            }))
        } finally {
            setLoading(false);
        }
    }

    function EmailConfirmationStatus(): JSX.Element {
        //Dialog resend email confirmation mail state 
        const [open, setOpen] = useState(false);
        const [loadingSend, setLoadingSend] = useState(false);

        //If email is already confirmated
        if (authUser.user().user.emailConfirmationDate) {
            const emailConfirmationDate = new Date(authUser.user().user.emailConfirmationDate);
            return <p>Email confirmado em: {emailConfirmationDate.toLocaleDateString()}</p>
        }

        /**
         * Resend the email confirmation mail
         */
        const sendEmail = () => {
            setLoadingSend(true)
            AuthenticationService.sendAccountConfirmationMail(authUser.user().user.email)
                .then(() => {
                    setOpen(false)
                    dispatch(show({ type: 'success', message: 'Email enviado' }))
                })
                .catch(error => {
                    dispatch(show({
                        type: 'error',
                        message: `Ocorreu um erro: ${new ErrorWrapper(error).message}`
                    }))
                })
                .finally(() => setLoadingSend(false))
        }

        //Else show alert and dialog to resend email confirmation mail
        return (
            <>
                <Alert color='warning' severity='warning'>
                    Email não confirmado. <Button sx={{ p: 0, color: '#663C00', textTransform: 'inherit' }}
                        onClick={() => setOpen(true)}>Clique aqui para reenviar email de confirmação.</Button>
                </Alert>
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle >
                        Deseja reenviar o email de confirmação?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Com isso, verifique a caixa de entrada do seu email primário e siga as instruções para realizar a confirmação. Verifique também o seu SPAM.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Voltar</Button>
                        <LoadingButton onClick={sendEmail} loading={loadingSend}>Enviar</LoadingButton>
                    </DialogActions>
                </Dialog>
            </>
        )

    }

    /**
     * Password change drawer
     * @returns 
     */
    function PasswordChangeDrawer(): JSX.Element {
        //State props
        const [newPassword, setNewPassword] = useState('');
        const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
        const [canChangePassword, setCanChangePassword] = useState(false);
        const [error, setError] = useState('');
        const [loading, setLoading] = useState(false);

        const [validConfirmPassword, setValidConfirmPassword] = useState(false)
        const [showPassword, setShowPassword] = useState(false)
        const [passwordStrenght, setPasswordStrenght] = useState(0)

        //Effect to observe newPasswordConfirmation state prop to check for errors
        useEffect(() => {
            //Reset errors and button disable
            setError(null!);
            setCanChangePassword(
                newPassword.length >= 6 &&
                calculatePasswordStrength(newPassword) >= 1.5 &&
                validConfirmPassword
            );

            
        }, [newPassword, newPasswordConfirmation]);

        //Functions
        async function updatePassword() {
            //Call the request
            try {
                setLoading(true);
                await AuthenticationService.updatePassword({
                    password: newPassword
                });

                //Reset fields and close drawer
                setNewPassword('');
                setNewPasswordConfirmation('');
                setPasswordChangeDrawer(false);

                dispatch(show({
                    message: "Senha alterada com sucesso",
                    type: 'success'
                }))
            } catch (e) {
                const error = new ErrorWrapper(e);
                setError(error.message);

                dispatch(show({
                    message: "Ocorreu um erro ao alterar a senha: " + error.message,
                    type: 'error'
                }))
            } finally {
                setLoading(false);
            }
        };

        //Inner components
        function ErrorAlert(): JSX.Element {
            if (error) {
                return <Alert color='error'>{error}</Alert>;
            }
            else {
                return <span>{error}</span>
            }
        }

        //Handle password change
        const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            //Update at the same time fullname and preferred name
            setNewPassword(event.target.value)

            if (newPasswordConfirmation !== "") {
                if (newPasswordConfirmation === event.target.value) {
                    setValidConfirmPassword(true)
                } else {
                    setValidConfirmPassword(false)
                }
            }

        }

        function handlePasswordStrenght(strenght: number) {
            setPasswordStrenght(strenght)
        }
    
        const handlePasswordVisibilityToggle = () => {
            if (showPassword) {
                setShowPassword(false)
            } else {
                setShowPassword(true)
            }
        }
    
        const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (newPassword === event.target.value) {
                setValidConfirmPassword(true)
            } else {
                setValidConfirmPassword(false)
            }
            setNewPasswordConfirmation(event.target.value)
        }

        return (
            <Dialog
                open={passwordChangeDrawer}
                onClose={() => { setPasswordChangeDrawer(false) }}
            >
                <Container
                    sx={{
                        padding: 2
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 380
                        }}
                    >
                        <h4 className='ta-c'>Insira sua nova senha</h4>
                        {/*Password*/}
                        <Box sx={{mb: 2}}>
                            <TextField
                                value={newPassword}
                                label='Senha'
                                onChange={handlePasswordChange}
                                type={showPassword ? 'text' : 'password'}
                                fullWidth
                                required={true}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton onClick={handlePasswordVisibilityToggle}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <PasswordStrenght password={newPassword} passwordStrenghtCallback={handlePasswordStrenght} />
                        </Box>
                        {/*Confirm Password*/}
                        <TextField
                            value={newPasswordConfirmation}
                            label='Confirmar Senha'
                            onChange={handleConfirmPasswordChange}
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            error={(!validConfirmPassword && newPasswordConfirmation !== "")}
                            helperText={(!validConfirmPassword && newPasswordConfirmation !== "") ? "As senhas não conferem" : null}
                            required={true}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={handlePasswordVisibilityToggle}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <ErrorAlert />
                        <LoadingButton loading={loading} onClick={updatePassword} sx={{ mt: 2 }} color='success' variant='contained' disabled={!canChangePassword}>Alterar senha</LoadingButton>
                    </Box>
                </Container>
            </Dialog>
        );
    }

    /**
     * Email change drawer
     */
    function EmailChangeDrawer(): JSX.Element {
        //State props
        const [newEmail, setNewEmail] = useState('');
        const [canUpdateMainEmail, setCanUpdateMainEmail] = useState(false);
        const [error, setError] = useState<string>(null!);

        //Effect to observe newEmail prop
        useEffect(() => {
            setCanUpdateMainEmail(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail));
        }, [newEmail])

        //Event handlers
        const handleNewEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setNewEmail(event.target.value);
        };

        //Functions
        async function updateMainEmail() {
            try {
                //Call service
                setError(null!);
                setLoading(true);
                await AuthenticationService.sendEmailChangeMail({
                    newEmail: newEmail
                })

                //Reset field and close drawer
                setNewEmail('');
                setEmailChangeDrawer(false);

                dispatch(show({
                    message: "Email de troca enviado com sucesso.",
                    type: 'success'
                }))
            } catch (e) {
                //Capture errors
                const error = new ErrorWrapper(e);
                setError(error.message);

                dispatch(show({
                    message: "Ocorreu um erro ao alterar a senha: " + error.message,
                    type: 'error'
                }))
            } finally {
                //Set loading false
                setLoading(false);
            }
        }

        //Inner components
        function ErrorAlert(): JSX.Element {
            if (error) {
                return <Alert color='error'>{error}</Alert>;
            }
            else {
                return <span></span>
            }
        }

        return (
            <Dialog
                open={emailChangeDrawer}
                onClose={() => { setEmailChangeDrawer(false) }}
            >
                <Container
                    sx={{
                        padding: 2
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 380
                        }}
                    >
                        <h4 className='ta-c'>Trocar e-mail principal da conta</h4>
                        <p className='ta-c'>
                            Insira abaixo o e-mail que será seu novo e-mail primário de acesso a sua conta. Você receberá um e-mail com um código de verificação que será 
                            utilizado clicando no botão <b>"Inserir código de verificação"</b> ao lado.
                        </p>
                        <TextField
                            name="newEmail"
                            value={newEmail}
                            label="Nova e-mail"
                            type="email"
                            variant="outlined"
                            onChange={handleNewEmailChange}
                        />
                        <LoadingButton loading={loading} onClick={updateMainEmail} sx={{ mt: 2 }} color='success' variant='contained' disabled={!canUpdateMainEmail}>Enviar e-mail</LoadingButton>
                        <ErrorAlert />
                    </Box>
                </Container>
            </Dialog>
        );
    }

    /**
     * Main component
     */
    return (
        <Container>
            <div className="ta-c">
                <h2>Segurança da minha conta</h2>
                <p>Configurações e ações para segurança e controle de acesso da sua conta</p>
            </div>
            {/* Expire sessions area*/}
            <Box
                className="header-area"
            >
                <h3>Expirar sessões abertas</h3>
                <p className="desc">Expire todas as sessões abertas na sua conta. Isso fará com que qualquer dispositivo que esteja atualmente conectado em
                    sua conta tenha que fazer login novamente.</p>
                <hr />
                <LoadingButton
                    loading={loading}
                    variant="outlined"
                    color="error"
                    onClick={() =>
                        stl.require(authUser, SecurityTierLevels.MaxLevel)
                            .then(() => setExpireSessionsDialog(true))
                            .catch(() => setRenewAccessDialog(true))
                    }
                >
                    <SecurityRounded sx={{ mr: 1, display: stl.hasAtLeast(authUser, SecurityTierLevels.MaxLevel) ? 'none' : 'inherit' }} />
                    Encerrar sessões
                </LoadingButton>
            </Box>
            {/* Manage passwords */}
            <Box
                className="header-area"
            >
                <h3>Gerencie sua senha</h3>
                <hr />
                <LoadingButton
                    loading={loading}
                    onClick={() =>
                        stl.require(authUser, SecurityTierLevels.MaxLevel)
                            .then(() => handlePasswordChangeDrawerOpen())
                            .catch(() => setRenewAccessDialog(true))
                    }
                >
                    <SecurityRounded sx={{ mr: 1, display: stl.hasAtLeast(authUser, SecurityTierLevels.MaxLevel) ? 'none' : 'inherit' }} />
                    Trocar a senha da minha conta
                </LoadingButton>
            </Box>
            {/* Manage account emails */}
            <Box
                className="header-area"
            >
                <h3>Meus emails</h3>
                <p className="desc">Gerencia o e-mail de acesso da sua conta</p>
                <hr />
                <p>Meu e-mail primário: <b>{authUser.user().user.email}</b></p>
                <EmailConfirmationStatus />
                <hr />
                <LoadingButton
                    //Disable if account is customer subuser
                    disabled={authUser.is(AccountTypes.CustomerSubuser)}
                    loading={loading}
                    onClick={() =>
                        stl.require(authUser, SecurityTierLevels.MaxLevel)
                            .then(() => handleEmailChangeDrawerOpen())
                            .catch(() => setRenewAccessDialog(true))
                    }
                >
                    <SecurityRounded sx={{ mr: 1, display: stl.hasAtLeast(authUser, SecurityTierLevels.MaxLevel) ? 'none' : 'inherit' }} />
                    Trocar meu e-mail primário
                </LoadingButton>

                <Button onClick={() => 
                    stl.require(authUser, SecurityTierLevels.MaxLevel)
                    .then(()=> setVerificationCodeDialog(true))
                    .catch(() => setRenewAccessDialog(true))
                } sx={{ml: 4}}>Inserir código de verificação </Button>
            </Box>
            {/* Account Access Activity */}
            <Box
                className="header-area"
            >
                <h3>Histórico de atividades da conta</h3>
                <p className="desc"> Algumas atividades de acesso da sua conta ficam registrados em nossos bancos de dados por 30 dias. Fazemos isso para te
                    oferecer uma segurança sobre o que acontece em sua conta.</p>
                <hr />
                <Link to="/my-account-security/account-access-activities">
                    <Button>Ver histórico de atividades da minha conta</Button>
                </Link>
            </Box>
            {/* Session expiration dialog */}
            <Dialog onClose={(e) => setExpireSessionsDialog(false)} open={expireSessionsDialog}>
                <DialogTitle>Expirar sessões</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Você tem certeza que deseja encerrar as sessões da sua conta? Se decidir encerrar você deverá efetuar
                        login novamente
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => setExpireSessionsDialog(false)}>Cancelar</Button>
                    <Button onClick={(e) => expireUserSessions()}>Encerrar sessões</Button>
                </DialogActions>
            </Dialog>
            <PasswordChangeDrawer />
            <EmailChangeDrawer />
            <RenewAccessDialog
                open={renewAccessDialog}
                onClose={() => setRenewAccessDialog(false)}
                onLogin={() => setRenewAccessDialog(false)} />
            <AppNotification />
            <Dialog onClose={() => setVerificationCodeDialog(false)} open={verificationCodeDialog}>
                <DialogTitle>Inserir código de verificação</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Insira abaixo o código de verificação que foi enviado para o novo e-mail
                    </DialogContentText>
                    <Box sx={{ display:"flex", justifyContent:"center", mt: 2 }}>
                        <PinInput
                            inputFocusStyle={{boxShadow: "2px 2px 2px rgba(21, 116, 255, 0.6)", borderRadius: 8}}
                            length={6}
                            type={'custom'}
                            inputStyle={{borderRadius: 4}}
                            onChange={handleCodeChange}
                            onComplete={handleCodeChange}
                            />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setVerificationCodeDialog(false)}>Cancelar</Button>
                    <LoadingButton 
                        disabled={verificationCode.length != 6}
                        loading={loadingVerificationCodeSubmit}
                        onClick={() => verifyVerificationCode(verificationCode)}>
                            Verificar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default MyAccountSecurity;