import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { AppNotification, LoadingButton } from "../../../components";
import AuthenticationService from "../../../services/accounts/authentication";
import {useAppDispatch} from '../../../redux/hooks';
import {show} from "../../../redux/features/app-global-notification/app-global-notification-slice"
import ErrorWrapper from "../../../utils/ErrorWrapper";
import { Check, Email, Warning } from "@mui/icons-material";

function SendEmailConfirmation() : JSX.Element {
    //Redux props
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    //Store the recover password email state
    const [email, setEmail] = useState(searchParams.get("email") || "");
    const [canSendEmail, setCanSendEmail] = useState(false);
    const [emailSentToUser, setEmailSentToUser] = useState(false);

    //Effect to validate if the email can be sent
    useEffect(() => {
        setCanSendEmail(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
    }, [email]);

    //Store 'loading' state
    const [loading, setLoading] = useState(false);

    //Event changes
    const handleEmailChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    //Functions
    function sendEmailConfirmationMail() {
        //Update loading state
        setLoading(true);

        AuthenticationService.sendAccountConfirmationMail(email)
        .then(() => {
            setEmailSentToUser(true);
        })
        .catch(e => {
            dispatch(show({
                message : new ErrorWrapper(e).message,
                type : 'error'
            }))
        })
        .finally(() => {
            setLoading(false);
        })
    }

    return (
        <Box
            sx={{
                margin : 'auto',
                maxWidth : '580px',
                padding : 2
            }}
        >
            <Box
                component='form'
                sx={{
                    alignItems : 'center',
                    display : 'flex',
                    height : '100vh'
                }}
            >
                <div>
                
                    {
                        (!emailSentToUser)
                        ? 
                        (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h1 className='ta-c'>Sua conta ainda não foi confirmada</h1>
                                    <Typography variant='subtitle2' className='ta-c'>Digite seu e-mail abaixo para receber o e-mail de confirmação de conta</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required type='email' label="Insira o e-mail da sua conta" fullWidth={true} value={email} onChange={handleEmailChange}  />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton variant='contained' color='success' 
                                        loading={loading} 
                                        disabled={!canSendEmail}
                                        sx={{margin : 'auto', display : 'block'}} 
                                        onClick={() => sendEmailConfirmationMail()}
                                    >
                                        Enviar e-mail
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        )
                        :
                        (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex' , justifyContent : 'center', mt : 1 }}>
                                        <Check color="success" fontSize="large"/>
                                    </Box>
                                    <h2 className='ta-c'>E-mail enviado com sucesso!</h2>
                                    <hr/>
                                    <Box sx={{ display: 'flex' , justifyContent : 'center', mt : 1 }}>
                                        <Email color="info" fontSize="medium"/>
                                    </Box>
                                    <Typography variant='subtitle1' className='ta-c'>Acesse a caixa de entrada do email <b>{email}</b> e siga as instruções do e-mail para confirmar sua conta</Typography>
                                    <hr/>
                                    <Box sx={{ display: 'flex' , justifyContent : 'center', mt : 1 }}>
                                        <Warning color="warning" fontSize="small"/>
                                    </Box>
                                    <Typography variant='subtitle2' className='ta-c'>
                                        Existe a possibilidade do e-mail cair no seu SPAM, 
                                        não esqueça de verificar caso o e-mail não apareça na sua caixa de entrada
                                    </Typography>
                                </Grid>
                            </Grid>
                        )

                    }
                    <hr/>
                    <Link to="/login">
                        <Button size="small" sx={{margin : 'auto', display : 'block'}}>Voltar para o login</Button>
                    </Link>
                </div>
            </Box>
            <AppNotification />
        </Box>        
    );
};

export default SendEmailConfirmation;