import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { Box } from "@material-ui/system";
import { OpenInNew } from "@mui/icons-material";
import { useEffect, useState } from "react";

const KlausProducts = (props: React.ComponentProps<any>) => {
    const { logo, color, description, minimalistLogo, name, link, whiteMinimalistIcon, ...other } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [moreDetails, setMoreDetails] = useState(false);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOnMouseLeave = () => {
        setAnchorEl(null)
    }

    const redirect = () => {
        window.location.href = link
        //window.open(link);
    }

    useEffect(() => {
        if (moreDetails) {
            setInterval(() => {
                setMoreDetails(false);
                setAnchorEl(null);
            }, 10000);
        }

    }, [moreDetails])

    return (
        <Box onClick={(e) => setAnchorEl(e.currentTarget)}>
            {
                moreDetails
                    ?
                    <Box>
                        <Box
                            sx={{
                                backgroundColor: color,
                                padding: "72px 36px",
                                borderRadius: 4,
                                display: "flex",
                                alignItems: "center",
                                maxHeight: "100px"
                            }}>
                            <img src={whiteMinimalistIcon} style={{ width: "24px", marginRight: "20px" }} />
                            <Box>
                                <Typography sx={{ color: "#fff", fontWeight: 600, fontSize: "24px", mb: "6px" }}>{name}</Typography>
                                <Typography sx={{ color: "#fff", fontSize: "12px" }}>{description}</Typography>
                                <Typography sx={{ marginTop: "16px" }}><Button onClick={() => { redirect(); setMoreDetails(false) }} sx={{
                                    color: "#fff", fontSize: "12px", '&:hover': {
                                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                                    },
                                }}>Ir para a aplicação<OpenInNew sx={{ ml: "4px" }} /></Button></Typography>
                            </Box>
                        </Box>
                    </Box>
                    :
                    open
                        ?
                        <Box
                            sx={{
                                backgroundColor: color,
                                padding: "72px 36px",
                                borderRadius: 4,
                            }}>
                            <Button
                                onMouseLeave={handleOnMouseLeave}
                                sx={{ width: "100%" }}
                            >
                                <Box sx={{
                                    width: "100%",
                                    backgroundSize: 'contain',
                                    display: "grid",
                                }}>
                                    <Button sx={{
                                        color: "#fff", '&:hover': {
                                            bgcolor: 'rgba(0, 0, 0, 0.5)',
                                        },
                                    }} onClick={redirect}>Ir para aplicação <OpenInNew sx={{ ml: "6px" }} /></Button>
                                    <Divider />
                                    <Button sx={{
                                        color: "#fff", '&:hover': {
                                            bgcolor: 'rgba(0, 0, 0, 0.5)',
                                        },
                                    }} onClick={() => setMoreDetails(true)}>Mais detalhes</Button>
                                </Box>
                            </Button>
                        </Box>
                        :
                        <Button
                            sx={{
                                width: "100%", padding: 0
                            }}
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handleOnMouseLeave}
                        >
                            <Box
                                sx={{
                                    backgroundColor: color,
                                    padding: "72px 36px",
                                    borderRadius: 4,
                                }}>
                                <img src={logo} width={"100%"} />
                            </Box>
                        </Button >
            }

            <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={minimalistLogo} style={{ width: "24px", marginRight: "8px" }} />
                <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>{name}</Typography>
            </Box>
        </Box >
    )

};

export default KlausProducts;