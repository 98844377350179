import { Button, Container, Grid } from "@material-ui/core";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthenticationContext from "../../contexts/authentication";
import RenewPassword from "./401-credentials-expired/RenewPassword";
import SendEmailConfirmation from "./405-confirm-email/SendEmailConfirmation-405";
import UserAccountBlocked from "./406-blocked/UserAccountBlocked-406";

const AuthStatusRouter = () : JSX.Element => {

    const params = useParams();

    //Context props
    function useAuthentication() {
        return React.useContext(AuthenticationContext)
    }
    let authentication = useAuthentication();

    //Context prop to navigate between pages
    let navigate = useNavigate();

    function AuthStatusInfo() : JSX.Element {
        const statusCode = (params.statusCode != null && /\d+/.test(params.statusCode)) ? params.statusCode : "?"; 
        switch (statusCode) {
            case "402": //Token sent by the client is invalid
            case "404": //User session expired
                return (
                <Container className='ta-c'>
                    <h3>Sua sessão expirou ou está invalidada.</h3>
                    <Link to="/login">
                        <Button color="info" fullWidth>Clique aqui e faça login novamente</Button>
                    </Link>
                 </Container>
                )
            case "401": //User credentials/password expired
                return (
                    <RenewPassword />
                );
            case "405": //User account of the client is not confirmed
                return (
                    <SendEmailConfirmation />
                );
            case "406":
                return  <UserAccountBlocked />;
            //Not implemented
            case "400":
            case "403":
            default : 
                return (
                    <Container className='ta-c'>
                        <h3>Oops, seu acesso foi negado.</h3>
                        <p>E o código de status que recebemos foi: <b>{statusCode}</b>. Infelizmente é tudo que sabemos.</p>
                    </Container>
                )
        }
    }

    /**
     * Logout for the user current account
     */
    function logout() {
        authentication.signout(() => {
            navigate("/login", {
                replace : true
            })
        });
    }

    return (
        <div>
            <AuthStatusInfo />
            <Grid container spacing={2} sx={{
             marginTop : 4   
            }}>
                <Grid item xs={12}>
                    <Link to='/'>
                        <Button color="info" fullWidth>Voltar para página principal</Button>
                    </Link>
                </Grid>
                <hr/>
                <Grid item xs={12}>
                    <Button color="error" fullWidth onClick={() => {logout()}}>Sair da minha conta</Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default AuthStatusRouter;