import { Pagination } from "../../models/pagination";
import { Team } from "../../models/team";
import { UserAccount } from "../../models/user";
import AccountsAPI from "./accountsApi";

interface RegisterTeamDTO {
    name : string,
    members : string[]
}

const TeamService = {
    /**
     * Fetch all teams from the organization
     * @param page The current page of the pagination
     * @param limit The limit of records of the pagination
     * @returns 
     */
    fetchFromOrganization : async (page : number = 1, limit : number = 10) : Promise<Team[] | null> => {
        const response = await AccountsAPI.get(`/rest/v1/customer-teams?page=${page}&limit=${limit}`);

        //If pagination data is 204, return null
        if (response.status === 204) {
            return null;
        }

        //Otherwise return the data
        return await response.data;
    },

    /**
     * Fetch pagination data from all teams of the organization
     * @param limit 
     * @returns 
     */
    paginationFromOrganization : async (limit : number = 10) : Promise<Pagination> => {
        const responseData = await (await AccountsAPI.get(`/rest/v1/customer-teams/pagination?limit=${limit}`)).data;

        //Return the pagination data
        return {
            recordsPerPage : responseData.recordsPerPage,
            totalRecords : responseData.totalRecords,
            totalPages : responseData.totalPages,
        }
    },

    /**
     * Register an new team for the organization
     * @param team 
     * @returns 
     */
    register : async (team : RegisterTeamDTO) : Promise<Team> => {
        return await (await AccountsAPI.post(`/rest/v1/customer-teams`, team)).data;
    },

    /**
     * Update an existing team
     * @param teamGuid 
     * @param teamDTO 
     * @returns 
     */
    update : async (teamGuid : string, teamDTO : RegisterTeamDTO) : Promise<Team> => {
        return await (await AccountsAPI.put(`/rest/v1/customer-teams/${teamGuid}`, teamDTO)).data;
    },

    /**
     * Fetch pagination data from members of an given team identified by its GUID
     * @param teamGuid 
     * @param limit 
     * @returns 
     */
    fetchMembersPagination : async (teamGuid : string, limit : number = 10) : Promise<Pagination> => {
        return await (await AccountsAPI.get(`/rest/v1/customer-teams/${teamGuid}/members/pagination?limit=${limit}`)).data;
    },

    /**
     * Fetch members from the team identified by its GUID
     * @param teamGuid 
     * @param page 
     * @param limit 
     * @returns 
     */
    fetchAllMembers : async (teamGuid : string) : Promise<UserAccount[] | null> => {
        const response = await AccountsAPI.get(`/rest/v1/customer-teams/${teamGuid}/members/all`);

        //Return null if there is no content on the given page
        if (response.status === 204) {
            return null;
        }

        return await response.data;
    },

    /**
     * Delete an team identified by its GUID
     * @param teamGuid 
     */
    deleteTeam : async (teamGuid : string) : Promise<Team> => {
        return  await (await AccountsAPI.delete(`/rest/v1/customer-teams/${teamGuid}`)).data;
    }
}

export default TeamService;