import { Button, Checkbox, CircularProgress } from "@material-ui/core";

const LoadingButton = (props :  React.ComponentProps<any>) => {

    const { classes, loading, done, progressColor, ...other } = props;

    if (done) {
        return (
        <Button  {...other} disabled>
            <Checkbox />
        </Button>
        );
    }
    else if (loading) {
        return (
        <Button  {...other} disabled>
            <CircularProgress sx={{color : progressColor || "white"}}/>
        </Button>
        );
    } else {
        return (
        <Button  {...other} />
        );
    }
};

export default LoadingButton;