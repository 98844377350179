import AccountsAPI from "./accountsApi";
import {AuthenticatedUser, Roles, UserAccount} from "../../models/user";
import { AccountAccessActivity } from "../../models/account-access-activity";

/**
 * Main abstraction used for  authentication methods
 */
interface UserAuthentication {
    trustDevice : boolean
}

interface AuthenticateWithPassword extends UserAuthentication{
    password : string
}

/**
 * Interface used to authenticate user with email and password
 */
interface AuthenticateWithEmailAndPassword extends UserAuthentication{
    email : string,
    password : string
}

interface UpdatePassword {
    password : string
}

interface SendEmailChangeMail {
    newEmail : string
}

interface Session {
    token : string,
    expirationDate : Date
}

interface RenewPasswordDTO {
    email : string,
    password : string,
    newPassword : string
}

/**
 * The authentication service
 */
const AuthenticationService = {
    /**
     * Authenticate the user using its combination of email and password. Return the authentication token
     * @param auth - Object used to authenticated the user
     * @returns {Promise<String>} Promise containing the user authentication token
     */
    authenticateWithEmailAndPassword : async function(auth : AuthenticateWithEmailAndPassword) : Promise<Session> {
        const flags = (auth.trustDevice) ? "?trustDevice=true" : '';

        //Try to authenticate the user
        const authResponse = await AccountsAPI.post(`/rest/v1/auth/public/tokens/create-with-email-and-password${flags}`, {
            'email' : auth.email,
            'password' : auth.password
        });

        //Fetch the response data
        const authResponseBody = authResponse.data;
        
        //Return the data
        return {
            token : authResponseBody.token,
            expirationDate : authResponseBody.expirationDate
        }
    },

    /**
     * Confirm the account stored on the token
     * @param token 
     */
    confirmEmailWithToken : async function (token : string) : Promise<void> {
        await AccountsAPI.post(`/rest/v1/auth/public/confirm-email-with-token?token=${token}`);  
    },

    expireUserSessions : async function () : Promise<void> {
        await AccountsAPI.post(`/rest/v1/auth/me/expire-sessions`);  
    },

    /**
     * Return data from the current authenticated user
     * @returns {Promise<AuthenticatedUser>}
     */
    fetchAuthenticatedUserData : async function() : Promise<AuthenticatedUser> {
        //Try to authenticate the user
        const response = await AccountsAPI.get(`/rest/v1/auth/me`);

        //Fetch response data
        const user = response.data.user;
        const account = response.data.account;
        const session = response.data.session;

        //Parsing the roles from the webservice to typescript enumeration
        const roles : Roles[] = [];
        if (session.roles) {
            session.roles.forEach((roleFromService : any) => {
                roles.push(Roles[roleFromService.authority as keyof typeof Roles]);
            })
        }

        //Return the model data
        return {
            user : user,
            account : account,
            session : {
                ...session,
                roles : roles
            }
        }
    },

    /**
     * Return the account access activities for the current user
     * @param page  - The page of the results
     * @returns 
     */
    fetchMyAccountAccessActivities : async function(page : Number = 1) : Promise<AccountAccessActivity[]>{
        //Prepare query parameters
        const queryParams = `page=${page}&limit=5`;

        //Get the api response
        const response = await AccountsAPI.get(`/rest/v1/auth/me/account-access-activities?${queryParams}`);

        //If pagination returns an response
        if (response.status === 200) {
            return (response.data as AccountAccessActivity[]);
        }
        //If pagination does not return an response
        else if (response.status === 204) {
            return null!;
        }
        //Throw error
        else {
            throw new Error("Unexpected server response status: " + response.status);
        }
    },

    /**
     * Return the owner of this account
     * @returns 
     */
    myAccountOwner : async function () : Promise<UserAccount>{
        //Fetch response from API
        const response =  await (await AccountsAPI.get(`/rest/v1/auth/me/owner`)).data;

        //Return data
        return {
            ...response
        }
    },

    /**
     * Send an email change mail to an given email to update the authenticated user main email
     * @param dto Object used on request
     */
    sendEmailChangeMail : async function (dto : SendEmailChangeMail) {
        //Send request
        await AccountsAPI.post('/rest/v1/auth/me/send-email-change-mail', dto);
    },

    /**
     * Send account confirmation mail
     * @param email 
     */
    sendAccountConfirmationMail : async function (email : string) {
        //Call the API
        await AccountsAPI.post(`/rest/v1/auth/public/send-email-confirmation?email=${email}`);
    },

    /**
     * Send an email to start password reset operation
     * @param email - The email to reset
     */
    sendPasswordResetMail : async function (email : string) {
        //Call the API
        await AccountsAPI.post(`/rest/v1/auth/public/send-password-reset-email?email=${email}`);
    },

    /**
     * Update the primary e-mail of an account using email confirmation
     * @param token 
     * @returns 
     */
    updateEmailWithToken : function (token : string) : Promise<void> {
        return AccountsAPI.put(`/rest/v1/auth/me/update-email-with-token?token=${token}`);
    },


    /**
     * Update the primary e-mail using the verification code
     * @param verificationCode 
     * @returns 
     */
    updateEmailWithVerificationCode : function (verificationCode: string) : Promise<void> {
        return AccountsAPI.put(`/rest/v1/auth/me/update-email-with-verification-code`, {
            verificationCode
        });
    },

    /**
     * Update current user password
     * @param dto 
     */
    updatePassword : async function(dto : UpdatePassword){
        //Send request
        await AccountsAPI.put(`/rest/v1/auth/me/update-password`, dto);    
    },

    /**
     * Renew the authentication token using the password as credential
     * @param auth 
     * @returns 
     */
    renewTokenWithPassword : async function(auth : AuthenticateWithPassword) : Promise<Session> {
        const flags = (auth.trustDevice) ? "?trustDevice=true" : '';

        //Try to authenticate the user
        const authResponse = await AccountsAPI.post(`/rest/v1/auth/tokens/renew-with-password${flags}`, {
            'password' : auth.password
        });

        //Fetch the response data
        const authResponseBody = authResponse.data;
        
        //Return the data
        return {
            token : authResponseBody.token,
            expirationDate : authResponseBody.expirationDate
        }
    },

    /**
     * Reset the user password using email token
     * @param newPassword 
     * @param verificationToken 
     * @returns 
     */
    resetPasswordByEmailToken : function (newPassword : string, verificationToken : string) : Promise<any> {
        return AccountsAPI.put(`/rest/v1/auth/public/reset-password-with-email-token`, {
            newPassword : newPassword,
            token : verificationToken
        });
    },

    /**
     * Renew an user password. The user must authenticate with its email and password and input the new password
     * @param dto 
     */
    renewPassword : function (dto : RenewPasswordDTO) : Promise<any> {
        return AccountsAPI.put(`/rest/v1/auth/public/renew-password`, dto);
    },

    authenticateWithToken : async function (authenticationToken: string) : Promise<Session> {
        //Try to authenticate the user
        const authResponse = await AccountsAPI.post(`/rest/v1/auth/public/tokens/authenticate-with-zaninte-auth-token`, { authenticationToken })

        //Return the data
        return {
            token: authResponse.data.token,
            expirationDate: authResponse.data.expirationDate
        }
    }
}

export default AuthenticationService;