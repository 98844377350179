export enum CacheControlStoreNames {
    AvatarCdn = 'acdntmstp'
}

const storage = sessionStorage;

const CacheControl = {

    get : (cacheName : CacheControlStoreNames) : string  => {
        let  valueFromStore = storage.getItem(cacheName.toString());
        if (!valueFromStore) {
            valueFromStore = new Date().getTime().toString();
            storage.setItem(cacheName.toString(), valueFromStore);
        }
        return valueFromStore;
    },

    refresh : (cacheName : CacheControlStoreNames) : string => {
        const value  = new Date().getTime().toString();
        storage.setItem(cacheName.toString(), value);
        return value;
    },

    avatarCdn : () : string => {
        return CacheControl.get(CacheControlStoreNames.AvatarCdn);
    },
}

export default CacheControl;