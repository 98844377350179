import { Alert, CircularProgress, Container, Grid, Switch, TextField, Typography } from "@material-ui/core";
import { Box } from "@material-ui/system";
import { AttachFile, Description, MoneyOff, Palette, People, RequestQuote, Storage, TextFields } from '@mui/icons-material';
import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { TabPanel } from '../../components';
import { ContractsQuota } from '../../models/contracts-quota';
import ContractsQuotaService from '../../services/accounts/contracts-quotas';
import ErrorWrapper from '../../utils/ErrorWrapper';

//Redux

const Subscriptions = (): JSX.Element => {

    const contractQuotaObject: ContractsQuota = {
        maxTemplateCount: 0,
        maxSubusers: 0,
        contractsIssuedPerMonth: 0,
        maxStorageBytes: 0,
        financialAnalyticsModuleEnabled: false,
        contractsFieldsAutomationEnabled: false,
        contractAttachmentsEnabled: false,
        plan: "PAY_AS_YOU_GO",
        interfaceThemesCustomizationEnabled: false
    }

    // VERIFY IF THE PARAM IN URL WAS PASSED AND SAVE IN A VARIABLE (EX.: /quotas#cartorizi)
    const tabValue = window.location.hash.slice(1).toLocaleLowerCase();

    // States
    const [value, setValue] = useState(0);
    const [contractsQuota, setContractsQuota] = useState<ContractsQuota>(contractQuotaObject);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    // Effects
    useEffect(() => {
        searchContractsQuota()
        switch (tabValue) {
            case 'cartorizi':
                setValue(0)
                break;
            default:
                setValue(0);
                break;
        }
    }, []);

    const searchContractsQuota = () => {
        setLoading(true);
        setError(null);

        ContractsQuotaService.find()
            .then(resp => {
                setContractsQuota(resp)
            })
            .catch(err => {
                const e = new ErrorWrapper(err)
                if (e.httpStatus != 404) setError(e.message)
                else {
                    setContractsQuota(contractQuotaObject)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Container>
            <div className='ta-c'>
                <div className='ta-c'>
                    <h2>Licenças</h2>
                    <p>Confira as suas licenças de uso de software da Klaus</p>
                </div>
            </div>
            {
                (error)
                    ?
                    <Alert severity="error">Não foi possível buscar as suas cotas.</Alert>
                    :
                    (loading)
                        ?
                        <Box sx={{ textAlign: "center" }}>
                            <CircularProgress />
                        </Box>
                        :
                        <Box className='header-area'>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab icon={<img src="/images/pilar_icon.svg" style={{ width: '25px' }} />} iconPosition='start' label='Cartorizi' />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <Box sx={{ padding: "10px", }}>
                                    <Grid container columnGap={3} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <Grid style={{ borderBottom: "1px solid rgba(0,0,0,0.15)", padding: "32px 0px" }} item xs={5}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                                <Description sx={{ fontSize: "40px", color: "#3590F3" }} />
                                                <Box>
                                                    <Typography fontSize={16}>Modelos de Contrato</Typography>
                                                    <Typography fontSize={14}>Modelos de contrato da organização</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", mt: "12px", justifyContent: "space-between" }}>
                                                <Typography>Permitidos</Typography>
                                                <TextField defaultValue={contractsQuota.maxTemplateCount} InputProps={{ readOnly: true }} />
                                            </Box>
                                        </Grid>

                                        <Grid style={{ borderBottom: "1px solid rgba(0,0,0,0.15)", padding: "32px 0px" }} item xs={5}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "16px", width: "100%" }}>
                                                <People sx={{ fontSize: "40px", color: "#3590F3" }} />
                                                <Box>
                                                    <Typography fontSize={16}>Sub Usuários</Typography>
                                                    <Typography fontSize={14}>Sub usuários da organização</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", mt: "12px", justifyContent: "space-between" }}>
                                                <Typography>Permitidos</Typography>
                                                <TextField defaultValue={contractsQuota.maxSubusers} InputProps={{ readOnly: true }} />
                                            </Box>
                                        </Grid>

                                        <Grid style={{ borderBottom: "1px solid rgba(0,0,0,0.15)", padding: "32px 0px" }} item xs={5}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "16px", width: "100%" }}>
                                                <MoneyOff sx={{ fontSize: "40px", color: "#3590F3" }} />
                                                <Box>
                                                    <Typography fontSize={16}>Contratos Gratuitos / Mês</Typography>
                                                    <Typography fontSize={14}>Contratos emitidos no mês da organização</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", mt: "12px", justifyContent: "space-between" }}>
                                                <Typography>Permitidos</Typography>
                                                <TextField defaultValue={
                                                    contractsQuota.contractsIssuedPerMonth !== null ? contractsQuota.contractsIssuedPerMonth : "Sem Limite"
                                                } InputProps={{ readOnly: true }} />
                                            </Box>
                                        </Grid>

                                        <Grid style={{ borderBottom: "1px solid rgba(0,0,0,0.15)", padding: "32px 0px" }} item xs={5}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "16px", width: "100%" }}>
                                                <Storage sx={{ fontSize: "40px", color: "#3590F3" }} />
                                                <Box>
                                                    <Typography fontSize={16}>Armazenamento</Typography>
                                                    <Typography fontSize={14}>Espaço de armazenamento da organização</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", mt: "12px", justifyContent: "space-between" }}>
                                                <Typography>Máximo</Typography>
                                                <Box sx={{ display: "flex" }}>
                                                    <TextField
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 1,
                                                                max: 1000,
                                                                readOnly: true,
                                                            }
                                                        }}
                                                        value={(contractsQuota.maxStorageBytes >= 1048576 && contractsQuota.maxStorageBytes < 1073741824) ?
                                                            (Number(contractsQuota.maxStorageBytes) / 1048576).toString() :
                                                            (Number(contractsQuota.maxStorageBytes) / 1073741824).toString()
                                                        }
                                                        onChange={e => {
                                                            let num: Number
                                                            Number(e.target.value) > 100000 ? num = 100000 : num = Number(e.target.value)
                                                            setContractsQuota({
                                                                ...contractsQuota, maxStorageBytes:
                                                                    (contractsQuota.maxStorageBytes >= 1048576 && contractsQuota.maxStorageBytes < 1073741824) ?
                                                                        Number(num) * 1048576 :
                                                                        Number(num) * 1073741824
                                                            })
                                                        }}
                                                        onBlur={e => {
                                                            let num: Number
                                                            Number(e.target.value) < 1 ? num = 1 : num = Number(e.target.value)
                                                            setContractsQuota({
                                                                ...contractsQuota, maxStorageBytes:
                                                                    (contractsQuota.maxStorageBytes >= 1048576 && contractsQuota.maxStorageBytes < 1073741824) ?
                                                                        Number(num) * 1048576 :
                                                                        Number(num) * 1073741824
                                                            })
                                                        }}
                                                    />
                                                    <Box>
                                                        {(contractsQuota.maxStorageBytes >= 1048576 && contractsQuota.maxStorageBytes < 1073741824) ?
                                                            <Typography>MB</Typography> :
                                                            <Typography>GB</Typography>
                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid style={{ borderBottom: "1px solid rgba(0,0,0,0.15)", padding: "32px 0px" }} item xs={5}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "16px", width: "100%" }}>
                                                <RequestQuote sx={{ fontSize: "40px", color: "#3590F3" }} />
                                                <Box>
                                                    <Typography fontSize={16}>Análise Financeira</Typography>
                                                    <Typography fontSize={14}>Acesso ao modulo de análise financeira da organização</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", mt: "12px", justifyContent: "space-between" }}>
                                                <Typography>Acesso</Typography>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Typography>{contractsQuota.financialAnalyticsModuleEnabled ? "Ativo" : "Inativo"}</Typography>
                                                    <Switch checked={contractsQuota.financialAnalyticsModuleEnabled} />
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid style={{ borderBottom: "1px solid rgba(0,0,0,0.15)", padding: "32px 0px" }} item xs={5}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "16px", width: "100%" }}>
                                                <TextFields sx={{ fontSize: "40px", color: "#3590F3" }} />
                                                <Box>
                                                    <Typography fontSize={16}>Campos Automatizados</Typography>
                                                    <Typography fontSize={14}>Acesso ao modulo de campos automatizados da organização</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", mt: "12px", justifyContent: "space-between" }}>
                                                <Typography>Acesso</Typography>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Typography>{contractsQuota.contractsFieldsAutomationEnabled ? "Ativo" : "Inativo"}</Typography>
                                                    <Switch checked={contractsQuota.contractsFieldsAutomationEnabled} />
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid style={{ borderBottom: "1px solid rgba(0,0,0,0.15)", padding: "32px 0px" }} item xs={5}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "16px", width: "100%" }}>
                                                <AttachFile sx={{ fontSize: "40px", color: "#3590F3" }} />
                                                <Box>
                                                    <Typography fontSize={16}>Inserção de Anexos</Typography>
                                                    <Typography fontSize={14}>Acesso ao modulo de inserção de anexos da organização</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", mt: "12px", justifyContent: "space-between" }}>
                                                <Typography>Acesso</Typography>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Typography>{contractsQuota.contractAttachmentsEnabled ? "Ativo" : "Inativo"}</Typography>
                                                    <Switch checked={contractsQuota.contractAttachmentsEnabled} />
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid style={{ borderBottom: "1px solid rgba(0,0,0,0.15)", padding: "32px 0px" }} item xs={5}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "16px", width: "100%" }}>
                                                <Palette sx={{ fontSize: "40px", color: "#3590F3" }} />
                                                <Box>
                                                    <Typography fontSize={16}>Personalização de interface</Typography>
                                                    <Typography fontSize={14}>Acesso ao modulo de personalização da interface do contrato</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", mt: "12px", justifyContent: "space-between" }}>
                                                <Typography>Acesso</Typography>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Typography>{contractsQuota.interfaceThemesCustomizationEnabled ? "Ativo" : "Inativo"}</Typography>
                                                    <Switch checked={contractsQuota.interfaceThemesCustomizationEnabled} />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TabPanel>
                        </Box >

            }
        </Container >
    )
}
export default Subscriptions;