import './CustomerSubuserInvite.css';

import { Alert, Checkbox, Container, FormControlLabel, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Box } from "@material-ui/system";
import { LoadingButton } from '../../components';
import { useContext, useEffect, useState } from 'react';
import ErrorWrapper from '../../utils/ErrorWrapper';
import CustomerSubuserService from '../../services/accounts/customer-subuser';
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom';
import AuthenticationContext from '../../contexts/authentication';
import PasswordStrenght from '../../components/PasswordStrenght';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { calculatePasswordStrength } from '../../utils/passwordUtil';
//import { Theme, SxProps } from "@mui/system";
import { Theme, SxProps } from "@material-ui/system";

export default function CustomerSubuserInvite() : JSX.Element {
    //States
    const [loading, setLoading] = useState(false);
    const [canCreateAccount, setCanCreateAccount] = useState(false);
    const [userPassword, setUserPassword] = useState("");
    const [trustDevice, setTrustDevice] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [confirmPassword, setConfirmPassword] = useState("")
    const [validConfirmPassword, setValidConfirmPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [passwordStrenght, setPasswordStrenght] = useState(0)
    
    //Router context props
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    //Context props
    const authUser = useContext(AuthenticationContext);

    const inviteId : string | null =  searchParams.get('inviteId');
    const invitePassword : string | null = searchParams.get('invitePassword');

    const mainBoxTheme: SxProps<Theme> = {
        ['@media(max-width:900px)']: { p: 1, width: "90%" },
        position: "fixed",
        top: "50%",
        left: "50%",
        WebkitTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
        maxWidth: "550px !important",
        minHeight: "600px",
        marginBottom: "16px",
        boxShadow: "10px 10px 100px 0px rgba(0, 0, 0, 0.25)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        overflow: "auto",
        padding: 4
    }

    //Effects
    useEffect(() => {
        setCanCreateAccount(
            userPassword.length >= 6 &&
            calculatePasswordStrength(userPassword) >= 1.5 &&
            validConfirmPassword
        );
    }, [userPassword, confirmPassword])

    //Events
    //Handle password change
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //Update at the same time fullname and preferred name
        setUserPassword(event.target.value)

        if (confirmPassword !== "") {
            if (confirmPassword === event.target.value) {
                setValidConfirmPassword(true)
            } else {
                setValidConfirmPassword(false)
            }
        }

    }

    const handleTrustDeviceChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        setTrustDevice(event.target.checked);
    }

    //Functions
    async function createAccount() {
        try {
            //Reset UI
            setError(null);
            setLoading(true);

            //Call API
            const createdAccount = await CustomerSubuserService.registerWithEmailInvite({
                inviteId : inviteId + "",
                invitePassword : invitePassword + "",
                preferredRegion : 'BRA',
                userPassword : userPassword
            });

            //Authenticate
            await authUser.authenticateWithEmailAndPassword(createdAccount.user.email, userPassword, trustDevice);

            // Send them back to the page they tried to visit when they were
            // redirected to the login page. Use { replace: true } so we don't create
            // another entry in the history stack for the login page.  This means that
            // when they get to the protected page and click the back button, they
            // won't end up back on the login page, which is also really nice for the
            // user experience.
            navigate(from, { replace: true });
            
        } catch(e) {
            console.error(e);
            const errorMessage = new ErrorWrapper(e).message;
            setError(errorMessage === "The invite was expired" ? "Esse convite expirou. Solicite a organização para enviar novamente." : errorMessage); 
        } finally {
            setLoading(false);
        }
    }

    function handlePasswordStrenght(strenght: number) {
        setPasswordStrenght(strenght)
    }

    const handlePasswordVisibilityToggle = () => {
        if (showPassword) {
            setShowPassword(false)
        } else {
            setShowPassword(true)
        }
    }

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (userPassword === event.target.value) {
            setValidConfirmPassword(true)
        } else {
            setValidConfirmPassword(false)
        }
        setConfirmPassword(event.target.value)
    }

    return (
        <Container>
            <Box
                id="formContainer"
                sx={mainBoxTheme}
            >
                <h2>Convite de Participação de Organização</h2>
                <p>Defina a senha que deseja utilizar para acessar sua conta abaixo</p>
                {/*Password*/}
                <Box sx={{mb: 2}}>
                    <TextField
                        value={userPassword}
                        label='Senha'
                        onChange={handlePasswordChange}
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        required={true}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={handlePasswordVisibilityToggle}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <PasswordStrenght password={userPassword} passwordStrenghtCallback={handlePasswordStrenght} />
                </Box>
                {/*Confirm Password*/}
                <TextField
                    value={confirmPassword}
                    label='Confirmar Senha'
                    onChange={handleConfirmPasswordChange}
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    error={(!validConfirmPassword && confirmPassword !== "")}
                    helperText={(!validConfirmPassword && confirmPassword !== "") ? "As senhas não conferem" : null}
                    required={true}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton onClick={handlePasswordVisibilityToggle}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <FormControlLabel
                    label="Continuar conectado neste dispositivo"
                    control={<Checkbox checked={trustDevice} onChange={handleTrustDeviceChange}/>}
                />
                <LoadingButton sx={{mt : 2}} disabled={!canCreateAccount} loading={loading} color='success' variant='contained' onClick={() => {createAccount()}}>
                    Criar conta
                </LoadingButton>
                <Alert
                    color='error'
                    sx ={{
                        display : (error) ? 'inherit' : 'none', mt: 1
                    }}
                >
                    {error}
                </Alert>
            </Box>
        </Container>
    );
}