export interface CustomerSubuserRole {
    name : string;
    value : string;
    description : string
}

export const  CustomerSubuserRoles : CustomerSubuserRole[] = [
    {
        name : 'Gerente de usuários',
        value : 'CSM',
        description : 'Permite que cadastre, altere e delete usuários pertencentes à organização'
    },
    {
        name : 'Gerente de Domínios de Internet',
        value : 'CIDM',
        description : 'Permite que o usuário cadastre e delete domínios da organização'
    },
]