import { createContext } from "react";
import queryString from "query-string"

export enum Params {
    SERVICE = 'service'
}
export enum ServicesConsumer {
    CONTRACTS = 'contracts'
}

//The context interface
export interface RouteParamContextInterface {
    hasParam(param: Params, value: ServicesConsumer): boolean;
    get(value: string): string | null;
}

//The react context
export const RouteParamContext = createContext<RouteParamContextInterface>({
    /**
     * Check if current URL has the requested query param
     * @param param 
     * @param value 
     * @returns 
     */
    hasParam(param: Params, value: ServicesConsumer) {
        //Current URL parsed
        const queryURL = queryString.parse(window.location.search)

        //Check if current url has the param
        const queryParam = queryURL[param];

        //Return true if value is the same of the query
        return (queryParam && queryParam === value) ? true : false;
    },
    
    get(value: string): string | null {
        //Current URL parsed
        const queryURL = queryString.parse(window.location.search)
       
        //Check if current url has the param
        const queryParam = queryURL[value];
       
        //Return true if value is the same of the query
        return queryParam ? queryParam.toString() : null;
    }
});