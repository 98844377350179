import { Button, LinearProgress, Typography } from "@material-ui/core";
import { Box } from "@material-ui/system"
import { Check, Error as ErrorIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import jwt from 'jsonwebtoken';
import { AppNotification } from "../../components";
import AuthenticationService from "../../services/accounts/authentication";
import { useNavigate } from "react-router-dom";

/**
 * Enum used to store token validation status
 */
 enum TokenValidationStatus {
    Processing,
    Failed,
    Validated
}

const ResetPasswordByEmail = () : JSX.Element => {

    //Context prop used to navigate to another page
    const navigate = useNavigate();

    //content used to get query parameter data
    const [searchParams] = useSearchParams();

    //Inner components
    function TokenValidationProgress() : JSX.Element | null{
        //Store the state of the token verification step
        const [tokenVerificationStatus, setTokenVerificationStatus] = useState<TokenValidationStatus>(TokenValidationStatus.Processing);
        const [token, setToken] = useState<string | null>(null);
        const [progress, setProgress] = useState(0);

        //Effect used to validate the token when the component is first loaded
        useEffect(() => {

            try {
                    
                //Get the token from query parameter
                const tokenFromQuery = searchParams.get("token") || null;
                if (tokenFromQuery === null) {
                    console.error("Token not found");
                    throw new Error("Token not found");
                }

                //Get the decoded JWT
                const decodedJwt = jwt.decode(tokenFromQuery) as any;
                if (decodedJwt === null) {
                    console.error("Invalid token");
                    throw new Error("Invalid token");
                }

                //Get the token expiration date to validate if it is expired
                const tokenExpirationTimestamp = decodedJwt.exp * 1000;
                const now = new Date().getTime();

                //Verify is the token expired
                if (tokenExpirationTimestamp < now) {
                    console.error("The verification token expired");
                    throw new Error("The verification token expired");
                }

                /**
                 * Call the API to confirm the account
                 */
                AuthenticationService.confirmEmailWithToken(tokenFromQuery)
                .then(() => {
                    //If any error is detected set the progress to check and set a timeout to change to step 1 (change password)
                    setTokenVerificationStatus(TokenValidationStatus.Validated);
                    setToken(tokenFromQuery);
                })
                .catch((e) => {
                    console.error("The verification on server failed", e);
                    setTokenVerificationStatus(TokenValidationStatus.Failed);
                })

                
            } catch (error) {
                setTokenVerificationStatus(TokenValidationStatus.Failed);
            }
        }, []);

        //Effect used to automatically skip to reset password step when the token is set
        const Timeout = 6000;
        useEffect(() => {
            if (token !== null) {
                //Go to login after timeout
                setTimeout(() => {
                    navigate("/login", {
                        replace : true
                    })
                }, Timeout);

                //Progress bar 
                const interval = setInterval(() => {
                    setProgress((oldProgress) => {
                        //Keep always on 100
                        if (oldProgress >= 80) {
                            clearInterval(interval);
                            return 100;
                        }

                        return oldProgress + 10;
                    });
                }, Timeout / 10);
            }
            // eslint-disable-next-line
        }, [token]);

        //Switch between token verification status components
        switch (tokenVerificationStatus) {
            case TokenValidationStatus.Processing:
                return (
                    <Box sx={{ justifyContent : 'center', mt : 4 }}>
                        <LinearProgress color="info" sx={{display : 'block', 'margin' : 'auto'}}/>
                        <p className="ta-c">Validando token de verificação</p>
                    </Box>
                );
            case TokenValidationStatus.Validated:
                return (
                    <Box sx={{ justifyContent : 'center', mt : 4 }} className='ta-c'>
                        <Check color="success" fontSize="large" sx={{display : 'block', 'margin' : 'auto'}}/>
                        <p className="ta-c">Token validado com sucesso</p>
                        <Typography variant="subtitle1" >Agora você pode colocar sua nova senha</Typography>
                        <LinearProgress variant="determinate" value={progress} />
                        <hr/>
                        <Button size="medium" onClick={() => navigate("/login", { replace : true })}>Ir para tela de login</Button>
                    </Box>
                );
                case TokenValidationStatus.Failed:
                    return (
                        <Box sx={{ justifyContent : 'center', mt : 4 }}>
                            <ErrorIcon color="error" fontSize="large" sx={{display : 'block', 'margin' : 'auto'}}/>
                            <p className="ta-c">Token de verificação inválido</p>
                            <hr/ >
                            <Link to="/recover-my-password">
                                <Button size="medium" sx={{margin : 'auto', display : 'block'}}>Iniciar recuperação de senha novamente</Button>
                            </Link>
                        </Box>
                    );
            default : 
                    return null;
        }
    }

    /**
     * Main component
     */
    return (
        <Box
            sx={{
                margin : 'auto',
                maxWidth : '480px',
                padding : 2
            }}
        >
            <Box
                sx={{
                    alignItems : 'center',
                    display : 'flex',
                    height : '100vh'
                }}
            >
                <div>

                    <TokenValidationProgress />
                    <hr/ >
                    <p className='ta-c' style={{textTransform : 'uppercase'}}><b>Ou</b></p>
                    <Link to="/login">
                        <Button size="small" color='error' sx={{margin : 'auto', display : 'block'}}>Voltar para login</Button>
                    </Link>
                </div>
            </Box>
            {/* App notification component */}
            <AppNotification />
        </Box>
    );
};

export default ResetPasswordByEmail;