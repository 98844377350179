import { CustomerSubuserEmailInvite } from "../../models/email-invite";
import { Pagination } from "../../models/pagination";
import AccountsApi from "./accountsApi";

interface SendInvite {
    fullname : string,
    preferredName : string,
    email : string
}

const CustomerSubuserEmailInviteService = {

     /**
     * Return the customer subuser from the organization of the current authenticated user
     * @returns 
     */
      fetchFromOrganization : async (page : number = 1, limit : number = 10) : Promise<CustomerSubuserEmailInvite[] | null> => {
        //Fetch server response
        const response = await AccountsApi.get(`/rest/v1/customer-subusers/email-invites?page=${page}&limit=${limit}`);

        //Return null if pagination response is empty
        if (response.status === 204) {
            return null;
        }

        //Otherwise fetch the data
        return response.data;
    },

    /**
     * Return pagination of customer subusers of the organization of the authenticated user
     * @param limit 
     * @returns 
     */
    paginationFromOrganization : async (limit : number = 10) : Promise<Pagination> => {
        return await (await AccountsApi.get(`/rest/v1/customer-subusers/email-invites/pagination?limit=${limit}`)).data;
    },

    /**
     * Send an new customer subuser email invite
     * @param dto Data with the invite to add
     * @returns 
     */
    sendInvite : async (dto :SendInvite) : Promise<CustomerSubuserEmailInvite> => {
        return (await AccountsApi.post(`/rest/v1/customer-subusers/email-invites`, dto)).data;
    },

    /**
     * Delete an invite
     * @param id The invite id
     * @returns 
     */
    deleteInvite : async (id : string) : Promise<CustomerSubuserEmailInvite> => {
        return await (await AccountsApi.delete(`/rest/v1/customer-subusers/email-invites/${id}`)).data;
    },

    /**
     * Recreate the email invite using an existing one as reference
     * @param dto 
     * @returns 
     */
    resendInvite : async (dto : CustomerSubuserEmailInvite) : Promise<CustomerSubuserEmailInvite> => {
        //Delete the invite form the database
        await CustomerSubuserEmailInviteService.deleteInvite(dto.id);

        //Create the new invite
        return await CustomerSubuserEmailInviteService.sendInvite({
            email : dto.email,
            fullname : dto.fullname,
            preferredName : dto.preferredName
        });
    }

}

export default CustomerSubuserEmailInviteService;