const CharTypesFactors = {
    'a': 0.1,
    'A': 0.1,
    '9': 0.1,
    '!': 0.25

}

function psf_charstr(pwd: string) {
    let factor = 0

    let lastCharType = ""

    for (let i = 0; i < pwd.length; i++) {
        let c = pwd[i]
        let charType = ""

        if (/[a-z]/.test(c)) charType = 'a'
        if (/[A-Z]/.test(c)) charType = 'A'
        if (/[0-9]/.test(c)) charType = '9'
        if (/\W/.test(c)) charType = '!'

        let charTypeFactor = 0

        switch (charType) {
            case 'a':
                charTypeFactor = CharTypesFactors.a
                break;
            case 'A':
                charTypeFactor = CharTypesFactors.A
                break;
            case '9':
                charTypeFactor = CharTypesFactors[9]
                break;
            case '!':
                charTypeFactor = CharTypesFactors["!"]
                break;
        }

        factor += (charType !== lastCharType) ? charTypeFactor : charTypeFactor / 2
        lastCharType = charType

    }

    return factor
}

function psf_seqrep(pwd: string) {

    if (pwd.length < 2) return 0

    const normalizedPwd = pwd.toLowerCase()
    
    let factor = 0

    let ref = normalizedPwd.charCodeAt(0)

    for (let i = 1; i < normalizedPwd.length; i++) {
        let c = normalizedPwd.charCodeAt(i)

        switch (ref - c) {
            case 0:
                factor -= 0.2
                break;
            case 1:
            case -1:
                factor -= 0.1
                break;
            default:
                factor += 0.1
                break;
        }

        ref = c
    }

    return factor
}

export function calculatePasswordStrength(pwd: string) {
    if (pwd.length < 6) return 0
    
    let factor = 0

    factor += psf_charstr(pwd)
    factor += psf_seqrep(pwd)

    return factor
}

//Function to verify if in the password has special characters
export const handleHasSpecialCharacter = (str: string): boolean => {
    const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    return specialCharacters.test(str);
};

//Function to verify if in the password has lower and Upper case
export const handleHasUpperCaseAndLowerCase = (str: string): boolean => {
    const hasUpperCase = /[A-Z]/.test(str);
    const hasLowerCase = /[a-z]/.test(str);
    return hasUpperCase && hasLowerCase;
};

//Function to verify if in the password has sequences
export function handlePasswordSequences(pwd: string): boolean {
    if (pwd.length < 6) {
        return false;
    }

    const normalizedPwd = pwd.toLowerCase();
    let ref = normalizedPwd.charCodeAt(0);
    let count = 1;

    /**
     * At each iteration, the Unicode code of the current character is obtained and stored in the c variable. 
     * It is checked whether the difference between the Unicode code of the current character (c) and the reference character (ref) is 0, 1 or -1. 
     * This indicates that the characters are in sequence. 
     * If the difference is 0, 1 or -1, it means there is a sequence of consecutive characters. The count counter is incremented. 
     * If the counter reaches or exceeds 3, it means that three characters are in sequence, and the function immediately returns false, 
     * indicating that the password has sequential repetitions.
     */
    for (let i = 1; i < normalizedPwd.length; i++) {
        const c = normalizedPwd.charCodeAt(i);

        if (ref - c === 0 || ref - c === 1 || ref - c === -1) {
            count++;
            if (count >= 3) {
                return false;
            }
        } else {
            count = 1;
        }

        ref = c;
    }

    return true;
}