import { Box, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppNotification, LoadingButton } from "../../../components";
import AuthenticationService from "../../../services/accounts/authentication";
import {useAppDispatch} from '../../../redux/hooks';
import {show} from "../../../redux/features/app-global-notification/app-global-notification-slice"
import ErrorWrapper from "../../../utils/ErrorWrapper";

interface RenewPasswordDTO {
    email : string,
    password : string,
    newPassword : string
}

function RecoverMyPassword() : JSX.Element {
    //Redux props
    const dispatch = useAppDispatch();

    //Context prop to navigate between pages
    let navigate = useNavigate();

    //State that stores the data of the model that will be sent to the server
    const [renewPasswordModel, setRenewPasswordModel] = useState<RenewPasswordDTO>({
        email : "",
        password : "",
        newPassword : ""
    });

    const [canSubmitForm, setCanSubmitForm] = useState(false);

    //Effect to validate if the email can be sent
    useEffect(() => {
        setCanSubmitForm(
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(renewPasswordModel.email) &&
            renewPasswordModel.newPassword.length > 6 && 
            renewPasswordModel.password.length > 6 && 
            renewPasswordModel.password !== renewPasswordModel.newPassword
            );
    }, [renewPasswordModel]);

    //Store 'loading' state
    const [loading, setLoading] = useState(false);

    //Event changes
    const handleEmailChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        setRenewPasswordModel({
            ...renewPasswordModel,
            email : event.target.value
        })
    };
    const handlePasswordChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        setRenewPasswordModel({
            ...renewPasswordModel,
            password : event.target.value
        })
    };
    const handleNewPasswordChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        setRenewPasswordModel({
            ...renewPasswordModel,
            newPassword : event.target.value
        })
    };

    //Functions
    function renewPassword() {
        //Update loading state
        setLoading(true);

        AuthenticationService.renewPassword(renewPasswordModel)
        .then(() => {
            dispatch(show({
                message : 'Senha renovada com sucesso',
                type : 'success'
            }));

            //Go to login automatically after password renew
            setTimeout(() => {
                navigate("/login", {
                    replace : true
                })
            }, 2000)
        })
        .catch((e) => {
            dispatch(show({
                message : new ErrorWrapper(e).message,
                type : 'error'
            }));
        })
        .finally(() => {
            setLoading(false);
        })
    }

    return (
        <Box
            sx={{
                margin : 'auto',
                maxWidth : '480px',
                padding : 2
            }}
        >
            <Box
                component='form'
                sx={{
                    alignItems : 'center',
                }}
            >
                <div>
                    <div className="ta-c">
                        <h2>Alteração de senha necessária</h2>
                        <Typography variant='subtitle1'>
                            Foi verificado que é necessária a renovação da senha da conta informada.
                        </Typography>
                        <Typography variant='subtitle2'>
                            Insira abaixo seu email e senha atuais e logo após a nova senha que deseja aplicar na sua conta
                        </Typography>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField 
                                value={renewPasswordModel.email}
                                label='Email'
                                type='email'
                                onChange={handleEmailChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                value={renewPasswordModel.password}
                                label='Sua senha atual'
                                type='password'
                                onChange={handlePasswordChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                value={renewPasswordModel.newPassword}
                                label='Sua nova senha (deve ser diferente da atual)'
                                type='password'
                                onChange={handleNewPasswordChange}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <LoadingButton loading={loading} disabled={!canSubmitForm} variant='contained' color='success'
                        sx={{
                            margin : 'auto',
                            display : 'block',
                            maxWidth : '480px',
                            marginTop : 2
                        }}
                        fullWidth
                        onClick={() => renewPassword()}
                    >
                        Renovar senha
                    </LoadingButton>
                </div>
            </Box>
            <AppNotification />
        </Box>        
    );
};

export default RecoverMyPassword;