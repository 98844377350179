import { UserAccount } from "../../models/user";
import AccountsAPI from "./accountsApi";

interface UpdateUserAccountDTO {
    preferredName : string,
    fullname : string
}

const UserAccountService = {

    /**
     * Update the requester user account data
     * @param dto 
     * @returns 
     */
    update : async (dto : UpdateUserAccountDTO) : Promise<UserAccount> => {
        return await (await AccountsAPI.put(`/rest/v1/user-accounts/me`, dto)).data;
    }

}

export default UserAccountService;

