import { Button, Grid, LinearProgress, Step, StepLabel, Stepper, TextField, Typography } from "@material-ui/core";
import { Box } from "@material-ui/system"
import { Check, Error as ErrorIcon } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import jwt from 'jsonwebtoken';
import { AppNotification, LoadingButton } from "../../components";
import AuthenticationService from "../../services/accounts/authentication";
import { useAppDispatch } from "../../redux/hooks";
import {show} from "../../redux/features/app-global-notification/app-global-notification-slice"
import ErrorWrapper from "../../utils/ErrorWrapper";
import { useNavigate } from "react-router-dom";

interface UpdatePasswordWithEmailTokenDTO {
    newPassword : string,
    token : string | null
}

/**
 * Enum used to store token validation status
 */
 enum TokenValidationStatus {
    Processing,
    Failed,
    Validated
}

const ResetPasswordByEmail = () : JSX.Element => {
    //Redux props
    const dispatch = useAppDispatch();

    //Context prop used to navigate to another page
    const navigate = useNavigate();

    // Content used to get query parameter data
    const [searchParams] = useSearchParams();

    //Store the state of the DTO used to reset the password
    const [resetPasswordDTO, setResetPasswordDTO] = useState<UpdatePasswordWithEmailTokenDTO>({
        newPassword : "",
        token : null
    });

    //Store the state of the current step
    const [step, setStep] = useState(0);

    //Store the state of the password confirmation field
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    //Store loading state
    const [loading, setLoading] = useState(false);

    //Store a flag state that indicates if the password can be changed
    const [canResetPassword, setCanResetPassword] = useState(false);

    //Effect that validate if the user can reset password
    useEffect(() => {

        //Set the flag that verified if can change the password
        setCanResetPassword(
            resetPasswordDTO.newPassword.length > 6 &&
            resetPasswordDTO.newPassword === passwordConfirmation
        );

    }, [resetPasswordDTO, passwordConfirmation])

    //Stateless props
    const steps = [
        'Validando token de verificação',
        'Escolha uma nova senha',
    ];

    //Inner components
    function TokenValidationProgress() : JSX.Element | null{
        //Store the state of the token verification step
        const [tokenVerificationStatus, setTokenVerificationStatus] = useState<TokenValidationStatus>(TokenValidationStatus.Processing);
        const [token, setToken] = useState<string | null>(null);
        const [progress, setProgress] = useState(0);

        //Effect used to validate the token when the component is first loaded
        useEffect(() => {

            //As this component change an parent state prop is necessary that the verification is inside an if statement to prevent infinity re-render
            //as, when the parent component has its state changed it will automatically update their children
            if (resetPasswordDTO.token === null) {
                try {
                    
                    //Get the token from query parameter
                    const tokenFromQuery = searchParams.get("token") || null;
                    if (tokenFromQuery === null) {
                        console.error("Token not found");
                        throw new Error("Token not found");
                    }

                    //Get the decoded JWT
                    const decodedJwt = jwt.decode(tokenFromQuery) as any;
                    if (decodedJwt === null) {
                        console.error("Invalid token");
                        throw new Error("Invalid token");
                    }

                    //Get the token expiration date to validate if it is expired
                    const tokenExpirationTimestamp = decodedJwt.exp * 1000;
                    const now = new Date().getTime();

                    //Verify is the token expired
                    if (tokenExpirationTimestamp < now) {
                        console.error("The verification token expired");
                        throw new Error("The verification token expired");
                    }

                    //If any error is detected set the progress to check and set a timeout to change to step 1 (change password)
                    setTokenVerificationStatus(TokenValidationStatus.Validated);
                    setToken(tokenFromQuery);
                } catch (error) {
                    setTokenVerificationStatus(TokenValidationStatus.Failed);
                }
            }
            else{
                setStep(1);
            }
        }, []);

        //Effect used to automatically skip to reset password step when the token is set
        const Timeout = 3000;
        useEffect(() => {
            if (token !== null) {
                setTimeout(() => {
                    goToResetPassword();
                }, Timeout);

                //Progress bar 
                const interval = setInterval(() => {
                    setProgress((oldProgress) => {
                        //Keep always on 100
                        if (oldProgress >= 80) {
                            clearInterval(interval);
                            return 100;
                        }

                        return oldProgress + 10;
                    });
                }, Timeout / 10);
            }
            // eslint-disable-next-line
        }, [token]);

        //Functions
        function goToResetPassword() {
            //Ignore if token is null
            if (token === null) {
                return;
            }

            //Set the value for parent property
            setResetPasswordDTO({
                ...resetPasswordDTO,
                token : token
            });

            //Go to the next page
            setStep(1);
        }

        //Switch between token verification status components
        switch (tokenVerificationStatus) {
            case TokenValidationStatus.Processing:
                return (
                    <Box sx={{ justifyContent : 'center', mt : 4 }}>
                        <LinearProgress color="info" sx={{display : 'block', 'margin' : 'auto'}}/>
                        <p className="ta-c">Validando token de verificação</p>
                    </Box>
                );
            case TokenValidationStatus.Validated:
                return (
                    <Box sx={{ justifyContent : 'center', mt : 4 }} className='ta-c'>
                        <Check color="success" fontSize="large" sx={{display : 'block', 'margin' : 'auto'}}/>
                        <p className="ta-c">Token validado com sucesso</p>
                        <Typography variant="subtitle1" >Agora você pode colocar sua nova senha</Typography>
                        <LinearProgress variant="determinate" value={progress} />
                        <hr/>
                        <Button size="medium" onClick={() => goToResetPassword()}>Definir minha nova senha agora</Button>
                    </Box>
                );
                case TokenValidationStatus.Failed:
                    return (
                        <Box sx={{ justifyContent : 'center', mt : 4 }}>
                            <ErrorIcon color="error" fontSize="large" sx={{display : 'block', 'margin' : 'auto'}}/>
                            <p className="ta-c">Token de verificação inválido</p>
                            <hr/ >
                            <Link to="/recover-my-password">
                                <Button size="medium" sx={{margin : 'auto', display : 'block'}}>Iniciar recuperação de senha novamente</Button>
                            </Link>
                        </Box>
                    );
            default : 
                    return null;
        }
    }

    //Event handlers
    const handleNewPasswordChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        setResetPasswordDTO({
            ...resetPasswordDTO,
            newPassword : event.target.value
        })
    };
    const handlePasswordConfirmationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirmation(event.target.value);
    }

    //Function
    function resetPassword() {
        //Ignore if the token is not set
        if (resetPasswordDTO.token === null) {
            console.error("Token not set on transfer model", resetPasswordDTO);
            return;
        }

        //Call the API
        setLoading(true);
        AuthenticationService.resetPasswordByEmailToken(resetPasswordDTO.newPassword, resetPasswordDTO.token)
        .then(() => {
            //Show success message
            dispatch(show({
                message : "Senha alterada com sucesso",
                type : 'success'
            }))

            //Navigate
            setTimeout(() => {
                navigate("/login", {
                    replace : true
                })
            }, 2000);
            
        })
        .catch((e) => {
            dispatch(show({
                message : new ErrorWrapper(e).message,
                type : 'error'
            }))
        })
        .finally(() => {
            setLoading(false);
        })
    }

    /**
     * Main component
     */
    return (
        <Box
            sx={{
                margin : 'auto',
                maxWidth : '480px',
                padding : 2
            }}
        >
            <Box
                sx={{
                    alignItems : 'center',
                    display : 'flex',
                    height : '100vh'
                }}
            >
                <div>
                    {/*Steppers */}
                    <Box sx={{width : '100%', mb : 8}}>
                        <Stepper activeStep={step} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>

                    {/*Step validations*/}
                    {
                        /*Step 0 = Token validation progress */
                        (step === 0) 
                        ?
                        <TokenValidationProgress />
                        :
                        /*Step 1 = Reset password form*/
                        (step === 1)
                        ?
                        <div>
                            <h3 className='ta-c'>Coloque abaixo sua nova senha</h3>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        placeholder="Insira sua nova senha"
                                        required={true}
                                        variant='outlined'
                                        onChange={handleNewPasswordChange}
                                        value={resetPasswordDTO.newPassword}
                                        type='password'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        placeholder="Confirme sua nova senha"
                                        required={true}
                                        variant='outlined'
                                        onChange={handlePasswordConfirmationChange}
                                        value={passwordConfirmation}
                                        type='password'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton fullWidth color='success' variant='contained' disabled={!canResetPassword} loading={loading} onClick={() => resetPassword()}>
                                        Redefinir senha
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </div>
                        :
                        null
                    }
                    <hr/ >
                    <p className='ta-c' style={{textTransform : 'uppercase'}}><b>Ou</b></p>
                    <Link to="/login">
                        <Button size="small" color='error' sx={{margin : 'auto', display : 'block'}}>Voltar para login</Button>
                    </Link>
                </div>
            </Box>
            {/* App notification component */}
            <AppNotification />
        </Box>
    );
};

export default ResetPasswordByEmail;