import React, { useEffect, useState } from 'react';
import './Background.scss';
import { Typography } from '@material-ui/core';

const createElementsArray = (className: string) => {
  return Array.from({ length: 40 }).map((_, i) => (
    <a className={className} key={i} style={{ zIndex: -1 }}></a>
  ));
};

const Background = (): JSX.Element => {
  const [currentProduct, setCurrentProduct] = useState('accounts');
  const products = ['accounts', 'contracts', 'kloud', 'audire', 'kauditor'];

  const handleProductChange = () => {
    const nextProductIndex = (products.indexOf(currentProduct) + 1) % products.length;
    setCurrentProduct(products[nextProductIndex]);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleProductChange();
    }, 10000);

    return () => clearInterval(interval);
  }, [currentProduct]);

  return (
    <div className={`background ${currentProduct}`}>
      <div className="text-content">
        {products.map((product) => (
          <div
            key={product}
            className={`product-container ${currentProduct === product && 'product-visible'}`}
          >
            {currentProduct === product && (
              <>
                <div className="product-text">
                  {product === 'accounts' && (
                    <Typography variant="h4">
                      Uma conta, várias <span>experiências</span>.
                    </Typography>
                  )}
                  {product === 'contracts' && (
                    <>
                      <img
                        src="images/cartorizi_white_logo.svg"
                        alt=""
                        style={{ maxWidth: "200px", width: "100%", marginBottom: "12px" }}
                      />
                      <Typography variant="h4">
                        A solução <span>inteligente</span> para assinatura de contratos.
                      </Typography>
                    </>
                  )}
                  {product === 'kloud' && (
                    <>
                      <img
                        src="images/kloud_white_logo.svg"
                        alt=""
                        style={{ maxWidth: "200px", width: "100%", marginBottom: "12px" }}
                      />
                      <Typography variant="h4">
                        <span>Automatize</span> e <span>otimize</span> sua gestão fiscal.
                      </Typography>
                    </>
                  )}
                  {product === 'audire' && (
                    <>
                      <img
                        src="images/audire_white_logo.svg"
                        alt=""
                        style={{ maxWidth: "200px", width: "100%", marginBottom: "12px" }}
                      />
                      <Typography variant="h4">
                        Monitoramento de <span>pendências</span> fiscais.
                      </Typography>
                    </>
                  )}
                  {product === 'kauditor' && (
                    <>
                      <img
                        src="images/kauditor_white_logo.svg"
                        alt=""
                        style={{ maxWidth: "300px", width: "100%", marginBottom: "12px" }}
                      />
                      <Typography variant="h4">
                        O <span>melhor</span> software de auditoria do mercado.
                      </Typography>
                    </>
                  )}
                </div>
                {createElementsArray(product)}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Background;
