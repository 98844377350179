import { CheckCircle, Cancel, Help, Warning } from "@mui/icons-material"
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Tooltip, Theme } from "@mui/material"
import { useEffect, useState } from "react"
import { calculatePasswordStrength, handleHasSpecialCharacter, handleHasUpperCaseAndLowerCase, handlePasswordSequences } from "../utils/passwordUtil";

interface PasswordStrenghtProps {
    password: string,
    passwordStrenghtCallback: (strenght: number) => void
}

// Tooltip designed to inform the user that it is not mandatory, but will help to increase password strength
const WarningTooltip: React.FC = () => (
    <Tooltip title="As sugestões não são obrigatórias, mas irão te ajudar a aumentar a força da sua senha." >
        <Warning color="warning" sx={{ fontSize: "0.75rem" }} />
    </Tooltip>
);

const PasswordStrenght = (props: PasswordStrenghtProps): JSX.Element =>{

    const { password, passwordStrenghtCallback } = props

    const [passwordStrenght, setPasswordStrenght] = useState(0)
    const [normalizedPasswordStrenght, setNormalizedPasswordStrenght] = useState(0)

    //State of Password Suggestions 
    const [hasSpecialCharacters, setHasSpecialCharacters] = useState(false)
    const [hasLowerAndUpperCases, setHasLowerAndUpperCases] = useState(false)
    const [doNotHasSequences, setDoNotHasSequences] = useState(false)

    useEffect(() => {
        const strenght = calculatePasswordStrength(password)
        setPasswordStrenght(strenght)
        passwordStrenghtCallback(strenght)
    }, [password])

    useEffect(() => {
        if (passwordStrenght === 0) setNormalizedPasswordStrenght(0)
        else if (passwordStrenght < 0.5) setNormalizedPasswordStrenght(5)
        else if (passwordStrenght < 1) setNormalizedPasswordStrenght(25)
        else if (passwordStrenght < 1.5) setNormalizedPasswordStrenght(50)
        else if (passwordStrenght < 1.99) setNormalizedPasswordStrenght(75)
        else if (passwordStrenght >= 2) setNormalizedPasswordStrenght(100)

    }, [passwordStrenght])

    useEffect(() => {
        setHasSpecialCharacters(handleHasSpecialCharacter(password));
        setHasLowerAndUpperCases(handleHasUpperCaseAndLowerCase(password));
        setDoNotHasSequences(handlePasswordSequences(password))
    })

    function passwordStrenghtProgressBarColor() {
        const passwordStrenghtObject = {
            color: "",
            nivel: "",
            emoji: ""
        }
        if (normalizedPasswordStrenght === 0) {
            passwordStrenghtObject.color = ""
            passwordStrenghtObject.emoji = ""
            passwordStrenghtObject.nivel = ""
        }
        else if (normalizedPasswordStrenght < 25) {
            passwordStrenghtObject.color = "#ff1744"
            passwordStrenghtObject.emoji = "💩"
            passwordStrenghtObject.nivel = "Não seguro"
        } else if (normalizedPasswordStrenght < 50) {
            passwordStrenghtObject.color = "#ff8f00"
            passwordStrenghtObject.emoji = "😔"
            passwordStrenghtObject.nivel = "Não seguro"
        } else if (normalizedPasswordStrenght < 75) {
            passwordStrenghtObject.color = "#fbc02d"
            passwordStrenghtObject.emoji = "😐"
            passwordStrenghtObject.nivel = "Não seguro"
        } else if (normalizedPasswordStrenght < 100) {
            passwordStrenghtObject.color = "#43a047"
            passwordStrenghtObject.emoji = "🙂"
            passwordStrenghtObject.nivel = "Aceitável"
        } else {
            passwordStrenghtObject.color = "#00c853"
            if (passwordStrenght < 2.5) {
                passwordStrenghtObject.emoji = "😀"
                passwordStrenghtObject.nivel = "Seguro"
            } else {
                passwordStrenghtObject.emoji = "😮"
                passwordStrenghtObject.nivel = "Bem seguro"
            }
        }

        return passwordStrenghtObject
    }

    return (
        <Box sx={{textAlign: "start"}}>
            <Typography mt={1} sx={{ fontSize: "0.75rem" }}>Força da senha</Typography>
                    {/* Password Strenght Progress Bar */}
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "36px" }}>
                        <Box sx={{ width: "100%", height: "5px", backgroundColor: "#CCCCCC", borderRadius: "5px" }}>
                            <Box 
                                sx={{
                                    width: `${normalizedPasswordStrenght}%`,
                                    height: "5px",
                                    backgroundColor: passwordStrenghtProgressBarColor().color,
                                    borderRadius: "5px"
                                }} 
                            ></Box>
                        </Box>
                        <Typography sx={{ fontSize: "1.5rem" }}>{passwordStrenghtProgressBarColor().emoji}</Typography>
                    </Box>
                    <Typography variant="body2">
                        {passwordStrenghtProgressBarColor().nivel !== "" ? `Nível: ${passwordStrenghtProgressBarColor().nivel}` : ""}
                    </Typography>
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        aria-label="contacts"
                    >
                        <ListItem disablePadding>
                            <ListItemIcon sx={{ minWidth: "15px", width: "15px" }}>
                                {(password.length >= 6) ?
                                    <CheckCircle color="success" sx={{ fontSize: "0.75rem" }} />
                                    :
                                    <Cancel color="error" sx={{ fontSize: "0.75rem" }} />
                                }
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2" sx={{ fontSize: "0.75rem" }} color={(password.length >= 6) ? "green" : "error"}>Sua senha deve ter no mínimo 6 caracteres.</Typography>} />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon sx={{ minWidth: "15px", width: "15px" }}>
                                {(normalizedPasswordStrenght >= 75) ?
                                    <CheckCircle color="success" sx={{ fontSize: "0.75rem" }} />
                                    :
                                    <Cancel color="error" sx={{ fontSize: "0.75rem" }} />
                                }
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2" sx={{ fontSize: "0.75rem" }} color={(normalizedPasswordStrenght >= 75) ? "green" : "error"}>Sua senha deve estar no mínimo no nível aceitável.</Typography>} />
                        </ListItem>

                        <Tooltip title="As sugestões não são obrigatórias, mas irão te ajudar a aumentar a força da sua senha." >
                            <Box sx={{ display: "flex", alignItems: "center", pt: 2 }}>
                                <Typography sx={{ fontSize: "0.75rem", mr: 1 }}>Sugestões de senha</Typography>
                                <Help sx={{ fontSize: "1rem", color: "#777" }} />
                            </Box>
                        </Tooltip>
                        <ListItem disablePadding>
                            <ListItemIcon sx={{ minWidth: "15px", width: "15px" }}>
                                {(hasSpecialCharacters) ?
                                    <CheckCircle color="success" sx={{ fontSize: "0.75rem" }} />
                                    :
                                    <WarningTooltip />
                                }
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2" sx={{ fontSize: "0.75rem" }} color={(hasSpecialCharacters) ? "green" : "#aaa"}>Sua senha deve ter pelo menos um caracter especial (ex.: ! @ $ % #)</Typography>} />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon sx={{ minWidth: "15px", width: "15px" }}>
                                {(hasLowerAndUpperCases) ?
                                    <CheckCircle color="success" sx={{ fontSize: "0.75rem" }} />
                                    :
                                    <WarningTooltip />
                                }
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2" sx={{ fontSize: "0.75rem" }} color={(hasLowerAndUpperCases) ? "green" : "#aaa"}>Sua senha deve ter pelo menos uma letra maiúscula e uma minúscula</Typography>} />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon sx={{ minWidth: "15px", width: "15px" }}>
                                {(doNotHasSequences) ?
                                    <CheckCircle color="success" sx={{ fontSize: "0.75rem" }} />
                                    :
                                    <WarningTooltip />
                                }
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2" sx={{ fontSize: "0.75rem" }} color={(doNotHasSequences) ? "green" : "#aaa"}>Não utilize sequências ou caracteres iguais (ex.: 1234, 1111, 321, aaaaa, abcd)</Typography>} />
                        </ListItem>
                    </List>
        </Box>
    )
}

export default PasswordStrenght;

function setHasLowerAndUpperCases(arg0: any) {
    throw new Error("Function not implemented.");
}
