import AccountsAPI from "./accountsApi";

const UserService = {
    /**
     * Upload an avatar image
     * @param avatarBlob
     */
    async uploadAvatar(avatarBlob : Blob) : Promise<void> {
        //Build the form data body
        const bodyFormData = new FormData();
        bodyFormData.append("avatar", avatarBlob);

        //Call the API to upload the avatar
        return await AccountsAPI.post(`/rest/v1/users/me/avatar`, bodyFormData, {
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 30000
        });
    },

    /**
     * Remove the current avatar image from the user profile
     * @returns 
     */
    async removeAvatar() : Promise<void> {
        //Call the API to upload the avatar
        return await AccountsAPI.delete(`/rest/v1/users/me/avatar`);
    }

}

export default UserService;