import { Container, Grid, TextField } from '@material-ui/core'
import { Box } from '@material-ui/system';
import { useEffect, useState, useContext } from 'react';
import { AppNotification, LoadingButton } from "../../components";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import CustomerService from '../../services/accounts/customer';
import ErrorWrapper from '../../utils/ErrorWrapper'
import AuthenticationContext from '../../contexts/authentication';
import { Params, RouteParamContext, ServicesConsumer } from '../../contexts/route-param';

interface CreateUserDTO {
    email: string,
    password: string,
    preferredRegion: 'BRA'
}

interface CreateAccountDTO {
    paymentRegion: 'BRA',
    preferredName: string,
    fullname: string,
}

const CreateAccount = (): JSX.Element => {
    //Redux props
    const dispatch = useAppDispatch();
    const routeParam = useContext(RouteParamContext)

    //Context props 
    // const useAuthentication = () => { return useContext(AuthenticationContext) }
    const authentication = useContext(AuthenticationContext);
    const navigate = useNavigate();

    //State prop to store account data
    const [createAccountModel, setCreateAccountModel] = useState<CreateAccountDTO>({
        paymentRegion: 'BRA',
        preferredName: '',
        fullname: ''
    });

    //State prop to store user data
    const [createUserModel, setCreateUserModel] = useState<CreateUserDTO>({
        email: '',
        password: '',
        preferredRegion: 'BRA'
    })

    //State prop to store loading state
    const [loading, setLoading] = useState(false);

    //State prop that store flag that indicates if the form can be submitted
    const [canCreateAccount, setCanCreateAccount] = useState(false);

    //Effect that validates form data
    useEffect(() => {

        //Update the flag validating each input field
        setCanCreateAccount(
            createAccountModel.fullname.length > 0 &&
            createAccountModel.preferredName.length > 0 &&
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(createUserModel.email) &&
            createUserModel.password.length > 6
        )

    }, [createAccountModel, createUserModel])

    //Handle fullname change
    const handleFullnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //Update at the same time fullname and preferred name
        setCreateAccountModel({
            ...createAccountModel,
            fullname: event.target.value,
            preferredName: event.target.value
        });
    }

    //Handle preferred name change
    const handlePreferredNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //Update at the same time fullname and preferred name
        setCreateAccountModel({
            ...createAccountModel,
            preferredName: event.target.value
        });
    }

    //Handle email change
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //Update at the same time fullname and preferred name
        setCreateUserModel({
            ...createUserModel,
            email: event.target.value
        })
    }

    //Handle password change
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //Update at the same time fullname and preferred name
        setCreateUserModel({
            ...createUserModel,
            password: event.target.value
        })
    }

    /**
     * Create the account
     */

    const createAccount = async () => {
        setLoading(true);

        try {
            //Create the account
            await CustomerService.createAccount({
                paymentRegion: createAccountModel.paymentRegion,
                fullname: createAccountModel.fullname,
                preferredName: createAccountModel.preferredName,
                user: {
                    email: createUserModel.email,
                    password: createUserModel.password,
                    preferredRegion: createUserModel.preferredRegion
                }
            });

            //Do the login
            await authentication.authenticateWithEmailAndPassword(createUserModel.email, createUserModel.password, true);
            redirect();
        }
        catch (e) {
            dispatch(show({
                message: new ErrorWrapper(e).message,
                type: 'error'
            }))
        }
        finally {
            setLoading(false);
        }
    }

    /**
     * Check if the URL has the service param to redirect to the contracts app, or go to home
     */
    const redirect = () => {
        //Get the url param
        const hasQueryParam = routeParam.hasParam(Params.SERVICE, ServicesConsumer.CONTRACTS);

        //Navigate to home
        if (!hasQueryParam) return navigate("/", { replace: true })

        //If URL has the service param navigate to contracts app and send the auth toke on url
        const token = authentication.getToken()
        const accountType = authentication.user().session.accountType;
        window.location.href = `${process.env.REACT_APP_CONTRACTS_WEBAPP}/auth/service-response?accountType=${accountType}&token=${token}`
    }

    return (
        <Container>
            <h2 className='ta-c'>Cadastro de conta KC2W</h2>
            <Box className='form-container'>
                <Box className='form-container'>
                    <h4>Como podemos te chamar?</h4>
                    <hr />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {/*Full name field*/}
                            <TextField
                                value={createAccountModel.fullname}
                                label='Nome completo'
                                onChange={handleFullnameChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/*Preferred name field*/}
                            <TextField
                                value={createAccountModel.preferredName}
                                label='Nome de apresentação'
                                onChange={handlePreferredNameChange}
                                fullWidth
                            />
                        </Grid>

                    </Grid>
                </Box>
                <Box className='form-container'>
                    <h4>Dados de acesso da sua conta</h4>
                    <hr />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {/*Email*/}
                            <TextField
                                value={createUserModel.email}
                                label='Email'
                                onChange={handleEmailChange}
                                type='email'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/*Password*/}
                            <TextField
                                value={createUserModel.password}
                                label='Senha'
                                onChange={handlePasswordChange}
                                type='password'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Box>
                <LoadingButton loading={loading} disabled={!canCreateAccount} variant='contained' color='success'
                    sx={{
                        margin: 'auto',
                        display: 'block',
                        maxWidth: '380px'
                    }}
                    fullWidth
                    onClick={() => createAccount()}
                >
                    Criar conta
                </LoadingButton>
            </Box>
            <AppNotification />
        </Container>
    )
}

export default CreateAccount;