import { Alert, Grid } from "@material-ui/core";
import { Box } from "@material-ui/system";
import { AccountCircle } from "@mui/icons-material";
import { Container } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { CurrentContext } from "../../appctx/webappContext";
import { AppNotification } from "../../components";
import AuthenticationContext from "../../contexts/authentication";
import CacheControl from "../../contexts/cache-control";
import { AccountTypes, UserAccount } from "../../models/user";
import { useAppDispatch } from '../../redux/hooks';
import AuthenticationService from "../../services/accounts/authentication";
import KlausProducts from "../../components/KlausProducts";

export default function Home() {
    //Redux props
    const dispatch = useAppDispatch();

    //State props
    const [accountOwner, setAccountOwner] = useState<UserAccount>(null!);

    //Context props
    let authUser = useAuthentication();
    function useAuthentication() {
        return useContext(AuthenticationContext);
    }

    //On load use effect
    useEffect(() => {
        //Load account owner if the user is customer subuser
        if (authUser.is(AccountTypes.CustomerSubuser)) {
            AuthenticationService.myAccountOwner()
                .then(response => {
                    //Set the account owner on prop state
                    setAccountOwner(response);
                });
        }
    }, [authUser]);

    //Inner components
    function GetSubuserOnlyDescription(): JSX.Element | null {
        //If the account owner is not set
        if (!accountOwner) {
            return null;
        }

        return (
            <Alert variant="outlined" color='warning' severity='warning'>
                Sua conta pertence a organização <b>{accountOwner.fullname}</b>. Quando uma conta pertence a uma organização algumas ações de conta serão limitadas.
                Leia mais <a target="_blank" href="https://google.com" rel="noreferrer">neste link</a>.
            </Alert>
        );
    }

    function GetEmaiNotConfirmedDescription(): JSX.Element | null {
        //Ignore if user email is already confirmamted
        if (authUser.user().user.emailConfirmationDate) return null;
        return (
            <Alert variant="outlined" color='warning' severity='warning'>
                <strong>Email não confirmado.</strong> Vá em segurança da minha conta para reenviar o email de confirmação.
            </Alert>
        );
    }

    return (
        <Container>
            <AppNotification />
            <Box
                sx={{
                    mt: 8,
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                {
                    (authUser.isAuthenticated() && authUser.user().user.hasAvatar) ?
                        (

                            <img src={CurrentContext.accountsCdn.server + '/' + authUser.user().user.uuid + `?cache-tmstp=${CacheControl.avatarCdn()}`} alt='User profile avatar'
                                style={{ height: 96, width: 96, objectFit: 'contain', borderRadius: '50%', padding: 4 }} />
                        )
                        :
                        (
                            <AccountCircle sx={{ height: 128, width: 128 }} />
                        )
                }
            </Box>
            <h1 className="ta-c">Bem vindo, {authUser.user().account.fullname}</h1>
            <hr />
            <GetSubuserOnlyDescription />
            <GetEmaiNotConfirmedDescription />
            <Box key="products">
                <Grid container spacing={2}>
                    <Grid item sm={4} xs={12}>
                        <KlausProducts
                            logo="../images/cartorizi_white_logo.svg"
                            color="#64067f"
                            name="Cartorizi"
                            minimalistLogo="../images/pilar_icon.svg"
                            link={CurrentContext.cartoriziAuthenticationURL}
                            whiteMinimalistIcon="../images/minimalist_logo.svg" 
                            description="Plataforma de assinatura de contratos, com gestão financeira e analítica. Envie documentos com poucos cliques, acompanhe etapas e tenha segurança e validade jurídica."
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}