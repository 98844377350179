/**
 * Store the status of the domain challenge
 */
export enum CustomerInternetDomainChallengeStatus {
    Pending = "PENDING",
    Finished = "FINISHED"
}

export enum InternetDomainChallengeTypes {
    Txt = "TXT",
    WellKnown = "WELL_KNOWN"
}

export interface CustomerInternetDomain {
    /**
     * The challenge associated with the creation of this domain
     */
    challengeId : string;

    /**
     * The domain name
     */
    name : string;

    /**
     * Timestamp when the internet domain was verified
     */
    creationDate : number;
}

/**
 * The challenge used in the internet domain challenge
 */
export interface InternetDomainChallenge {
    type : InternetDomainChallengeTypes
}

export interface CustomerInternetDomainChallenge {
    /**
     * The ID of the challenge
     */
    id : string;

    /**
     * The domain name
     */
    name : string;

    /**
     * The current status of the domain verification challenge
     */
    status : CustomerInternetDomainChallengeStatus;

    /**
     * The challenge used in the internet domain challenge.
     */
    challenge : InternetDomainChallenge;
}

/**
 * Parse the enumeration value of InternetDomainChallengeTypes to a String format accepted by the back-end
 * @param type 
 * @returns 
 */
export function parseInternetDomainChallengeTypesToString(type : InternetDomainChallengeTypes) : 'WELL_KNOWN' | 'TXT' | null {
    switch (type) {
        case InternetDomainChallengeTypes.Txt:
            return 'TXT';
        case InternetDomainChallengeTypes.WellKnown:
            return 'WELL_KNOWN';
        default : 
            return null;
    }
}