import { configureStore } from '@reduxjs/toolkit'
import AppGlobalNotificationReducer from './features/app-global-notification/app-global-notification-slice';

const store = configureStore({
  reducer: {
    appGlobalNotification : AppGlobalNotificationReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
