import { LinearProgress, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, TextField, Typography } from "@material-ui/core";
import { Box } from "@material-ui/system";
import { AccountCircle } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { CurrentContext } from "../../appctx/webappContext";
import { AppNotification, LoadingButton } from "../../components";
import ImageSelector from "../../components/ImageSelector";
import AuthenticationContext from "../../contexts/authentication";
import { AccountTypes } from "../../models/user";
import UserService from "../../services/accounts/user";
import UserAccountService from "../../services/accounts/user-account";
import ImageCompressor from "compressorjs";

//Redux
import {useAppDispatch} from '../../redux/hooks';
import {show} from "../../redux/features/app-global-notification/app-global-notification-slice"
import ErrorWrapper from "../../utils/ErrorWrapper";
import CacheControl, { CacheControlStoreNames } from "../../contexts/cache-control";

import { useSearchParams } from "react-router-dom";
import AuthenticationService from "../../services/accounts/authentication";
import  AuthenticationStore from "../../store/authentication";

interface UserAccountDTO {
    preferredName : string,
    fullname : string
}

const MyProfile = () : JSX.Element => {
    //Redux props
    const dispatch = useAppDispatch();

    //Context props
    function useAuthentication() {
        return useContext(AuthenticationContext);
    }
    let authUser = useAuthentication();

    //State props
    const [canUpdateBio, setCanUpdateBio] = useState(false);
    const [loading, setLoading] = useState(false);
    const [avatarSelectionDialog, setAvatarSelectionDialog] = useState(false);
    const [removeAvatarDialog, setRemoveAvatarDialog] = useState(false);
    const [canvasOfCroppedImage, setCanvasOfCroppedImage] = useState<HTMLCanvasElement | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const [token, setToken] = useState(searchParams.get('token'));

        // return session data related to the token used in the request.
        async function authorizeMe() {
            setLoading(true)
            let authMe = await AuthenticationService.fetchAuthenticatedUserData();
            setLoading(false)
        }
    
        async function validateToken(token: string) {
            await authUser.authenticateWithAuthToken(token);
            authorizeMe();
        }
    
        useEffect(() => {
            if (token) {
                validateToken(token)
                searchParams.delete("token");
                searchParams.delete("accountType");
                setSearchParams(searchParams);
            }
            else {
                const tokenFromStore = AuthenticationStore.getToken();
                if (tokenFromStore) {
                    setToken(tokenFromStore);
                }
                else {
                    window.location.replace(`${CurrentContext.authWebappURL}/login?service=accounts&requiredEmail=${authUser.user().user.email}&redirectUrl=/my-profile`)
                }
            }
        }, [token])
    

    //Store the state with the authenticate user data
    const [authUserData, setAuthUserData] = useState<UserAccountDTO>({
        preferredName : '',
        fullname : ''
    })

    //Events
    const handleCropChange = (canvas : HTMLCanvasElement) => {
        setCanvasOfCroppedImage(canvas);
    };

    //Effect that validade witch functions the current user can use
    useEffect(() => {
        if (authUser !== null && authUser.isAuthenticated())  {
            setAuthUserData({
                preferredName : authUser.user().account.preferredName,
                fullname : authUser.user().account.fullname
            })
        }
    }, [authUser]);

    //Effect that observer the authUserData prop
    useEffect(() => {
        if (authUser !== null && authUser.isAuthenticated())  {
            setCanUpdateBio(
                authUser.is(AccountTypes.Customer) && 
                authUserData.preferredName.trim().length > 1 &&
                authUserData.fullname.trim().length > 1);
        }
    }, [authUser, authUserData]);

    //Function
    async function uploadAvatar() {
        //If canvas of cropped image is not defined, return null
        if (canvasOfCroppedImage === null) {
            return;
        }

        //Call the API
        setLoading(true);

        //Parse the canvas data to blob
        canvasOfCroppedImage.toBlob((blob : Blob | null) => {
            //Ignore if the blob is null
            if (blob === null) {
                return;
            }

            new ImageCompressor(blob, {
                quality: 0.1,
            
                // The compression process is asynchronous,
                // which means you have to access the `result` in the `success` hook function.
                success(compressedBlob) {

                    console.log("Image length before upload", compressedBlob.size);
                    
                    UserService.uploadAvatar(compressedBlob)
                    .then(() => {
                        //Update the user data
                        authUser.updatedUser();

                        //Close this dialog
                        setAvatarSelectionDialog(false);

                        //Show success message
                        dispatch(show({
                            message : 'Foto de perfil alterada com sucesso',
                            type : 'success'
                        }))

                        //Refresh the cache value for the avatar CDN
                        CacheControl.refresh(CacheControlStoreNames.AvatarCdn);
                    })
                    .catch((e) => {
                        //Show error message
                        dispatch(show({
                            message : 'Ocorreu um erro ao trocar foto de perfil: ' + (new ErrorWrapper(e).message),
                            type : 'error'
                        }))
                    })
                    .finally(() => {
                        setLoading(false);
                    })

                },
                error(err) {
                  console.log(err.message);
                },
              });
        });
    }

    async function removeAvatar() {
        setLoading(true);
        UserService.removeAvatar()
            .then(() => {
                //Update the user data
                authUser.updatedUser();

                //Close this dialog
                setAvatarSelectionDialog(false);

                //Show success message
                dispatch(show({
                    message : 'Foto de perfil removida com sucesso',
                    type : 'success'
                }))

                //Close the remove avatar dialog
                setRemoveAvatarDialog(false);
            })
            .catch((e) => {
                //Show error message
                dispatch(show({
                    message : 'Ocorreu um erro ao remover foto de perfil: ' + (new ErrorWrapper(e).message),
                    type : 'error'
                }))
            })
            .finally(() => {
                setLoading(false);
            })
    }

    function updateUserAccountBio() {
        setLoading(true);
        UserAccountService.update({
            preferredName : authUserData.preferredName,
            fullname : authUserData.fullname
        })
        .then(() => {
            //Success message
            dispatch(show({
                message : 'Bio alterada com sucesso',
                type : 'success'
            }))

            //Update auth user data async
            authUser.updatedUser();
        })
        .catch(e => {
            //Error message
            dispatch(show({
                message : `Ocorreu um erro ao alterar a bio`,
                type : 'error'
            }))
        })
        .finally(() => {
            setLoading(false);
        })
    }

    //Events
    const handlePreferredNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        setAuthUserData({
            ...authUserData,
            preferredName : event.target.value
        });
    }
    const handleFullnameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        setAuthUserData({
            ...authUserData,
            fullname : event.target.value
        });
    }

    return (
        <Container>
            <div className="ta-c">
                <h2>Meu Perfil</h2>
                <p>Aqui você poderá alterar seus dados de identificação como nome e foto de perfil</p>
            </div>
            {/*Avatar */}
            <Box
                className="header-area"
            >
                <Grid container spacing={1}>
                    <Grid item md={2}>
                        {
                            (authUser.isAuthenticated() && authUser.user().user.hasAvatar) ?
                            (
                                
                                <img src={CurrentContext.accountsCdn.server + '/' + authUser.user().user.uuid + `?cache-tmstp=${CacheControl.avatarCdn()}`} alt='User profile avatar' 
                                style={{height : 96, width : 96, objectFit : 'contain', borderRadius : '50%', padding : 4}}/>
                            )
                            :
                            (
                                <AccountCircle sx={{height : 96, width : 96}} />
                            )
                        }
                    </Grid>
                    <Grid item md={10}>
                        <h3>Avatar</h3>
                        <p className="desc">Foto perfil global da sua conta</p>
                    </Grid>
                </Grid>
                <hr/>
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="outlined" 
                        color="success"
                        onClick={() => setAvatarSelectionDialog(true)}
                    >
                        Definir avatar
                    </Button>
                    <LoadingButton 
                        variant="outlined" 
                        color="error"
                        onClick={() => setRemoveAvatarDialog(true)}
                        loading={loading}
                    >
                        Remover avatar
                    </LoadingButton>
                </Stack>
            </Box>
            {/*Bio data*/}
            <Box
                className="header-area"
            >
                <h3>Bio</h3>
                <p className="desc">Aqui ficam os dados de apresentação da sua conta</p>
                <hr/>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField value={authUserData.fullname} label='Nome' variant='standard' fullWidth onChange={handleFullnameChange} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField value={authUserData.preferredName} label='Nome de apresentação' variant='standard' fullWidth onChange={handlePreferredNameChange} />
                    </Grid>
                </Grid>
                <hr/>
                <LoadingButton 
                        variant="outlined" 
                        color="success"
                        disabled={!canUpdateBio}
                        onClick={() => updateUserAccountBio()}
                        loading={loading}
                >
                    Alterar dados
                </LoadingButton>
            </Box>
            {/*Set profile picture dialog */}
            <Dialog
                open={avatarSelectionDialog}
                onClose={() => setAvatarSelectionDialog(false)}
            >
                <DialogContent> 
                    <ImageSelector 
                        onCrop={handleCropChange}
                        cropConfiguration={{
                            aspect : 1 / 1,
                            width : 128,
                            height : 128
                        }} />
                        {loading && <LinearProgress />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAvatarSelectionDialog(false)} color='error'>Cancelar</Button>
                    <LoadingButton disabled={!canvasOfCroppedImage} onClick={() => uploadAvatar()} loading={loading}>Usar esta foto</LoadingButton>
                </DialogActions>
            </Dialog>
            {/*Remove profile picture */}
            <Dialog
                open={removeAvatarDialog}
                onClose={() => setRemoveAvatarDialog(false)}
            >
                <DialogTitle>
                    <Typography>Remover avatar</Typography>
                </DialogTitle>
                <DialogContent> 
                    <DialogContentText>
                        Deseja realmente remover sua foto de perfil?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRemoveAvatarDialog(false)} color='error'>Cancelar</Button>
                    <LoadingButton onClick={() => removeAvatar()} loading={loading} color='info'>Remover foto</LoadingButton>
                </DialogActions>
            </Dialog>
            <AppNotification />
        </Container>
    );
}

export default MyProfile;