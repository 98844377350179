// export interface AppNotificationProps extends React.ComponentProps<any> {
//     message : string,
//     type : 'success' | 'error' | 'warning',
//     visible : boolean,
//     timeout : number
// }

import { Alert, Snackbar } from "@material-ui/core";
//Redux imports
import {useAppDispatch, useAppSelector} from "../redux/hooks"
import {hide} from "../redux/features/app-global-notification/app-global-notification-slice"

const AppNotification = (props :  React.ComponentProps<any>) : JSX.Element => {
    //Extracting props
    let {message, visible, ...otherProps} = props;
    message = message || "";
    visible = visible || false;

    //Redux props
    const appGlobalNotification = useAppSelector((state) => state.appGlobalNotification);
    const dispatch = useAppDispatch();

    return (
        <Snackbar
            {...otherProps}
            open={appGlobalNotification.visible || visible}
            onClose={() => {dispatch(hide())}}
            autoHideDuration={6000}
            anchorOrigin={{ 
            vertical: "top",
            horizontal: "right"
            }}
        >
          <Alert onClose={() => {dispatch(hide())}} severity={appGlobalNotification.type} sx={{ width: '100%' }}>
            {appGlobalNotification.message || message}
          </Alert>
      </Snackbar>
    );
}

export default AppNotification;