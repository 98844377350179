import SearchResult from "../../models/search-result";
import AccountsAPI from "./accountsApi";

interface ApiSearchResult {
    type : 'CUSTOMER' | 'CUSTOMER_SUBUSER' | 'CUSTOMER_TEAM';
    data : any
}

/**
 * Normalize the description prop from the API search
 * @param data 
 * @returns 
 */
function normalizeDescription(data : ApiSearchResult) : string {
    switch (data.type) {
        case "CUSTOMER_SUBUSER" :
        case "CUSTOMER" :
            return data.data.fullname;
        case "CUSTOMER_TEAM" :
            return data.data.name;
        default :
            throw Error("Not identified entity. " + data.type);
    }
}

/**
 * Normalize the subdescription prop from the API search
 * @param data 
 * @returns 
 */
function normalizeSubdescription(data : ApiSearchResult) : string | null {
    switch (data.type) {
        case "CUSTOMER_SUBUSER" :
        case "CUSTOMER" :
            return data.data.email;
        default :
            return null;
    }
}

/**
 * Normalize the id prop from the API search
 * @param data 
 * @returns 
 */
function normalizeId(data : ApiSearchResult) : string {
    switch (data.type) {
        case "CUSTOMER_SUBUSER" :
        case "CUSTOMER" :
            return data.data.uuid;
        case "CUSTOMER_TEAM" :
            return data.data.guid;
        default :
            throw Error("Not identified entity. " + data.type);
    }
}

const AccountsSearchService = {
    /**
     * Search all entities stored on the accounts organization fulltext database
     * @param query 
     * @returns 
     */
    searchAllEntitiesFromOrganization : async (query : string = '') : Promise<SearchResult[] | null> => {
        //Fetch the result from the API
        const response = await AccountsAPI.get(`/rest/v1/organizations/my/search/all-entities?q=${query}`);

        //If the response is empty, return null
        if (response.status === 204) {
            return null;
        }

        //Parse the response
        const responseData = await (response).data as ApiSearchResult[];

        //Normalize the data
        return responseData.map((data) : SearchResult => {
            return {
                description : normalizeDescription(data),
                subdescription : normalizeSubdescription(data),
                type : data.type,
                id : normalizeId(data),
                link : normalizeId(data),
                object : data.data
            }
        })
    },

    /**
     * Search all entities stored on the accounts organization fulltext database
     * @param query 
     * @returns 
     */
     searchUserAccountsFromOrganization : async (query : string = '') : Promise<SearchResult[] | null> => {
        //Fetch the result from the API
        const response = await AccountsAPI.get(`/rest/v1/organizations/my/search/user-accounts?q=${query}`);

        //If the response is empty, return null
        if (response.status === 204) {
            return null;
        }

        //Parse the response
        const responseData = await (response).data as ApiSearchResult[];

        //Normalize the data
        return responseData.map((data) : SearchResult => {
            return {
                description : normalizeDescription(data),
                subdescription : normalizeSubdescription(data),
                type : data.type,
                id : normalizeId(data),
                link : normalizeId(data),
                object : data.data
            }
        })
    }
}

export default AccountsSearchService;