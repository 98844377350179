import { CustomerInternetDomain, CustomerInternetDomainChallenge, CustomerInternetDomainChallengeStatus, InternetDomainChallengeTypes } from "../../models/customer-internet-domain";
import { Pagination } from "../../models/pagination";
import AccountsApi from "./accountsApi";

/**
 * Interface used to add an new domain challenge
 */
interface AddNewDomainChallenge {
    name : string;
    type : 'TXT' | 'WELL_KNOWN'
}

const CustomerInternetDomainService = {
    /**
     * Add an new CustomerInternetDomainChallenge
     * @param dto 
     * @returns 
     */
    addNewDomainChallenge : async (dto : AddNewDomainChallenge) : Promise<CustomerInternetDomainChallenge> => {
        const responseData = await (await AccountsApi.post(`/rest/v1/customer-internet-domains/challenges`, dto)).data;

        return {
            challenge : {
                ...responseData.challenge,
                type :  responseData.challenge.type as InternetDomainChallengeTypes
            },
            id : responseData.id,
            name : responseData.name,
            status : responseData.status as CustomerInternetDomainChallengeStatus
        }
    },

    addNewDomainWithChallenge : async (domainName : string) : Promise<CustomerInternetDomain> => {
        return await (await AccountsApi.post(`/rest/v1/customer-internet-domains/${domainName}/verify-challenge`)).data;
    },

    /**
     * Fetch pagination data of verified domains
     * @param limit 
     * @returns 
     */
    fetchDomainsPagination : async (limit : number = 10) : Promise<Pagination> => {
        return await (await AccountsApi.get(`/rest/v1/customer-internet-domains/pagination?limit=${limit}`)).data;
    },

    /**
     * Fetch verified domains using pagination
     * @param page 
     * @param limit 
     * @returns 
     */
    fetchDomains : async (page : number, limit : number = 10) : Promise<CustomerInternetDomain[] | null> => {
        const response = await AccountsApi.get(`/rest/v1/customer-internet-domains?page=${page}&limit=${limit}`);

        //Return null if response is 204
        if (response.status === 204) {
            return null;
        }

        return await response.data;
    },

    /**
     * Fetch pagination data of pending challenges
     * @param limit 
     * @returns 
     */
    fetchPendingChallengesPagination : async (limit : number = 10) : Promise<Pagination> => {
        return await (await AccountsApi.get(`/rest/v1/customer-internet-domains/challenges/pagination?limit=${limit}`)).data;
    },

    /**
     * Fetch pending challenges
     * @param page 
     * @param limit 
     * @returns 
     */
    fetchPendingChallenges : async (page : number, limit : number = 10) : Promise<CustomerInternetDomainChallenge[] | null> => {
        const response = await AccountsApi.get(`/rest/v1/customer-internet-domains/challenges?page=${page}&limit=${limit}`);

        //Return null if response is 204
        if (response.status === 204) {
            return null;
        }

        return await response.data;
    },

    /**
     * Delete an pending challenge
     * @param challengeId - The challenge ID
     * @returns 
     */
    deleteChallenge : async (challengeId : string) : Promise<CustomerInternetDomainChallenge> => {
        return await AccountsApi.delete(`/rest/v1/customer-internet-domains/challenges/${challengeId}`);
    },

    /**
     * Delete an verified domain
     * @param domain - The domain to be deleted
     * @returns 
     */
     deleteDomain : async (domain : CustomerInternetDomain) : Promise<CustomerInternetDomain> => {
        return await AccountsApi.delete(`/rest/v1/customer-internet-domains/${domain.name}`);
    },
};

export default CustomerInternetDomainService;