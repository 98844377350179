import { Alert, Checkbox, Dialog, FormControlLabel, TextField } from "@material-ui/core";
import { Box } from "@material-ui/system";
import { useContext, useState } from "react";
import LoadingButton from "../../components/LoadingButton";
import AuthenticationContext from "../../contexts/authentication";
import ErrorWrapper from "../../utils/ErrorWrapper";

function RenewAccessDialog(props :  React.ComponentProps<any>) {
    //Parent props
    const { onLogin, ...others } = props;

    //State props
    const [password, setPassword] = useState('');
    const [trustDevice, setTrustDevice] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>(null!);

    //Context props
    function useAuthentication() {
        return useContext(AuthenticationContext);
    }
    const authUser = useAuthentication();

    //Functions
    async function updateSession() {
        try {
            setError(null!);
            setLoading(true);
            //Authenticate the user again
            await authUser.renewSessionWithPassword(password, trustDevice);

            //Call onLogin callback
            if (onLogin) {
                onLogin();
            }

        }catch (e) {
            setError(new ErrorWrapper(e).message);
        } finally {
            setLoading(false);
        }
    }

    //Inner components
    /**
     * Default error element handling
     * @returns 
     */
     function ErrorAlert() : JSX.Element {
        if (error) {
            return <Alert severity='error' onClose={() => setError(null!)}>{error}</Alert>
        }
        else {
            return <span></span>
        }
    }

    return(
        <Dialog {...others}>
            <h2 className="ta-c">Confirme sua identidade</h2>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 0.5, width: '25ch' },
                    padding : 8
                }}
                noValidate
                autoComplete="off"
                id="formLogin"
            >
                <div className="ta-c">
                    <p>Olá, {authUser.user().account.preferredName}</p>
                    <p>Reinsira suas credenciais para poder utilizar o serviço solicitado</p>
                </div>
                <Box
                    sx={{
                        display : 'flex',
                        flexDirection : 'column'
                    }}
                >
                    <p><b>{authUser.user().user.email}</b></p>
                    <Box 
                        sx={{
                            display : 'flex',
                            justifyContent : 'center'
                        }}
                    >
                        <TextField style={{width : '100%', maxWidth : '850px'}} name="password" value={password} onChange={(e) => setPassword(e.target.value)} label="Senha" type="password" variant="outlined" />
                    </Box>
                    <FormControlLabel
                        label="Continuar conectado neste dispositivo"
                        control={<Checkbox checked={trustDevice} onChange={(e) => setTrustDevice(e.target.checked)}/>}
                    />
                    <LoadingButton loading={loading} variant="contained" onClick={updateSession}>Entrar</LoadingButton>
                </Box>
            </Box>
            <ErrorAlert/> 
        </Dialog>
    );
}

export default RenewAccessDialog;