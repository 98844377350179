import { Button } from "@material-ui/core";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = (): JSX.Element => {
    const navigate = useNavigate();

    return (
        <Container>
            <Box sx={{ justifyContent: "center", alignItems: "center", mt: "25%" }}>
                <Box sx={{ display: "grid", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <img style={{ display: "block", margin: "auto", marginBottom: "16px" }} src="../images/products-logos/logo-klaus.svg" width={280} />
                    <Typography sx={{ color: "#1976D2", fontSize: "2.5rem", fontWeight: 600, mb: 2 }}>Ops... 404!</Typography>
                    <Typography sx={{ fontSize: "1rem", mb: 2 }}>Parece que você não conseguiu encontrar o que procurava.</Typography>
                    <Button onClick={() => navigate("/", { replace: true }) }>Voltar ao início</Button>
                </Box>
            </Box>
        </Container>
    )
}

export default NotFound;
