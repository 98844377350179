import { Autocomplete, Box, TextField, Typography } from "@material-ui/core";
import { AccountCircle, AdminPanelSettings, Groups, Search } from "@mui/icons-material";
import React, { useState } from "react";
import SearchResult from "../models/search-result";
import AccountsSearchService from "../services/accounts/accounts-search";

interface SearchBarProps extends React.ComponentProps<any> {
    searchMode? : 'all-entities' | 'user-accounts',
    rederInputProps? : React.ComponentProps<any>
}

const SearchBar = (props : SearchBarProps) : JSX.Element => {

    //Global state props
    const [queryText, setQueryText] = useState("");
    const [searchResult, setSearchResult] = useState<SearchResult[]>([]);

    //Component props
    let {searchMode, renderInputProps, ...otherProps} = props;

    //Set default values for component props
    searchMode = (searchMode) ? searchMode : 'all-entities';
    renderInputProps = (renderInputProps) ? renderInputProps : {};

    //Store the timeout state as React state
    const [keyDownTimeout, setKeyDownTimeout] = useState<NodeJS.Timeout | null>(null);

    //Events
    const onChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        //Update the query text prop
        setQueryText(event.target.value);

        //Interrupt the last timeout
        if (keyDownTimeout) {
            clearTimeout(keyDownTimeout);
        }

        setKeyDownTimeout(setTimeout(() => {
            if (event.target.value.length >= 2) {
                fetchSearchResults();
            }
        }, 600));
    }

    // Inner components
    function CustomAvatar(searchResult : SearchResult) : JSX.Element | null {
        switch (searchResult.type) {
            case 'CUSTOMER':
                return <AdminPanelSettings color="primary" />;
            case 'CUSTOMER_SUBUSER':
                return <AccountCircle color="secondary" />;
            case 'CUSTOMER_TEAM':
                return <Groups color="success" />;
            default:
                return null;
        }
    }

    //Global events
    const fetchSearchResults = () => {
        switch (searchMode) {
            case 'all-entities':
                AccountsSearchService.searchAllEntitiesFromOrganization(queryText)
                .then(response => {
                    if (response) {
                        setSearchResult(response);
                    }
                    else {
                        setSearchResult([]);
                    }  
                })
                .catch(e => {
                    console.error(e);
                });
            break;
            case 'user-accounts':
                AccountsSearchService.searchUserAccountsFromOrganization(queryText)
                .then(response => {
                    if (response) {
                        setSearchResult(response);
                    }
                    else {
                        setSearchResult([]);
                    }  
                })
                .catch(e => {
                    console.error(e);
                });
            break;
            default:
                throw Error(`Unknown/Not supported search mode: ${searchMode}`);

        }
    };

    return (
        <Autocomplete
            {...otherProps}
            options={searchResult}
            getOptionLabel={(option : SearchResult) => option.description}
            filterOptions={(x) => x}
            disableCloseOnSelect
            renderInput={(params) => (
                <TextField {...params} {...renderInputProps} onChange={onChange} InputProps={{
                    ...params.InputProps,
                    startAdornment : <span><Search />{params.InputProps.startAdornment}</span>
                }} />
            )}
            renderOption={(props, option, { selected }) => {
                return (
                    <li {...props}>
                        <Box display={'flex'} flexDirection={'row'}>
                            {CustomAvatar(option)}
                            <Box display={'flex'} ml={3} flexDirection={'column'}>
                                <Typography color={'text.primary'} fontSize={'0.9em'}>
                                    {option.description}
                                </Typography>
                                <Typography color={'text.secondary'} fontSize={'0.8em'}>
                                    {option.subdescription}
                                </Typography>
                            </Box>
                        </Box>
                    </li>
                );
               }
            }
        />
    );
    
}

export default SearchBar;