import { AccountTypes, AuthenticatedUser, AuthenticationContextData } from "../models/user";
import AuthenticationService from "../services/accounts/authentication";

const STORE_USER = "App@user"
const STORE_TOKEN = "Dev@token";

const storage = localStorage;

/**
 * Class that store authenticated user data on application cache
 */
class AuthenticationStore implements AuthenticationContextData {
    is(type: AccountTypes): boolean {
        //if the user is not authenticated return false
        if (!this.isAuthenticated()) {
            return false;
        }

        //Otherwise return the type check
        return (this.user().session.accountType as AccountTypes) === type;
    }

    isAuthenticated(): boolean {
        return this.user() != null
    }

    user(): AuthenticatedUser {
        const storageData = storage.getItem(STORE_USER);
        return (storageData) ? JSON.parse(storageData) : null;
    }

    async updatedUser(): Promise<AuthenticatedUser> {
        //Return user data from server
        const authenticatedUser = await AuthenticationService.fetchAuthenticatedUserData();

        //Store the user data
        storage.setItem(STORE_USER, JSON.stringify(authenticatedUser));

        return authenticatedUser;
    }

    async authenticateWithAuthToken(authToken: string): Promise<AuthenticatedUser> {
        //Set auth token 
        this.setToken(authToken);

        //Update authentidated user with new token
        return await this.updatedUser();
    }

    async authenticateWithEmailAndPassword(email: string, password: string, trustDevice: boolean): Promise<AuthenticatedUser> {
        //Authenticated the user
        const authenticationResponse = await AuthenticationService.authenticateWithEmailAndPassword({
            email: email,
            password: password,
            trustDevice: trustDevice
        });

        //Store the authentication token on storage
        this.setToken(authenticationResponse.token);

        //Return the updated user from server
        return await this.updatedUser();
    }

    async renewSessionWithPassword(password: string, trustDevice: boolean): Promise<AuthenticatedUser> {
        const authenticationResponse = await AuthenticationService.renewTokenWithPassword({
            password: password,
            trustDevice: trustDevice
        });

        //Store the authentication token on storage
        this.setToken(authenticationResponse.token);
        
        //Return the updated user from server
        return await this.updatedUser();
    }

    signout(callback: VoidFunction) {
        storage.removeItem(STORE_USER);
        if (callback) callback();
    }

    /**
     * Return the user authentication token
     * @returns 
     */
    getToken(): string | null {
        return storage.getItem(STORE_TOKEN);
    }

    /**
     * Store the token on storage
     * @param token 
     */
    setToken(token: string) {
        storage.setItem(STORE_TOKEN, token);
    }

    hasStlAtLeast(requiredStlLevel: number): boolean {
        return this.user().session.stl >= requiredStlLevel;
    }
}

export default new AuthenticationStore();