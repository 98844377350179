/**
 * Abstraction of an contextual Api configuration
 */
interface ApiContext {
    baseURL : string;
    timeout : number;
}

interface CdnServer {
    server : string;
}

/**
 * Abstract of the web application context
 */
class WebappContext {
    /**
     * Store the Accounts API configuration
     */
    accountsAPI : ApiContext;

    accountsCdn : CdnServer;

    authWebappURL : string;

    cartoriziAuthenticationURL : string;

    constructor(accountsApi : ApiContext, accountsCdn : CdnServer, authWebAppURL : string, cartoriziAuthenticationURL : string) {
        this.accountsAPI = accountsApi;
        this.accountsCdn = accountsCdn;
        this.authWebappURL = authWebAppURL;
        this.cartoriziAuthenticationURL = cartoriziAuthenticationURL;
    }
}

//Store the default development host name
const developmentHost = process.env.REACT_APP_DCHOST || 'localhost';
const avatarCdn = process.env.REACT_APP_AVATAR_CDN || 'http://localhost:8080';
console.log(developmentHost);

//Create the development context
const DevelopmentContext = new WebappContext(
    {
        baseURL : `http://${developmentHost}:21001/klausc2w-accounts-ws`,
        timeout : 10000
    },
    {
        server : avatarCdn
    },
    "http://localhost:22002",
    "http://localhost:22002/login?service=contracts"
);

const ProductionContext = new WebappContext(
    {
        baseURL : "https://accounts-ws.klaus.services",
        timeout : 30000
    },
    {
        server : "https://accountsblob.blob.core.windows.net/publicassets"
    },
    "https://auth.klaus.services",
    "https://auth.klaus.services/login?service=contracts"
);

//Store the current application context
let CurrentContext : WebappContext;
if (process.env.NODE_ENV === 'development') {
    CurrentContext = DevelopmentContext;
}
else {
    CurrentContext = ProductionContext;
}

//Export modules
export {CurrentContext};
export {DevelopmentContext};