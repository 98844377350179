import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'

export interface Message {
    message : string | null;
    type : 'success' | 'warning' | 'error' | 'info';
    visible : boolean
}


export interface MessagePayload {
  message? : string,
  type? : 'success' | 'warning' | 'error' | 'info'
}

const initialState : Message = {
    message : null,
    type : 'success',
    visible : false
} as Message

export const AppGlobalNotificationSlice = createSlice({
  name: 'appGlobalNotification',
  initialState,
  reducers: {
    show: (state, action: PayloadAction<MessagePayload>) => {
      state = {
          message : (action.payload.message) ? action.payload.message : null,
          type : (action.payload.type) ? action.payload.type : 'success',
          visible : true
      }
      return state;
    },
    hide: (state) => {
      state.visible = false;
      return state;
    },
  },
})

// Action creators are generated for each case reducer function
export const { show, hide } = AppGlobalNotificationSlice.actions

//Export the reducer
export const selectAppGlobalNotification = (state : RootState) => state.appGlobalNotification;

export default AppGlobalNotificationSlice.reducer;
