import { Box } from "@material-ui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

interface ImageSelectorProps extends React.ComponentProps<any> {
    onCrop : {(canvas : HTMLCanvasElement) : void},
    cropConfiguration? : Partial<Crop>
}
const ImageSelector = (props : ImageSelectorProps) : JSX.Element => {
    //Props extraction
    const {cropConfiguration, onCrop, ...otherProps} = props
    
    //Refs
    let imgRef = useRef<HTMLImageElement | null>(null);
    let previewCanvasRef = useRef<HTMLCanvasElement | null>(null);

    //State props
    const [crop, setCrop] = useState<Partial<Crop>>((cropConfiguration) ? cropConfiguration : {});
    const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
    const [uploadedImage, setUploadedImage] = useState<string | null>(null);

    //Use effect that observer the completedProp value
    useEffect(() => {
        if (!onCrop || !completedCrop || !previewCanvasRef.current || !imgRef.current) {
          return;
        }
    
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;
    
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        //Ignore if context is null
        if (ctx === null) {
            return;
        }
        const pixelRatio = window.devicePixelRatio;
    
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
    
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );

        //Call callback
        onCrop(canvas);
      }, [completedCrop, onCrop]);
    
    //Callbacks
    const onLoad = useCallback((img : HTMLImageElement) => {
        imgRef.current = img;
    }, []);

    //Events
    const onSelectFile = (e : React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUploadedImage(reader.result as string));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    return (
        <Box {...otherProps}>
             <div>
                <input type="file" accept="image/*" onChange={onSelectFile} />
            </div>
            {
                (uploadedImage !== null) 
                ?
                (
                    <Box 
                        
                    >
                        <ReactCrop src={uploadedImage} crop={crop} onImageLoaded={onLoad} onComplete={(c) => setCompletedCrop(c)} onChange={(c) => setCrop(c)}/>
                        <div>
                            <canvas
                                ref={previewCanvasRef}
                                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                style={{
                                    width: Math.round(completedCrop?.width ?? 0),
                                    height: Math.round(completedCrop?.height ?? 0),
                                    display : 'none'
                                }}
                            />
                        </div>
                    </Box>
                )
                
                :
                null
            }
        </Box>
    );
}

export default ImageSelector;