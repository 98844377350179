/**
 * Abstraction of platform user account
 */
export interface User {
    creationDate: Date,
    uuid: string,
    hasAvatar: boolean,
    email: string,
    preferredRegion: string,
    emailConfirmationDate: Date,
    blockDate?: number
};

/**
 * Abstraction of the platform user account type
 */
export interface UserAccount {
    preferredName: string,
    fullname: string,
    user: User,
    roles: string[],
    organizationId: string
};

/**
 * Enumerate all account types of the platform
 */
export enum AccountTypes {
    Administrator = "ADMINISTRATOR",
    Customer = "CUSTOMER",
    CustomerSubuser = "CUSTOMER_SUBUSER"
}

export enum Roles {
    ROLE_STL_0,
    ROLE_STL_1,
    ROLE_STL_2,
    ROLE_STL_3,
    ROLE_STL_4,

    ROLE_ACCOUNT_TYPE_CUSTOMER,
    ROLE_ACCOUNT_TYPE_CUSTOMER_SUBUSER,

    ROLE_CSM,
    ROLE_CIDM
}

/**
 * Store session data
 */
export interface Session {
    stl: number,
    accountType: AccountTypes,
    roles: Roles[]
};

/**
 * Encapsulates User, UserAccount and Session objects of the same authenticated user
 */
export interface AuthenticatedUser {
    user: User,
    account: UserAccount,
    session: Session,
}

export interface AuthenticationContextData {
    /**
     * Flag that indicates if the user is authenticated
     */
    isAuthenticated: () => boolean,
    /**
     * Authenticate the user using the combination of email and password
     */
    authenticateWithEmailAndPassword: (email: string, password: string, trustDevice: boolean) => Promise<AuthenticatedUser>,

    /**
     * Authenticate the user using the token from auth webapp 
     */
    authenticateWithAuthToken: (authToken: string) => Promise<AuthenticatedUser>,

    renewSessionWithPassword: (password: string, trustDevice: boolean) => Promise<AuthenticatedUser>,
    /**
     * Remove the authenticate user data from the application
     */
    signout: (callback: VoidFunction) => void;

    /**
     * Get the data from the authenticated user
     */
    user: () => AuthenticatedUser,

    /**
     * Get the version of the user that is always updated from the server
     */
    updatedUser: () => Promise<AuthenticatedUser>,

    /**
     * Return an flag indicating if the authenticated user has an STL level greater or equals than the given one
     */
    hasStlAtLeast: (requiredStlLevel: number) => boolean,

    /**
     * Return an flag indicating if the authenticated user is from an given account type
     */
    is: (type: AccountTypes) => boolean,

    /**
     * Get authenticated user token from local storage
     */
    getToken: () => string | null,
}