import AccountsApi from "./accountsApi";

/**
 * Create account DTO
 */
interface CreateAccountDTO {
    preferredName : string,
    fullname : string,
    paymentRegion : 'BRA',
    user : {
        preferredRegion : 'BRA',
        email : string,
        password : string,
    }
}

const CustomerService = {
    /**
     * Create account
     * @param dto 
     */
    createAccount : async (dto : CreateAccountDTO) => {
        await AccountsApi.post(`/rest/v1/customers/public`, dto);
    }
}

export default CustomerService;