import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from "@material-ui/core";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

/**
 * Tips for organization members
 * @returns 
 */
function OrganizationMemberTips() : JSX.Element {
    return (
        <ul>
            <li><b>Você pode ter sido bloqueado por um administrador</b>
                <ul>
                    <li>Neste cenário, apenas a administração da organização que você fazia parte pode liberar o acesso da sua conta</li>
                </ul>
            </li>
        </ul>
    )
}

/**
 * Tips for not organization members
 * @returns 
 */
function NotOrganizationMembersTips() : JSX.Element { 
    return (
        <ul>
            <li><b>Você pode ter sido bloqueado por algum procedimento de segurança da plataforma</b>
                <ul>
                    <li>
                        Algum recurso da nossa plataforma, seja ele software um por um colaborador pode ter solicitado o bloqueio da sua conta.
                        Neste cenário você deve receber um e-mail com o motivo do bloqueio. Se você achar que o motivo do bloqueio foi indevido ou
                        se ficou alguma dúvida do motivo, entre em contato com o suporte para abrir um pedido de desbloqueio de conta.
                    </li>
                </ul>
            </li>
        </ul>
    );
}

const UserAccountBlocked = () : JSX.Element => {
    return (
        <Container 
            sx={{
                paddingTop : 8
            }}
        >
            <h1 className='ta-c'>Sua conta foi bloqueada</h1>
            <Typography variant='subtitle2' className='ta-c'>#AS-406</Typography>
            <h2 className='ta-c'>Possíveis Motivos</h2>
            <Accordion>
                <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>Minha conta pertencia a uma organização</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <OrganizationMemberTips />
                    <hr/>
                    <NotOrganizationMembersTips />
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>Minha conta não pertencia a uma organização</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <NotOrganizationMembersTips />
                </Typography>
                </AccordionDetails>
            </Accordion>
        </Container>
    );
};

export default UserAccountBlocked;