import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContentText, DialogTitle, Drawer, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Menu, MenuItem, Radio, RadioGroup, Step, StepLabel, Stepper, TextField, Typography } from "@material-ui/core"
import { Menu as MenuIcon, ExpandMore, Info, DomainVerification, DeleteForever, Check, Error, PriorityHigh } from "@mui/icons-material";
import { DialogContent } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { CustomerInternetDomain, CustomerInternetDomainChallenge, InternetDomainChallengeTypes, parseInternetDomainChallengeTypesToString } from "../../models/customer-internet-domain";
import {AppNotification, LoadingButton} from '../../components'
import CustomerInternetDomainService from "../../services/accounts/customer-internet-domain";
import { Pagination } from "../../models/pagination";

//Redux
import {useAppDispatch} from '../../redux/hooks';
import {show} from "../../redux/features/app-global-notification/app-global-notification-slice"
import ErrorWrapper from "../../utils/ErrorWrapper";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import SecurityTierLevels from "../../security/stl";
import AuthenticationContext from "../../contexts/authentication";
import StlInterceptorInstance from "../../contexts/stl";
import RenewAccessDialog from "../renew-access/RenewAccessDialog";

/**
 * DTO used to add domains challenges
 */
interface AddDomainChallenge {
    name : string;
    challengeType : InternetDomainChallengeTypes
}

enum DomainVerificationStatus {
    Processing,
    VerificationError,
    ServerError,
    Approved
}

const Domains = () : JSX.Element => {
    //Context props

    //authUser
    function useAuthentication() {
        return useContext(AuthenticationContext);
    }
    let authUser = useAuthentication();

    //stl
    function useStl() {
        return useContext(StlInterceptorInstance);
    }
    let stl = useStl();
    
    //Redux props
    const dispatch = useAppDispatch();

    //Store loading state prop
    const [loading, setLoading] = useState(false);

    //Store visibility flag to  DomainVerificationChallengeInfoDrawer
    const [domainVerificationChallengeInfoDrawerVisible, setDomainVerificationChallengeInfoDrawerVisible] = useState(false);

    //Store visibility state of AddDomainDialog subcomponent
    const [addDomainDialogVisible, setAddDomainDialogVisible] = useState(false);

    //Store visibility state of DeleteDomainDialog subomponent
    const [deleteDomainDialogVisible, setDeleteDomainDialogVisible] = useState(false);

    //Store visibility state of DeleteDomainChallengeDialog subomponent
    const [deleteDomainChallengeDialogVisible, setDeleteDomainChallengeDialogVisible] = useState(false);
    
    //Store visibility state of RenewAccessDialog
    const [renewAccessDialog, setRenewAccessDialog] = useState(false);

    //Store visibility state of VerifyDomainDialog subcomponent
    const [verifyDomainDialogVisible, setVerifyDomainDialogVisible] = useState(false);

    //Store pagination data and the data itself of registered verified domains
    const [domainsPagination, setDomainsPagination] = useState<Pagination | null>(null);
    const [domains, setDomains] = useState<CustomerInternetDomain[]>([]);
    const [domainsPage, setDomainsPage] = useState(1);

    //Store pagination data and the data itself of pending challenges
    const [challengesPagination, setChallengesPagination] = useState<Pagination | null>(null);
    const [challenges, setChallenges] = useState<CustomerInternetDomainChallenge[]>([]);
    const [challengesPage, setChallengesPage] = useState(1);

    //Store the selected domain on the UI
    const [selectedDomain, setSelectedDomain] = useState<CustomerInternetDomain | null>(null);
    
    //Store the selected challenge on the UI
    const [selectedChallenge, setSelectedChallenge] = useState<CustomerInternetDomainChallenge | null>(null);

    //Store the anchor HTML element associated with the challenge context menu
    const [challengesContextMenuAnchor, setChallengesContextMenuAnchor] = useState<HTMLElement | null>(null);

    //Effects

    //Effect to load pagination data for verified domains
    useEffect(() => {
        setLoading(true);
        CustomerInternetDomainService.fetchDomainsPagination()
        .then(pagination => {
            setDomainsPagination(pagination);
        })
        .catch(e => {
            dispatch(show({
                message : new ErrorWrapper(e).message,
                type : 'error'
            }))
        })
        .finally(() => {
            setLoading(false);
        })
    }, [dispatch]);

    //Effect to fetch current page verified domains
    useEffect(() => {
        setLoading(true);
        CustomerInternetDomainService.fetchDomains(domainsPage)
        .then(response => {
            if (response) {
                setDomains(response);
            }
        })
        .catch(e => {
            dispatch(show({
                message : new ErrorWrapper(e).message,
                type : 'error'
            }))
        })
        .finally(() => {
            setLoading(false);
        });
    }, [domainsPage, dispatch]);

    //Effect to load pagination data for domain challenges
    useEffect(() => {
        setLoading(true);
        CustomerInternetDomainService.fetchPendingChallengesPagination()
        .then(pagination => {
            setChallengesPagination(pagination);
        })
        .catch(e => {
            dispatch(show({
                message : new ErrorWrapper(e).message,
                type : 'error'
            }))
        })
        .finally(() => {
            setLoading(false);
        })
    }, [dispatch]);

    //Effect to fetch current page pending challenges
    useEffect(() => {
        setLoading(true);
        CustomerInternetDomainService.fetchPendingChallenges(challengesPage)
        .then(response => {
            if (response) {
                setChallenges(response);
            }
        })
        .catch(e => {
            dispatch(show({
                message : new ErrorWrapper(e).message,
                type : 'error'
            }))
        })
        .finally(() => {
            setLoading(false);
        });

    }, [challengesPage, dispatch]);

    //Subcomponents

    function DeleteDomainDialog() : JSX.Element | null{
        const [loading, setLoading] = useState(false);

        if (selectedDomain === null) {
            return null;
        }

        //Function to delete domain
        function deleteDomain() {
            if (selectedDomain === null) {
                return;
            }

            setLoading(true);
            CustomerInternetDomainService.deleteDomain(selectedDomain)
            .then(deletedDomain => {
                //Remove the domain from the UI
                setDomains(domains.filter(d => d.name !== selectedDomain.name));

                //Close this dialog
                setDeleteDomainDialogVisible(false);

                //Show success dialog
                dispatch(show({
                    message : 'Domínio deletado com sucesso',
                    type : 'success'
                }))
            })
            .catch((e) => {
                dispatch(show({
                    message : new ErrorWrapper(e).message,
                    type : 'error'
                }))
            })
            .finally(() => {
                setLoading(false);
            })
        }

        return (
            <Dialog
                open={deleteDomainDialogVisible}
                onClose={() => {setDeleteDomainDialogVisible(false)}}
            >
                <DialogContent>
                    <DialogTitle>Deletar domínio</DialogTitle>
                    <DialogContentText>
                        Deseja realmente deletar o domínio <b>{selectedDomain.name}</b>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setDeleteDomainDialogVisible(false)}}>Cancelar</Button>
                    <LoadingButton color="error" onClick={() => deleteDomain()}  
                        loading={loading}
                    >
                        Deletar domínio
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }

    /**
     * Drawer that shows information abou the domain verification challenge
     * @returns 
     */
    function DomainVerificationChallengeInfoDrawer() : JSX.Element {
        return (
            <Drawer
                anchor='right'
                open={domainVerificationChallengeInfoDrawerVisible && selectedChallenge !== null}
                onClose={() => setDomainVerificationChallengeInfoDrawerVisible(false)}
            >
                <Container
                    sx={{
                        width : '80vw'
                    }}
                >
                    <h4>Instruções para verificação de domínio</h4>
                    <hr/>
                    <p><b>Domínio:</b> {selectedChallenge?.name}</p>
                    <p><b>Desafio:</b> {selectedChallenge?.challenge?.type}</p>
                    <hr/>
                {(selectedChallenge !== null) ? challengeSteps(selectedChallenge) : null}
                </Container>
            </Drawer>
        );
    }

    /**
     * Dialog used to add a new domain verification challenge
     * @returns 
     */
    function AddDomainDialog() : JSX.Element {
        //Stateful props
        //Store the state of the model used to register an new domain verification challenge
        const [addDomainModel, setAddDomainModel] = useState<AddDomainChallenge>({
            name : "",
            challengeType : InternetDomainChallengeTypes.Txt
        });

        //When the challenge is registered it wil be stored in this state prop to show the secret generated by the back-end
        const [registeredChallenge, setRegisteredChallenge] = useState<CustomerInternetDomainChallenge | null>(null);

        //Store the state to check if the domain challenge can be added
        const [formValidated, setFormValidated] = useState(false);

        //Store the current step
        const [step, setStep] = useState(0);

        //Store an global flag indicating if the UI is loading
        const [loading, setLoading] = useState(false);

        //Effect to validate the form
        useEffect(() => {
            //Set the rules to validate the form
            setFormValidated(
                /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(addDomainModel.name) &&
                !!addDomainModel.challengeType
            );
        }, [addDomainModel]);

        //Stateless props
        const challengesInfo = [
            {
                value : InternetDomainChallengeTypes.Txt,
                label : "Registro no DNS (TXT)",
            },
            {
                value : InternetDomainChallengeTypes.WellKnown,
                label : "Arquivo de verificação (WELL_KNOWN)",
            }
        ];

        const steps = [
            'Insira seu domínio e escolha o desafio',
            'Desafio iniciado',
        ];
          
        
        //Component events handlers
        const handleChallengeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
            setAddDomainModel({
                ...addDomainModel,
                challengeType : event.target.value as InternetDomainChallengeTypes
            });
        };
        const handleNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
            setAddDomainModel({
                ...addDomainModel,
                name : event.target.value
            });
        };
        const handleDialogClose = () => {
            //Add the registered challenge into the list
            if (registeredChallenge) {
                setChallenges([...challenges, registeredChallenge]);
            }
            
            //Close the dialog
            setAddDomainDialogVisible(false);
        }

        //Inner components
        function challengeDescription(challengeType : InternetDomainChallengeTypes) : JSX.Element | null {
            switch (challengeType) {
                case InternetDomainChallengeTypes.Txt:
                    return (
                        <div>
                            <p>Siga as instruções abaixo para completar o desafio de registro no DNS (TXT):</p>
                            <ol className="spaced-list">
                                <li>Nossa plataforma vai gerar uma <b>chave secreta</b>;</li>
                                <li>Adicione um registro <b>TXT</b> no DNS do seu domínio <b>{addDomainModel.name}</b> e no valor adicione a <b>chave secreta</b>;</li>
                                <li>Volte para esta plataforma e na lista de domínios aguardando verificação, selecione o domínio <b>{addDomainModel.name} </b>
                                e clique em <b>"Verificar domínio"</b>;
                                </li>
                            </ol>
                            <p><b>*</b>É possível que demore até 24 horas para que seu DNS propague a mudança com este método</p>
                            <p><b>*</b>A <b>chave secreta</b> só será gerada quando você clicar em <b>"Iniciar verificação de domínio"</b></p>
                        </div>
                    );
                case InternetDomainChallengeTypes.WellKnown:
                    return (
                        <div>
                            <p>Siga as instruções abaixo para completar o desafio de arquivo de verificação (WELL_KNOWN):</p>
                            <ol className="spaced-list">
                                <li>Nossa plataforma vai disponibilizar <b>um arquivo</b> com um nome aleatório. Esse nome é a chave de verificação;</li>
                                <li>Em seu servidor HTTP/S do domínio, adicione na raiz dos arquivos a pasta: <b>.well-known/acme-challenge</b>;</li>
                                <li>Adicione o arquivo gerado pela plataforma nesta pasta;</li>
                                <li>Após o passo anterior, volte para a lista de domínios aguardando verificação, selecione o domínio <b>{addDomainModel.name} </b>
                                e clique em <b>"Verificar domínio";</b>
                                </li>
                            </ol>
                            <p><b>*</b>A <b>chave secreta</b> só será gerada quando você clicar em <b>"Iniciar verificação de domínio"</b></p>
                        </div>
                    );
                default : 
                        return null;
            }
        }

        //Functions 
        function addNewChallenge() {
            setLoading(true);
                
            //Parse the enum value to accepted string format
            const type = parseInternetDomainChallengeTypesToString(addDomainModel.challengeType);

            //Null check
            if (type === null) {
                console.error("Not supported enumeration value found: " + addDomainModel.challengeType);
                return;
            }

            //Call the API to register the challenge
            CustomerInternetDomainService.addNewDomainChallenge({
                name : addDomainModel.name,
                type : type
            })
            .then(createdChallenge => {
                //Update the state of the registered challenge prop
                setRegisteredChallenge(createdChallenge);
                setStep(1);
                dispatch(show({
                    message : 'Desafio de verificação de domínio criado',
                    type : 'info'
                }))
            })
            .catch((e) => {
                dispatch(show({
                    message : new ErrorWrapper(e).message,
                    type : 'error'
                }))
            })
            .finally(() => {
                setLoading(false);
            });
        }

        return (
            <Dialog
                open={addDomainDialogVisible}
                onClose={handleDialogClose}
            >
                <DialogContent>
                    {/*Steppers */}
                    <Box sx={{width : '100%', mb : 8}}>
                        <Stepper activeStep={step} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    {/*Step 0: Create challenge form*/}
                    {
                    (step === 0) ? 
                        (
                            <Box
                                component={"form"}
                                noValidate={true}
                                sx={{
                                    mt : 2,
                                    display : (step === 0) ? 'inherit' : 'none'
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField fullWidth={true} variant='standard' placeholder="Digite seu domínio: exemplo.com" value={addDomainModel.name} onChange={handleNameChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Desafio de verificação de domínio</FormLabel>
                                            <RadioGroup row aria-label="desafio" name="row-radio-buttons-group" onChange={handleChallengeChange} value={addDomainModel.challengeType}>
                                                <React.Fragment>
                                                    {challengesInfo.map((challenge) : JSX.Element => (
                                                        <FormControlLabel key={challenge.value} value={challenge.value} control={<Radio />} label={challenge.label} />
                                                    ))}
                                                </React.Fragment> 
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                        <Typography>Detalhes do desafio</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {challengeDescription(addDomainModel.challengeType)}
                                        </AccordionDetails>
                                    </Accordion>
                                    </Grid>
                                </Grid>
                            </Box>
                        )
                    :
                    (step === 1) ? 
                    (
                        <Box
                            sx={{
                                mt : 2,
                                display : (step === 1) ? 'inherit' : 'none'
                            }}
                        >
                            <p><b>Domínio: </b> {registeredChallenge?.name}</p>
                            <p><b>Desafio: </b> {registeredChallenge?.challenge?.type}</p>
                            <hr/>
                            {(registeredChallenge) ? challengeSteps(registeredChallenge) : null}
                        </Box>
                    ) 
                    : 
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>
                        {(registeredChallenge === null) ? 'Cancelar' : 'Fechar'}
                    </Button>
                    <LoadingButton color="success" disabled={!formValidated} onClick={() => addNewChallenge()}  
                        loading={loading}
                        sx={{
                            display : (registeredChallenge === null) ? 'inherit' : 'none'
                        }}
                    >
                        Iniciar verificação de domínio
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }

    /**
     * Dialog component to delete existing challenges
     */
    function DeleteDomainChallengeDialog() : JSX.Element {
        //Store loading state
        const [loading, setLoading] = useState(false);

        //Event handlers
        const handleDialogClose = () => {
            setDeleteDomainChallengeDialogVisible(false);
        }

        //Functions
        function deleteChallenge() {

            //Ignore the delete method if selected challenge is null
            if (selectedChallenge === null) {
                return;
            }

            setLoading(true);

            //Call the API to delete the selected challenge
            CustomerInternetDomainService.deleteChallenge(selectedChallenge?.id)
            .then(() => {
                //Remove the deleted challenge from the view list
                setChallenges(challenges.filter(c => c.id !== selectedChallenge.id));

                //Set this dialog invisible
                setDeleteDomainChallengeDialogVisible(false);

                //Show success message
                dispatch(show({
                    message : "Verificação deletada com sucesso",
                    type : 'success'
                }))

            })
            .catch((e) => {
                dispatch(show({
                    message : new ErrorWrapper(e).message,
                    type : 'error'
                }))
            })
            .finally(() => {
                setLoading(false);
            });
        }

        return (
            <Dialog
                open={deleteDomainChallengeDialogVisible}
                onClose={handleDialogClose}
            >
                <DialogContent>
                    <DialogTitle className='ta-c'>
                        Deletar domínio {selectedChallenge?.name}
                    </DialogTitle>
                    <DialogContentText className="ta-c">
                        Deseja realmente deletar o desafio de verificação do domínio <b>{selectedChallenge?.name}</b>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">Cancelar</Button>
                    <LoadingButton loading={loading} onClick={() => {deleteChallenge()}} color="error">Deletar</LoadingButton>
                </DialogActions>
            </Dialog>
        );
    }

    //Inner components
    function challengeSteps(registeredChallenge : CustomerInternetDomainChallenge) : JSX.Element | null {
        if (registeredChallenge === null) {
            return null;
        }

        const challengeCustomData : any = {
            ...registeredChallenge.challenge
        }

        switch (registeredChallenge.challenge.type) {
            case InternetDomainChallengeTypes.Txt:
                return (
                    <div>
                        <p>Siga as instruções abaixo para completar o desafio de registro no DNS (TXT):</p>
                        <ol className="spaced-list">
                            <li>Adicione um registro <b>TXT</b> no DNS do seu domínio <b>{registeredChallenge.name}</b> e no valor adicione a chave secreta:    
                            <b> {challengeCustomData.secret}</b></li>
                            <li>Volte para esta plataforma e na lista de domínios aguardando verificação, selecione o domínio <b>{registeredChallenge.name}</b>
                            e clique em <b>"Verificar domínio"</b>;
                            </li>
                        </ol>
                        <p><b>*</b>É possível que demore até 24 horas para que seu DNS propague a mudança com este método</p>
                    </div>
                );
            case InternetDomainChallengeTypes.WellKnown:

                //Create an empty file link
                const file = new Blob([challengeCustomData.fileName], {type: "octet/stream"});
                const urlHrefContent = URL.createObjectURL(file);
                const fileName = challengeCustomData.fileName;

                return (
                    <div>
                        <p>Siga as instruções abaixo para completar o desafio de arquivo de verificação (WELL_KNOWN):</p>
                        <ol className="spaced-list">
                            <li>Baixe <a href={urlHrefContent} download={fileName}>este arquivo</a></li>
                            <li>Em seu servidor HTTP/S do domínio, adicione na raiz dos arquivos a pasta: <b>.well-known/acme-challenge</b>;</li>
                            <li>Adicione o arquivo gerado pela plataforma nesta pasta;</li>
                            <li>Após o passo anterior, volte para a lista de domínios aguardando verificação, selecione o domínio <b>{registeredChallenge.name} </b> 
                             e clique em <b>"Verificar domínio";</b>
                            </li>
                        </ol>
                    </div>
                );
            default : 
                    return null;
        }
    }

    /**
     * Return default props used in pagination
     * @param pagination 
     * @returns 
     */
    function paginationProps(pagination : Pagination | null) : Object {
        //Return empty object if pagination is null
        if (pagination === null) {
            return {}
        }

        return {
            rowCount : pagination?.totalRecords,
            pageSize : pagination?.recordsPerPage,
            paginationMode : 'server'
        }
    }

     //Domains grid column def
     const domainsColumnDef: GridColDef[] = [
        { field: 'name', headerName: 'Domínio', width: 800 },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            renderCell: (params : GridRenderCellParams) => {

                const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation(); // don't select this row after clicking
                    setSelectedDomain(params.row);

                    //Assert STL level
                    stl.require(authUser, SecurityTierLevels.MaxLevel)
                        .then(() => {setDeleteDomainDialogVisible(true);})
                        .catch(() => setRenewAccessDialog(true))
                };
        
                return (
                    <Box>
                        <IconButton
                            color='error'
                            onClick={onClick}
                        >
                            <DeleteForever />
                        </IconButton>
                    </Box>
                )
            }
          }
      ];

    //Challenges grid column def
    const challengesColumnDef: GridColDef[] = [
        { field: 'name', headerName: 'Domínio', width: 620 },
        { field: 'type', headerName: 'Desafio', width: 160, valueGetter: (params: GridValueGetterParams) => `${params.row.challenge.type}`},
        {
            field: "actions",
            headerName: "",
            sortable: false,
            renderCell: (params : GridRenderCellParams) => {
            
                const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation(); // don't select this row after clicking
                    setChallengesContextMenuAnchor(event.currentTarget);
                    setSelectedChallenge(params.row);
                };
        
                return (
                    <Box>
                        <IconButton 
                            onClick={onClick}
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            aria-expanded={!!challengesContextMenuAnchor ? 'true' : undefined}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                )
            }
          }
      ];

    function VerifyDomainDialog() : JSX.Element | null {

        //Store the state of the domain verification status
        const [domainVerificationStatus, setDomainVerificationStatus] = useState<DomainVerificationStatus>(DomainVerificationStatus.Processing);

        //Effect to verify the domain
        useEffect(() => {
            //Ignore the select challenge is null
            if (selectedChallenge === null || !verifyDomainDialogVisible) {
                return ;
            }
            
            //Timeout to get an better UX while verifying the domain
            setTimeout(() => {
                CustomerInternetDomainService.addNewDomainWithChallenge(selectedChallenge.name)
                .then((verifiedDomain) => {
                    setDomainVerificationStatus(DomainVerificationStatus.Approved);

                    //Add a timeout to close the dialog after domain verification succeeded
                    setTimeout(() => {
                        setVerifyDomainDialogVisible(false);

                        //Remove the challenge from the UI
                        setChallenges(challenges.filter(c => c.id !== selectedChallenge.id));

                        //Include the created challenge into the UI
                        setDomains([...domains, verifiedDomain]);

                    }, 3000);
                })
                .catch(e => {
                    const error = new ErrorWrapper(e);
                    if (error.httpStatus === 403) {
                        setDomainVerificationStatus(DomainVerificationStatus.VerificationError);    
                    }
                    else {
                        setDomainVerificationStatus(DomainVerificationStatus.ServerError);
                    }
                    
                });
            }, 3000)
        }, [])
        

        /**
         * Return an customer component based on the domain verification status
         * @returns 
         */
        function DomainVerificationStatusIcon() : JSX.Element | null {
            switch (domainVerificationStatus) {
                case DomainVerificationStatus.Processing:
                    return (
                        <Box sx={{ display: 'flex' , justifyContent : 'center', mt : 4 }}>
                            <CircularProgress />
                        </Box>
                    );
                case DomainVerificationStatus.Approved:
                    return (
                        <Box sx={{ display: 'flex' , justifyContent : 'center', mt : 4 }}>
                            <Check color="success" fontSize="large"/>
                            <p className="ta-c">Domínio verificado com sucesso</p>
                        </Box>
                    );
                case DomainVerificationStatus.ServerError:
                    return (
                        <div>
                            <Box sx={{ display: 'flex' , justifyContent : 'center', mt : 4 }}>
                                <Error color="error" fontSize="large"/>
                            </Box>
                            <p className="ta-c">Ocorreu um erro no servidor ao verificar domínio</p>
                        </div>
                    );
                    case DomainVerificationStatus.VerificationError:
                        return (
                            <div>
                                <Box sx={{ display: 'flex' , justifyContent : 'center', mt : 4 }}>
                                    <PriorityHigh color="warning" fontSize="large"/>
                                </Box>
                                <p className="ta-c">Não conseguimos verificar seu domínio. Veja as instruções para checar o procedimento de verificação</p>
                            </div>
                        );
                    default : 
                            return null;
            }
        }

        return (
            <Dialog
                open={verifyDomainDialogVisible}
                onClose={() => {setVerifyDomainDialogVisible(false); setSelectedChallenge(null);}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className="ta-c">
                    Verificando domínio
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="ta-c">
                        Verificando autenticidade do domínio<br/>
                        <b>{selectedChallenge?.name}</b>
                    </DialogContentText>
                    <DomainVerificationStatusIcon />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setVerifyDomainDialogVisible(false)}>Fechar</Button>
                </DialogActions>
            </Dialog>
        );
    }

    //Main view
    return (
        <Container sx={{mb : 4}}>
            {/*Title*/}
            <div className='ta-c'>
                <h2>Domínios verificados</h2>
                <p>Verifique seus domínios para utilizar recursos da plataforma, como adicionar usuários sem precisar de verificação por e-mail</p>
            </div>
            <hr/>
            {/**Button area */}
            <Box
                sx={{
                    display : 'flex',
                    flexDirection : 'row-reverse',
                    mt : 2
                }}
            >
                <Button color="primary" variant='contained' onClick={() => setAddDomainDialogVisible(true)}>Adicionar domínio</Button>
            </Box>
            {/*Grid area - Challenges */}
            <Box
                sx={{
                    height : '32vh',
                    mt : 2,
                    display : (challenges.length > 0) ? 'inherit' : 'none'
                }}
            >
                <h3>Domínios aguardando verificação</h3>
                <DataGrid
                    getRowId={(challenge) => challenge.id}
                    rows={challenges}
                    columns={challengesColumnDef}
                    loading={loading}
                    page={challengesPage - 1}
                    onPageChange={(page : number) => {setChallengesPage(page + 1)}}
                    isRowSelectable={() => false}
                    {...paginationProps(challengesPagination)}
                />
            </Box>
            {/*Grid area - Verified domains */}
            <Box
                sx={{
                    height : '48vh',
                    mt : 8,
                    display : (domains.length > 0) ? 'inherit' : 'none'
                }}
            >
                <h3>Domínios verificados</h3>
                <DataGrid
                    getRowId={(domain) => domain.name}
                    rows={domains}
                    columns={domainsColumnDef}
                    loading={loading}
                    page={domainsPage - 1}
                    onPageChange={(page : number) => {setDomainsPage(page + 1)}}
                    isRowSelectable={() => false}
                    {...paginationProps(domainsPagination)}
                />
            </Box>
            {/* Subusers menus */}
            <Menu
                id="basic-menu"
                anchorEl={challengesContextMenuAnchor}
                open={!!challengesContextMenuAnchor}
                onClose={() => {setChallengesContextMenuAnchor(null)}}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {setChallengesContextMenuAnchor(null); setVerifyDomainDialogVisible(true)}}>
                    <DomainVerification sx={{mr : 1}} color="success" /> Verificar domínio
                </MenuItem>
                <MenuItem onClick={() => {setChallengesContextMenuAnchor(null); setDomainVerificationChallengeInfoDrawerVisible(true)}}>
                    <Info sx={{mr : 1}} color="info" /> Instruções para verificação de domínio
                </MenuItem>
                <hr/>
                <MenuItem onClick={() => {
                        stl.require(authUser, SecurityTierLevels.MaxLevel)
                        .then(() => {setChallengesContextMenuAnchor(null); setDeleteDomainChallengeDialogVisible(true)})
                        .catch(() => setRenewAccessDialog(true))
                    }
                }
                >
                    <DeleteForever sx={{mr : 1}} color="error" /> Deletar verificação
                </MenuItem>
            </Menu>
            {/*Add domain dialog*/}
            <AddDomainDialog />
            {/*Delete domain dialog*/}
            <DeleteDomainDialog />
            {/*Delete domain challenge dialog*/}
            <DeleteDomainChallengeDialog />
            {/*Verify domain dialog*/}
            <VerifyDomainDialog />
            {/* Drawer with domain verification instructions */}
            <DomainVerificationChallengeInfoDrawer />
            {/* App notification component */}
            <AppNotification/>
            {/* Renew access dialog */}
            <RenewAccessDialog 
                open={renewAccessDialog} 
                onClose={() => setRenewAccessDialog(false)} 
                onLogin={() => setRenewAccessDialog(false)}/>
        </Container>
    )
}

export default Domains;