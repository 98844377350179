import "./Login.css";

import { Alert, Box, Button, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { Container } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import AuthenticationContext from "../../contexts/authentication";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../../components/LoadingButton";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { RouteParamContext } from "../../contexts/route-param";
import { CurrentContext } from "../../appctx/webappContext";
import Background from "./Background";

enum Actions {
    login = 'login',
    create = 'create-account'
}

/**
 * Login UI
 * @returns {JSX.Element}
 */
export default function Login() {
    //State props
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    //Context props
    const authentication = useContext(AuthenticationContext)
    const routeParam = useContext(RouteParamContext)
    const navigate = useNavigate();

    const isMobile = useMediaQuery("(max-width:900px)")

    //Token from auth webapp
    const [authToken, setAuthToken] = useState<string | null>(null)
    useEffect(() => { 
        getQueryParam(); 
        
        // Reset the local storage item user to avoid show the wrong user in cache
        localStorage.removeItem("App@user");
    }, [])

    //Get the token from the URL 
    const getQueryParam = () => {
        const token = routeParam.get('token');
        setAuthToken(token);
    }

    //Signin with auth token
    useEffect(() => { signin() }, [authToken])
    /**
     * Handle login form submit event
     * @param {React.FormEvent<HTMLFormElement>} event 
     */
    const signin = async () => {
        if (!authToken) return;

        //Call accounts API to create session token
        setLoading(true);
        try {
            //Call the context to authenticate the user
            await authentication.authenticateWithAuthToken(authToken);
            navigate("/", { replace: true })
        } catch (e) {
            const error = new ErrorWrapper(e);
            //Check for HTTP error
            if (error.isHttpError) {
                if (error.httpStatus === 403) {
                    setError("Credenciais inválidas");
                }
                else {
                    setError("Ocorreu um erro durante a requisição: " + error.message);
                }
            }
            //Check for application error
            else {
                setError("Ocorreu um erro ao realizar login");
                console.error(e);
            }
        } finally {
            setLoading(false);
        }
    };

    //Redirect to auth webapp
    const goToAuth = (action: Actions) => {
        window.location.href = `${CurrentContext.authWebappURL}/${action}?service=accounts`
    }

    //If the user is authenticated redirect the page
    if (authentication.isAuthenticated()) navigate("/", { replace: true })

    /**
     * Default error element handling
     * @returns 
     */
    function ErrorAlert(): JSX.Element {
        if (error) {
            return <Alert severity='error' onClose={() => setError(null!)}>{error}</Alert>
        }
        else {
            return <span></span>
        }
    }

    return (
        <>
            <Box sx={{width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#000", overflow: "hidden"}}>
               {
                !isMobile
                    ?
                    <Grid container>
                        <Grid item md={6} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Box>
                                <Background /> 
                            </Box> 

                        </Grid>
                        <Grid item xs={12} md={6} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Box sx={{width: "30vw", height: "50vh", display: "flex",flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#FFF", borderRadius: "5px", zIndex: 1000}}>
                                <img src="http://klausfiscal.com.br/images/klaus-logo.png" alt="" style={{maxWidth: "150px", width: "100%"}} />
                                <Typography className="login-content" variant="h6" mt={2} mb={6} >Entrar no Klaus Accounts</Typography>
                                <LoadingButton sx={{width: "75%", my: 2}} loading={loading} variant="contained"
                                    onClick={() => goToAuth(Actions.login)}>Entrar</LoadingButton>
                                <Button size="small" onClick={() => goToAuth(Actions.create)}>Criar conta</Button>
                            </Box>
                        </Grid>
                    </Grid>
                    :
                    <Box sx={{width: "100vw", height: "100vh", display: "flex",flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#FFF", borderRadius: "5px"}}>
                        <img src="http://klausfiscal.com.br/images/klaus-logo.png" alt="" style={{maxWidth: "150px", width: "100%"}} />
                        <Typography className="login-content" variant="h6" mt={2} mb={6} >Entrar no Klaus Accounts</Typography>
                        <LoadingButton sx={{width: "75%", my: 2}} loading={loading} variant="contained"
                            onClick={() => goToAuth(Actions.login)}>Entrar</LoadingButton>
                        <Button size="small" onClick={() => goToAuth(Actions.create)}>Criar conta</Button>
                    </Box>
               }
            </Box>
            
            
            {/* <Container id="formContainer" sx={{ textAlign: 'center' }}>
                <h1>Entrar Klaus Accounts</h1>
                <LoadingButton fullWidth loading={loading} variant="contained"
                    onClick={() => goToAuth(Actions.login)}>Entrar</LoadingButton>
                <hr />
                <Button size="small" onClick={() => goToAuth(Actions.create)}>Criar conta</Button>
                <ErrorAlert />
            </Container > */}
        </>
    );
}